<script>
import instance,{lienImage} from "../../api/api";
import Swal from "sweetalert2";
export default {
  name: "detailPropriete",

  data() {
    return {
      listResidenceReservee: [],
      newArrayImage: [],
      detailPropriete:null,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      categoryId:null,
      localisation_gps:"",
      dataObject: "",
      responseCreateImage:"",
      libelleTypeOperation: "",
      label: "",
      lienImage:lienImage,
      convenience: [],
      listCommune: [],
      filterVilleForCommune:[],
      listVille: [],
      preloader: false,
      listCommodite: [],
      categorieChosen: "",
      ListProprietaire: [],
      appartenanceImmobiliere: "fournisseur",
      docuPropriete: "",
      personneMaxi: "",
      categorieProduct: "",
      descriptionPropriete: "",
      Listcategorie: [],
      imagePropriete: [],
      labelPropriete: "",
      typePropriete: "",
      costPropriete: "",
      documentPropriete: "",
      roomPropriete: "",
      cautionPropriete: "",
      lotPropriete: "",
      areaPropriete: "",
      ilotPropriete: "",
      addressPropriete: "",
      municipality_idPropriete: "",
      ville_idPropriete:"",
      category_idPropriete: "",
      proprietairePropriete: "",
      comoditesPropriete: "",
      TypeOperationImmobiliere: "",
      transactionImmobiliere: "",
      listTypeOperation: [],
      listCommoditeChoosen:[],
      tableauCommoditeChoosen:"",
      listCommoditeGetOut:[],
      photoDetail:[],
      idPictureForDelete:"",
      fields: [
        {
          convenience_id: "",
          number: "",
        },
      ],
      toggleWindowForDelete:false,
      spinnerDeletePicture:false,
      preloaderModify:false,
      preloaderSpinner:false,
      preloaderList:false,
    };
  },
  computed:{

  },
  methods: {
    async removePicture() {
     this.spinnerDeletePicture = true;
    //  this.helpMeGetList("photos");
     ////console.log("this.idPictureForDelete",this.idPictureForDelete)
     const responseForDeletePicture = await instance.delete("photos/"+this.idPictureForDelete)
     //console.log("responseForDeletePicture",responseForDeletePicture)
     try {
      
       if (responseForDeletePicture.data.status === true) {
         Swal.fire({
           icon: "success",
           title: "Image supprimée.",
           showConfirmButton: true,
         });
        
           let image = null;
       
         this.photoDetail.forEach(item=>{
           const substringToRemove = lienImage;
           
          this.detailPropriete.photos.forEach(element=>{
           if(item.replace(substringToRemove, "") == element.image){
             image = item.replace(substringToRemove, "")
           }
          })
         })
         this.imageEssay = image
        const deletePicture = this.detailPropriete.photos.findIndex((picture) => picture.id === this.idPictureForDelete)
        
          this.detailPropriete.photos.splice(deletePicture,1)
          this.photoDetail = this.putImageInArray(this.detailPropriete.photos)
    
         this.spinnerDeletePicture = false;
         this.toggleWindowForDelete = !this.toggleWindowForDelete;
         this.spinnerDeletePicture = false;
       }
     } catch (error) {
       this.spinnerDeletePicture = false;
       console.log(error);
     }
   },
    iterateInArrCommodite(tableau) {
      let compteur = 0;
      let commoditeArray = [];
      //console.log("tableau",tableau)
      while (compteur < tableau.length) {
        let idLibelle = tableau[compteur].label.id
          ? tableau[compteur].label.id
          : tableau[compteur].pivot.comodity_id;
        commoditeArray.push({
          comodity_id: idLibelle,
          number: tableau[compteur].pivot.number,
        });
        compteur++;
      }
      return commoditeArray;
    },
    selectDataForSend(){
      const myCommodites = this.detailPropriete.comodites ? this.detailPropriete.comodites :[]
      const tableauCommodite = this.iterateInArrCommodite(myCommodites);
      let data = new FormData();
      tableauCommodite.forEach((commodite) => {
        data.append("comodites[]", JSON.stringify(commodite));
      });
    
      // data.append("type_id",this.TypeOperationImmobiliere.id);
      data.append("name", this.labelPropriete ? this.labelPropriete : "");
      // data.append("type", );
      data.append("cost",this.costPropriete ? this.costPropriete:"");
      data.append("document",this.documentPropriete ? this.documentPropriete:"");
      data.append("room",this.roomPropriete ? this.roomPropriete:"");
      data.append("caution",this.cautionPropriete ? this.cautionPropriete:"");
      data.append("localisation_gps",this.localisation_gps ? this.localisation_gps : "")
      data.append("description",this.descriptionPropriete ? this.descriptionPropriete:"");
      data.append("lot", this.lotPropriete ? this.lotPropriete:"");
      data.append("area",this.areaPropriete ? this.areaPropriete : "");
      data.append("ilot",this.ilotPropriete ? this.ilotPropriete:"" );
      data.append("adress",this.addressPropriete ? this.addressPropriete:"");
      data.append("person_maxi",this.personneMaxi ? this.personneMaxi:"" );
      // data.append("municipality_id",municipalityDetail );
      // data.append("category_id",category_idDetail);
      // data.append("registered_by",registered_byDetail ); 

      this.imagePropriete.forEach((item) => {
        data.append("image[]", item);
      });
      ////console.log("this.imagePropriete",this.imagePropriete)

      return data;
    },
    resetData(){
      this.imagePropriete = []
    },
    addField: function () {
        this.detailPropriete.comodites.push({
        label: "",
        pivot: {
          number: "",
        },
      });
    },
    deleteField: function (element) {
      const DeleteField = this.detailPropriete.comodites.findIndex(
        (item) => item === element
      );
      this.detailPropriete.comodites.splice(DeleteField, 1);
    },
    handleMedias(file) {
      this.imagePropriete = this.iterateInArrImages(file.fileList);
    },
    iterateInArrImages(tableau) {
      let compteur = 0;
      let filesArray = [];
      while (compteur < tableau.length) {
        filesArray.push(tableau[compteur].file);
        compteur++;
      }
      return filesArray;
    },
    putImageInArray(arrayPicture) {
      let compteur = 0;
      let images = [];
      while (compteur < arrayPicture.length) {
        images.push(
          this.lienImage +
            arrayPicture[compteur].image
        );
        compteur++;
      }
      return images;
    },
    listDetailsPropriete() {
      this.isSpinner = true;
      this.preloaderSpinner = true
      instance
        .get("proprietes/" + this.$route.params.id)
        .then((res) => {
          this.detailPropriete = res.data.data;
          //console.log("this.detailPropriete", this.detailPropriete);
          this.categoryId=this.detailPropriete.category
         this.labelPropriete = this.detailPropriete.name
         this.ilotPropriete =  this.detailPropriete.ilot
         this.lotPropriete = this.detailPropriete.lot
         this.typePropriete = this.detailPropriete.types.label
         this.TypeOperationImmobiliere = this.detailPropriete.types
         this.costPropriete = this.detailPropriete.cost
         this.cautionPropriete = this.detailPropriete.caution
         this.roomPropriete = this.detailPropriete.room
         this.areaPropriete = this.detailPropriete.area
         this.ville_idPropriete = this.detailPropriete.municipality.city
         this.municipality_idPropriete = this.detailPropriete.municipality
         this.addressPropriete =  this.detailPropriete.adress
         this.personneMaxi = this.detailPropriete.person_maxi
         this.descriptionPropriete = this.detailPropriete.description
         this.documentPropriete = this.detailPropriete.document
         this.localisation_gps = this.detailPropriete.localisation_gps
         this.photoDetail = this.putImageInArray(
            this.detailPropriete.photos
          );
          this.isSpinner = false;
          this.preloaderSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.isSpinner = false;
          this.preloaderSpinner = false;
        });
    },
    async getList(){
      this.preloaderList = true;
            try{
              const responseCategorie = await instance.get('categories')
              const responseComodites = await instance.get('comodites')
              const responseMunicipalite = await instance.get('municipalite')
              const responseCity = await instance.get('cities')
              const responseTypeOperation = await instance.get('types')
               //console.log("responseCity",responseCity)
              this.listTypeOperation =responseTypeOperation.data.data 
              this.listCommodite = responseComodites.data.data
              this.listCommune =responseMunicipalite.data.data  
              this.listVille =responseCity.data.data 
              this.Listcategorie = responseCategorie.data.data
              this.preloaderList = false
            }catch(error){
                console.log(error);
                this.preloaderList = false
            }
        },
        WantDoDeletePicture(element) {
     const modifiedURL = element.replace(lienImage,"");
     //console.log("modifiedURL",modifiedURL)
     this.detailPropriete.photos.forEach((item) => {
       if (item.image == modifiedURL) {
         this.idPictureForDelete = item.id;
       }
     });
     //console.log("idPictureForDelete",this.idPictureForDelete)
     this.toggleWindowForDelete = !this.toggleWindowForDelete;
   },
   async sendModificationHome() {

this.preloaderModify = true;
try {
  const data = this.selectDataForSend()
  const response = await instance.post('updatePropriete/'+this.$route.params.id,data)

  //console.log("updatePropriete",response)
  if (
    response.data.status === true
  ) {
    // this.detailPropriete.type_id = response.data.data.type_id;
    // this.detailPropriete.name = response.data.data.name;
    // this.detailPropriete.type = response.data.data.type;
    this.detailPropriete.cost = response.data.data.cost;
    this.detailPropriete.document = response.data.data.document;
    this.detailPropriete.room = response.data.data.room;
    this.detailPropriete.caution = response.data.data.caution;
    this.detailPropriete.description = response.data.data.description;
    this.detailPropriete.lot = response.data.data.lot;
    this.detailPropriete.area = response.data.data.area;
    this.detailPropriete.ilot = response.data.data.ilot;
    this.detailPropriete.adress = response.data.data.adress;
    this.detailPropriete.person_maxi = response.data.data.person_maxi;
   
    // this.detailPropriete.category_id = response.data.data.category_id;
    // this.detailPropriete.registered_by = response.data.data.registered_by;
    this.detailPropriete.photos = response.data.data.photos
    this.detailPropriete.photoPropriete = this.putImageInArray(
      response.data.data.photos
    );
    this.photoDetail = this.detailPropriete.photoPropriete
   
    this.preloaderModify = false;
    Swal.fire({
           icon: "success",
           title: "Propriéte modifiée.",
           showConfirmButton: true,
         });
         
         this.imagePropriete = []
         
  }
  this.preloaderModify = false;
} catch (error) {
  console.log(error)
  this.preloaderModify = false;
}
},
middlewareForSendModificationHome(){
   
    this.sendModificationHome()

    },
  },
  created() {
    this.listDetailsPropriete()
    this.getList()
  },
};
</script>
<template>
    <div class="col-lg-10 col-md-12">

        <div class="confirmation_modifie" v-if="toggleWindowForDelete">
            <div class="card p-3">
              <h6 class="text-center text-secondary">
                Voulez-vous vraiment supprimer l'image ?
              </h6>
        
              <div class="d-flex justify-content-center align-items-center">
                <div>
                  <button
                    class="btn btn-all text-light mx-2 fw-bold bg-dark"
                    @click.prevent="removePicture"
                  >
                    {{spinnerDeletePicture ? "Chargement...":"Confirmer"}}
                  </button>
                  <button
                    class="btn bg-danger mx-2 text-white fw-bold"
                    @click.prevent="toggleWindowForDelete = !toggleWindowForDelete"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
							
        <div class="dashboard-wraper">
            
            <div class="row text-start">
                
               
                <div class="col-lg-12 col-md-12">
                  <div v-if="preloaderSpinner && preloaderList" class="text-center">
                    <span  class="text-dark">
                      Chargement... <ProgressSpinner  
          style="width: 25px; height: 25px" 
         strokeWidth="8" 
         fill="var(--surface-ground)"
         animationDuration=".5s" 
         aria-label="Custom ProgressSpinner" />
                    </span>
                   </div>
                    <form v-else>
                      <div class="submit-pages_view text-start">
                        <div class="form-submit">
                          <h2 class="text-dark">Détail de la propriéte</h2>
                          <div class="submit-section">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label
                                  >Catégorie de la propriéte
                                  <span class="text-danger">*</span></label
                                >
          
                                <select
                                  v-model="categoryId.id"
                                  class="form-control"
                                 disabled
                                >
                                  <option disabled value="">
                                    Sélectionne une catégorie
                                  </option>
                                  <option
                                    v-for="(categorie, index) in Listcategorie"
                                    :key="index"
                                    :value="categorie.id"
                                    :id="categorie.label"
                                  >
                                    {{ categorie.label }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group col-md-6"
                               v-if="categoryId">
                                <label
                                  >Nom </label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="labelPropriete"
                                  disabled
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                categoryId.label == 'terrain' ||
                                categoryId.label == 'Terrain'
                                "
                              >
                                <label>Ilot <span class="text-danger">*</span></label>
          
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ilotPropriete"
                                  
                                />
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categoryId.label == 'terrain' ||
                                  categoryId.label == 'Terrain'
                                "
                              >
                                <label
                                  >Numéro de lot <span class="text-danger">*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="lotPropriete"
                                  
                                />
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categoryId.label == 'Résidence' ||
                                  categoryId.label == 'Maison'
                                "
                              >
                                <label
                                  >Type de Propriéte
                                  <span class="text-danger">*</span></label
                                >
          
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="typePropriete"
                                  placeholder="villa,duplex etc..."
                                  disabled
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categoryId.label == 'Maison' ||
                                  categoryId.label == 'Terrain'
                                "
                              >
                                <label
                                  >Type de l'operation
                                  <span class="text-danger">*</span></label
                                >
                                <select
                                  v-model="TypeOperationImmobiliere.id"
                                  class="form-control"
                                  disabled
                                >
                                  <option disabled value="">Veuillez sélectionner</option>
                                  <option
                                    v-for="item in listTypeOperation"
                                    :key="item"
                                    :value="item.id"
                                  >
                                    {{ item.label }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group col-md-6" v-if="categoryId.label">
                                <label>Prix <span class="text-danger">*</span></label>
                                <VueNumberFormat class="form-control"
                                v-model:value="costPropriete"
                                :options="{
                                  precision: 0,
                                  nullValue: false,
                                  prefix: '',
                                  decimal: ',',
                                  thousand: '.',
                                  acceptNegative: false,
                                  isInteger: false,
                                }"
                               
                                ></VueNumberFormat>
                              
                                
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categoryId.label == 'Maison' &&
                                  TypeOperationImmobiliere.label == 'Location'
                                "
                              >
                                <label>Caution (Nombre de mois)</label>
          
                                <input
                                  type="number"
                                  min="1"
                                  class="form-control"
                                  v-model="cautionPropriete"
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categoryId.label == 'Maison' ||
                                  categoryId.label == 'Résidence'
                                "
                              >
                                <label
                                  >Nombre de pièces
                                  <span class="text-danger">*</span></label
                                >
          
                                <input
                                  type="number"
                                  min="1"
                                  class="form-control"
                                  v-model="roomPropriete"
                                  
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categoryId.label == 'Maison' ||
                                  categoryId.label == 'Terrain'
                                "
                              >
                                <label>Superficie</label>
          
                                <input
                                  type="number"
                                  min="1"
                                  class="form-control"
                                  v-model="areaPropriete"
                                />
                              </div>
                              <div class="form-group col-md-6" v-if="categoryId.label">
                                <label>Ville <span class="text-danger">*</span></label>
          
                                <v-select
                                  placeholder="Sélectionner une ville"
                                  v-model="ville_idPropriete.name"
                                  :searchable="true"
                                  :options="listVille"
                                  label="name"
                                  disabled
                                >
                                  <template>
                                    <p>{{ label }}</p>
                                  </template>
                                </v-select>
                              </div>
                              <div class="form-group col-md-6" v-if="categoryId.label">
                                <label>
                                  {{this.ville_idPropriete.name == 'Abidjan' ? 'Commune':'Quartier'}} 
                                  <span class="text-danger">*</span></label>
          
                                <v-select
                                  :placeholder="this.ville_idPropriete.name == 'Abidjan' ? 'Sélectionner une commune':'Sélectionner un quartier'"
                                  v-model="municipality_idPropriete.name"
                                  :searchable="true"
                                  :options="filterVilleForCommune"
                                  label="name"
                                  disabled
                                >
                                  <template>
                                    <p>{{ label }}</p>
                                  </template>
                                </v-select>
                              </div>
                              <div class="form-group col-md-6" v-if="categoryId.label">
                                <label>Situé précisément</label>
          
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="addressPropriete"
                                  
                                />
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="categoryId.label == 'Résidence'"
                              >
                                <label
                                  >Nombre de personne autorisées
                                  <span class="text-danger">*</span></label
                                >
          
                                <input
                                  type="number"
                                  class="form-control"
                                  min="1"
                                  v-model="personneMaxi"
                                  
                                />
                              </div>
                              <div class="form-group col-md-12" v-if="categoryId.label">
                                <label>Description</label>
                                <textarea
                                  class="form-control"
                                  
                                  v-model="descriptionPropriete"
                                  name=""
                                  id=""
                                  cols="30"
                                  rows="10"
                                >
                                </textarea>
                              </div>
          
                              <div
                                class="form-group col-md-12"
                                v-if="
                                  categoryId.label == 'Résidence' ||
                                  categoryId.label == 'Maison'
                                "
                              >
                                <div
                                  v-for="field in detailPropriete.comodites"
                                  :key="field"
                                  class="position-relative row"
                                >
                                <div class="w-100"  style="text-align:right;">
                                    <span
                                    v-if="field"
                                    class="delete badge bg-danger fw-bold"
                                    @click="deleteField(field)"
                                    >x</span
                                  >
                                </div>
                                 
                                <div class="col-lg-6">
                                    <label :for="field" class="text-dark">Commodité</label>
                                    <v-select
                                      v-model="field.label"
                                      :searchable="true"
                                      placeholder="Sélectionner une commodité"
                                      :options="listCommodite"
                                      label="label"
                                    >
                                      <template>
                                        <p>{{ label }}</p>
                                      </template>
                                    </v-select>
                                  </div>
                                  <div class="col-lg-6">
                                    <label :for="field" class="text-dark"
                                      >Nombre de la commodité</label
                                    >
                                    <input
                                      :id="field"
                                      type="number"
                                      min="1"
                                      class="form-control"
                                      v-model="field.pivot.number"
                                    />
                                  </div>
                                </div>
                                <div class="text-end">
                                  <button
                                    @click.prevent="addField"
                                    class="btn bg-primary  my-3 addField bg-dark text-light"
                                  >
                                    Ajouter une commodité +
                                  </button>
                                </div>
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="categoryId.label == 'Terrain'"
                              >
                                <label>Document <span class="text-danger">*</span></label>
                                <div class="row container">
                                  <section class="col-md-12 circuit">
                                    <div class="col-md-12 d-flex align-items-center my-2">
                                      <input
                                        type="radio"
                                        id="ACD"
                                        name="fav_language"
                                        value="ACD"
                                        
                                        v-model="documentPropriete"
                                      />
                                      <label for="ACD">ACD</label>
                                    </div>
          
                                    <div class="col-md-12 d-flex align-items-center my-2">
                                      <input
                                        type="radio"
                                        id="Approbation Ministeriel"
                                        name="fav_language"
                                        value="ACD en cours"
                                        
                                        v-model="documentPropriete"
                                      />
                                      <label for="Approbation Ministeriel"
                                        >ACD en cours</label
                                      >
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <!-- <div class="form-group col-md-6" v-if="categorieChosen">
                                <label
                                  >Appartenance <span class="text-danger">*</span></label
                                >
          
                                <select
                                  v-model="appartenanceImmobiliere"
                                  class="form-control"
                                >
                                  <option disabled value="">Veuillez sélectionner</option>
                                  <option value="administrateur">Dexter</option>
                                  <option value="fournisseur">Fournisseur</option>
                                </select>
                              </div> -->
          
                              <!-- <div
                                class="form-group col-md-6"
                                v-if="appartenanceImmobiliere == 'fournisseur'"
                              >
                                <label
                                  >Proprietaire <span class="text-danger">*</span></label
                                >
                                <v-select
                                  v-model="proprietairePropriete"
                                  :searchable="true"
                                  :options="ListProprietaire"
                                  label="firstname"
                                >
                                  <template #option="{ firstname, lastname }">
                                    <p>{{ lastname }} {{ firstname }}</p>
                                  </template>
                                </v-select>
                              </div> -->
                              <!-- <div
                                class="form-group col-md-12 text-start"
                                v-if="categoryId.label"
                              >
                                <label
                                  >Télécharger des images
                                  <span class="text-danger">*</span></label
                                >
          
                                <UploadMedias 
                                  uploadMsg="glisser et déposer vos images"
                                  @changed="handleMedias"
                                />
                              </div> -->
                              <div class="form-group col-md-12 text-start">
                                <label class="text-dark d-block">
                                  Galerie</label
                                >
            
                                
                                <div
                                  class="d-flex 
                                  justify-content-center
                                   flex-wrap align-items-center gap-3"
                                >
                              
                                  <span
                                    class="position-relative photo-detail"
                                    v-for="(item, index) in photoDetail"
                                    :key="index"
                                  >
                                    <i
                                      @click.prevent="WantDoDeletePicture(item)"
                                      class="bi bi-trash-fill p-3 
                                      trash-two 
                                      text-danger 
                                      
                                      position-absolute"
                                    ></i>
                                    <n-image width="300" height="300" 
                                    class="img-fluid" 
                                    :src="item" />
                                  </span>
                                </div>
            
                                <n-upload
                                  @change="handleMedias"
                                  multiple
                                  :default-file-list="newArrayImage"
                                  list-type="image-card"
                                >
                                Clique pour télécharger
                                </n-upload>
                              </div>
                              <div class="col-lg-6 mt-2" v-if="categoryId.label">
                                <label class="fw-bold">
                                  Lien de la localisation 
                                  
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="localisation_gps"
                                />
                              </div>
          
                              <div class="text-start mt-3">
                                <button
                                  @click.prevent="middlewareForSendModificationHome"
                                  class="btn-lg bg-dark text-light bg-dark rounded"
                                  type="submit"
                                >
                                
                                <span v-if="preloaderModify" class="text-light">
                                  Chargement... <ProgressSpinner  
        style="width: 25px; height: 25px" 
        strokeWidth="8" 
        fill="var(--surface-ground)"
        animationDuration=".5s" 
        aria-label="Custom ProgressSpinner" />
                                </span>
                                <span v-else>
                                  Modifier
                                </span>
                              
                                  
                              
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                
            </div>
            
        </div>
    </div>
</template>
<style scoped>
.confirmation_modifie {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.255);
    display: flex;
    justify-content: center;
    place-items: center;
  }
.dashboard-stat.widget-1 {
  background-color: white;
  box-shadow: 1px 1px 1.5px 1.5px black;
  color: black !important;
}
.dashboard-stat-content h4 {
  color: black !important;
}
.dashboard-stat-content {
  left: 0 !important;
  padding: 1em;
}

.alert-success {
  left: 0 !important;
  top: 0.5em !important;
}
.alert-date {
  right: 0 !important;
  top: 0.5em !important;
}
.btn-voir {
  right: 0 !important;
  bottom: 0 !important;
}
.trash-two{
  background:white;
  box-shadow:1px 1px 1px 1px black;
  border-radius:25%;
}
input[type='radio']{
  border:2px solid black;
}
</style>
