<script>
import Swal from "sweetalert2";
// import Vue3BasicAutocomplete from "vue3-basic-autocomplete";
import instance,{lienImage} from "../api/api";
export default {
  name: "listesProprieteVenteView",
  // components: {
  //   Vue3BasicAutocomplete,
  // },
  data() {
    return {
      preloaderSpinner:false,
      lienImage:lienImage,
      listMaisonVente:[],
      result: "",
      item: "",
      dateToday: new Date().toISOString().substring(0.9),
      ChooseVille: "",
      ChooseVilleId: "",
      spinner: false,
      preloader: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      ToggleShowRebootInformationResidence: false,
      showConfirmationForModify: false,
      showDetailsResidence: false,
      activeResidence: false,
      ToggleGiveDateForIndisponible: false,
      toggleForIndisponible: false,
      fieldForIndisponible: false,
      listTerrainProprietaire: [],
      detailsResidence: {},
      detailsTypeResidence: {},
      detailsPhoto: [],
      detailsUser: {},
      detailsVille: {},
      equipement: [],
      commodites: [],
      date_debut: "",
      date_fin: "",
      listPeriodeIndisponible: [],
      SeedetailsResidence: {},
      SeedetailsProprietaire: {},
      SeedetailsTypePropriete: {},
      SeedetailsVille: {},
      gallery: [],
      ResidenceAtDelete: "",
      IdBienImmobilier: "",
      listJourIndispo: [],
      IdUpdateImmobilier: "",
      ImagesResidence: [],
      perPage: 3,
      currentPage: 1,
      current: 1,
      rows: 6,
      perPageMaison: 3,
      currentPageMaison: 1,
      currentMaison: 1,
      rowsMaison: 6,

    };
  },
  computed: {
    paginatedTerrain() {
      const startIndex = (this.currentPage - 1) * this.rows;
      //console.log("startIndex", startIndex);
      const endIndex = startIndex + this.rows;
      //console.log("endIndex", endIndex);
      return this.listTerrainProprietaire.slice(startIndex, endIndex);
    },
    paginatedVenteMaison(){
      const startIndex = (this.currentPageMaison - 1) * this.rowsMaison;
      //console.log("startIndex", startIndex);
      const endIndex = startIndex + this.rowsMaison;
      //console.log("endIndex", endIndex);
      return this.listMaisonVente.slice(startIndex, endIndex);
    }
  },
  methods: {
    onPageChange(event) {
      //console.log("EVENT", event);
      this.currentPage = event.page + 1;
    },
    onPageChangeMaison(event) {
      //console.log("EVENT", event);
      this.currentPageMaison = event.page + 1;
    },
    handleMedias(files) {
      this.ImagesResidence = files;
      //console.log("Photo", this.ImagesResidence);
    },
    chooseTypeResidence(e) {
      console.log(e.target);
      console.log(this.detailsTypeResidence.id);
    },
    async updateResidence() {
      //console.log("IdUpdateImmobilier", this.IdUpdateImmobilier);
      //     let data = {
      //     libelle:this.detailsResidence.libelle,
      //     nbre_personne: this.detailsResidence.nbre_personne,
      //     nbre_pieces: this.detailsResidence.nbre_pieces,
      //     precission: this.detailsResidence.precission,
      //     reduction: this.detailsResidence.reduction,
      //     tarif: this.detailsResidence.tarif,
      //     latitude: this.detailsResidence.latitude,
      //     longitude: this.detailsResidence.longitude,
      //     ville_id: this.ChooseVilleId,
      //     type_id: this.detailsTypeResidence.id,
      //     type_logement: this.detailsResidence.type_logement,
      //     sell: this.detailsResidence.sell,
      //     periode: this.detailsResidence.periode,
      //    description: this.detailsResidence.description,
      //    c_reduction: this.detailsResidence.c_reduction,
      //    commodites: this.equipement,
      //    quartier: this.detailsResidence.quartier,
      //    status:0
      //     }
      //     console.log(data);
      //     this.spinner = true
      //     await instance.put('immob/'+this.IdUpdateImmobilier,data,{
      //     headers: {
      //     Authorization: "Bearer " + this.$store.state.token,
      //   },
      //     })
      // .then((response) => {
      //   //console.log("SAVEID", response.data.data.id);
      //   let immobilier_id = response.data.data.id
      // if(response.data.status === 'true'){

      //     let formData = new FormData();
      //     this.ImagesResidence.forEach((element,index) => {
      //         formData.append('path['+index+']', element);
      //     });

      //     formData.append('immobilier_id',immobilier_id);
      // await instance.post('updateph/'+this.IdUpdateImmobilier,formData,{
      //     headers: {
      //     Authorization: "Bearer " + this.$store.state.token,
      //   },
      //     })
      //     .then((response)=>{
      //       if(response){
      //         Swal.fire({
      //         icon: "success",
      //         title: "Propiété modifiée avec succés",
      //         showConfirmButton: false,
      //         timer: 1500,
      //       });
      //       this.spinner = false
      //     //   setTimeout(() =>{
      //     //  location.reload(true)
      //     //  },1510)
      //       this.$router.push('/liste-residence')
      //       this.ToggleShowRebootInformationResidence =
      // !this.ToggleShowRebootInformationResidence;

      //       }
      //     })

      //   }
      //   if(response.data.status == 'false'){
      //     Swal.fire({
      //         icon: "error",
      //         title: "Erreur",
      //         showConfirmButton: false,
      //         timer: 1500,
      //       });
      //      }
      // })
      // .catch((err) => {
      //   console.log(err);
      //   Swal.fire({
      //         icon: "error",
      //         title: "Probleme de connexion avec le serveur.",
      //         showConfirmButton: false,
      //         timer: 1500,
      //       });
      //   this.spinner = false
      // });
    },
    DeleteThisPicture(IdPictureAtDelete) {
      //console.log("IDPICTUREDELETE", IdPictureAtDelete);
      let PictureAtDelete = this.detailsPhoto.findIndex(
        (item) => item.id === IdPictureAtDelete
      );
      this.detailsPhoto.splice(PictureAtDelete, 1);
      instance
        .delete("photos/" + IdPictureAtDelete, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    Toogle(id) {
      this.spinner = true;
      this.IdUpdateImmobilier = id;
      console.log(this.IdUpdateImmobilier);
      this.listResidenceProprietaire.forEach((item) => {
        if (item.id === id) {
          this.detailsResidence = item;
          this.detailsTypeResidence = item.type;

          this.detailsPhoto = item.photo_s;
          this.detailsUser = item.user;
          this.detailsVille = item.ville;
          this.detailsEquipement = item.commodite;
          this.detailsEquipement.forEach((item) => {
            this.commodites.push(item.id);
          });
          this.equipement = this.commodites;

          console.log(this.commodites);
        }
      });
      this.spinner = false;
      this.ToggleShowRebootInformationResidence =
        !this.ToggleShowRebootInformationResidence;
    },
    seeDetailResidence(id) {
      this.spinner = true;
      this.listResidenceProprietaire.forEach((item) => {
        if (item.id === id) {
          this.SeedetailsResidence = item;
          console.log(this.SeedetailsResidence);
          this.SeedetailsProprietaire = item.user;
          this.SeedetailsTypePropriete = item.type;
          this.SeedetailsVille = item.ville;
          let address = "http://192.168.1.3:8080/public/image/";
          this.SeedetailsResidence.photo_s.forEach((item) => {
            if (item) {
              this.gallery.push(address + item.path);
              console.log(this.gallery);
            }
          });
          this.spinner = false;
          console.log(this.SeedetailsProprietaire);
          console.log(this.SeedetailsTypePropriete);
          console.log(this.detailsVille);
        }
      });
      this.showDetailsResidence = !this.showDetailsResidence;
    },
    CloseDetailResidence() {
      this.showDetailsResidence = !this.showDetailsResidence;
    },
    ChooseEquipement(e) {
      console.log(e.target);
      console.log(this.equipement);
    },
    DeleteResidence() {
      this.spinner = true;
      instance
        .delete("immob/" + this.ResidenceAtDelete)
        .then((res) => {
          console.log(res);
          if (res.data.status === "true") {
            setTimeout(function () {
              location.reload(true);
            }, 1500);
            Swal.fire({
              icon: "success",
              title: "Résidence supprimée avec succés",
              showConfirmButton: false,
              timer: 1500,
            });
            this.spinner = false;

            this.showConfirmationForModify = !this.showConfirmationForModify;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title:
              "La résidence ne peut pas etre supprimée,car elle fait objet de réservation. ",
            showConfirmButton: false,
            timer: 1500,
          });
          this.spinner = false;
          this.showConfirmationForModify = !this.showConfirmationForModify;
        });
    },
    ShowCalendarForIndisponibilite(idBien) {
      this.IdBienImmobilier = idBien;
      this.listResidenceProprietaire.forEach((item) => {
        if (item.id === idBien) {
          this.listJourIndispo = item.indisponibles;

          console.log(this.listJourIndispo);
        }
      });
      this.toggleForIndisponible = !this.toggleForIndisponible;
    },
    toggleAddDayIndisponibilite(id) {
      this.ListerPeriodeForIndisponibility(id);
      this.fieldForIndisponible = !this.fieldForIndisponible;
    },
    async showListResidenceProprietaire() {
      this.preloaderSpinner = true
      await instance
        .get("proprieteProprietaire")
        .then((response) => {
          //console.log("proprieteProprietaire",response.data.data)
          this.listTerrainProprietaire = response.data.data.filter(item=>item.category.label === "Terrain" && item.types.label === "Vente");
          this.listMaisonVente = response.data.data.filter(item=>item.category.label === "Maison" && item.types.label === "Vente")
          //console.log("this.listMaisonVente",this.listMaisonVente);
          this.preloaderSpinner = false
        })
        .catch((err) => {
          console.log(err);
          this.preloaderSpinner = false
        });
    },
    chooseMyVilleForPropriete(e) {
      console.log(e.target);
      this.ChooseVilleId = this.ChooseVille.id;
      console.log(this.ChooseVille.id);
    },
    GivePeriodeForIndisponibility() {
      this.spinner = true;
      let data = {
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        immobilier_id: this.IdBienImmobilier,
      };
      instance
        .post("indispo", data)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "true") {
            Swal.fire({
              icon: "success",
              title: "Période prise en compte pour l'indisponiblilitée.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.date_debut = "";
            this.date_fin = "";
            this.spinner = false;
          }
          this.listJourIndispo.push(data);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "success",
            title: "La période a déjà été prise en compte.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.spinner = false;
        });
    },
    ListerPeriodeForIndisponibility() {
      instance
        .get("indispo")
        .then((response) => {
          this.listPeriodeIndisponible = response.data.data;
          //console.log("LIST OF PERIODE INDISPO", response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ToggleResidenceForDelete(id) {
      this.showConfirmationForModify = !this.showConfirmationForModify;
      this.ResidenceAtDelete = id;
      console.log(this.ResidenceAtDelete);
    },
    ToggleResidence() {
      this.showConfirmationForModify = !this.showConfirmationForModify;
      this.ResidenceAtDelete = "";
    },
    closeModification() {
      this.ToggleShowRebootInformationResidence =
        !this.ToggleShowRebootInformationResidence;
      this.commodites = [];
    },
    TogglePeriodeIndispo() {
      this.IdBienImmobilier = "";
      this.toggleForIndisponible = !this.toggleForIndisponible;
    },
  },
  created() {
    this.showListResidenceProprietaire();
 
  },
};
</script>
<template>
  <div class="col-lg-10 col-md-12 position-relative">
  
    <div class="dashboard-wraper container">

      <n-tabs type="segment" animated>
        <n-tab-pane name="Mes Terrains" tab="Mes Terrains">
          <div class="form-submit text-start p-3">
        
            <h4>Mes Terrains</h4>

            <span class="py-3">{{this.listTerrainProprietaire.length}} résultats</span>
           <div v-if="preloaderSpinner" class="text-center">
            <span  class="text-dark">
              Chargement... <ProgressSpinner  
  style="width: 25px; height: 25px" 
 strokeWidth="8" 
 fill="var(--surface-ground)"
 animationDuration=".5s" 
 aria-label="Custom ProgressSpinner" />
            </span>
           </div>
            <div class="row" v-else>
              <div
                v-for="item in paginatedTerrain"
                :key="item.id"
                class="col-lg-4 col-md-6 col-sm-12 position-relative"
              >
                <div class="dashboard-stat widget-1 text-left">
                  <div class="dashboard-stat-content">
                    <h6>{{ item.name }}</h6>
                    <span class="d-block">
                      <span class="text-secondary fw-bold">Type:</span>Terrain</span>
                    <span class="d-block">
                      
                      <span class="text-secondary fw-bold">
                        Prix:</span>{{moneyFormat.format(item.cost)}} XOF</span>
                  </div>
                  <div v-if="item.photos.length" class="position-absolute image-residence">
                    <n-image
                    width="80"
                    :src="lienImage+item.photos[0].image"
                  />
                  </div>
                  <span 
                  :class=" item.status !== 'Indisponible' ? 'bg-success':'bg-danger'"
                  class="text-light badge alert-success position-absolute m-1">
                    {{ item.status }}</span
                  >
                  
                  <button class="btn bg-dark btn-voir text-light position-absolute">
                <router-link
                :to="{
                  name: 'DetailPropriete',
                  params: { id: item.id },
                }"
                class="text-light"
                >Voir plus</router-link
              >
                    
                  </button>
                </div>
              </div>
              <div v-if="listTerrainProprietaire.length">
                <PaginatorVue
                v-model="currentPage"
                :rows="rows"
                :totalRecords="listTerrainProprietaire.length"
                @page="onPageChange"
              ></PaginatorVue>
              </div>
            </div>
            <div v-if="!this.listMaisonVente.length && !preloaderSpinner" class="text-center fw-bold">
              Pas de Terrain
           </div>
          </div>
        </n-tab-pane>
        <n-tab-pane name="Mes Maisons" tab="Mes Maisons">
          <div class="form-submit text-start p-3">
        
            <h4>Mes Maisons</h4>
            <span class="py-3">{{this.listMaisonVente.length}} résultats</span>
            <div class="row">
              <div
                v-for="item in paginatedVenteMaison"
                :key="item.id"
                class="col-lg-4 col-md-6 col-sm-12 position-relative"
              >
                <div class="dashboard-stat widget-1 text-left">
                  <div class="dashboard-stat-content">
                    <h6>{{ item.name }}</h6>
                    <span class="d-block">
                      <span class="text-secondary fw-bold">Type:</span>{{ item.type }}</span>
                    <span class="d-block">
                      <span class="text-secondary fw-bold">Prix:</span> 
                      {{moneyFormat.format(item.cost)}} XOF</span>
                  </div>
                  <div v-if="item.photos.length" class="position-absolute image-residence">
                    <n-image
                    width="80"
                    :src="lienImage+item.photos[0].image"
                  />
                  </div>
                  <span 
                  :class=" item.status !== 'Indisponible' ? 'bg-success':'bg-danger'"
                  class="text-light badge alert-success position-absolute m-1">
                    {{ item.status }}</span
                  >
                  
                  <button class="btn bg-dark btn-voir text-light position-absolute">
                <router-link
                :to="{
                  name: 'DetailPropriete',
                  params: { id: item.id },
                }"
                class="text-light"
                >Voir plus</router-link
              >
                    
                  </button>
                </div>
              </div>
              <div v-if="listMaisonVente.length">
                <PaginatorVue
                v-model="currentPageMaison"
                :rows="rowsMaison" 
                :totalRecords="listMaisonVente.length"
                @page="onPageChangeMaison"
              ></PaginatorVue>
              </div>
            </div>
            <div v-if="!this.listMaisonVente.length" class="text-center fw-bold">
               Pas de Maisons
            </div>
          </div>
        </n-tab-pane>
      </n-tabs>
     
      <div>
        
      </div>
    </div>
  </div>
</template>

<style scoped>

.btn-theme-light-2 {
  background-color: black !important;
  color: white !important;
}

.pre {
  padding: 0 0 15em 0;
}
.images {
  border: 3px solid black;
  border-radius: 8px;
}
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.221);
  z-index: 99999;
}
.position-delete_image {
  position: absolute;
  right: 0.3em;
  top: 0.3em;
  font-size: 1.3em;
  z-index: 9;
  cursor: pointer;
  color: crimson;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  background-color: white;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.container[data-v-51562f51] {
  width: 100% !important;
  padding: 0 !important;
}
.confirmation_modifie {
  position: absolute;
  z-index: 9999999;
  background-color: white;
  padding: 2em;
  border: 3px solid black;
  border-radius: 1em;
  margin-left: 50%;
  transform: translateX(-75%);
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}

.conteneur_modify .content {
  width: 60%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.conteneur_modify .periode_indispo {
  height: auto;
  padding-bottom: 1em;
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index: 999;
}
.submit-pages {
  padding: 1em 3em;
}
.dashboard-stat-content{
  left:0 !important;
}
.image-residence{
  right:1em;
  top:1em;
}
</style>
