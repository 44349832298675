<script>
import instance, { lienImage } from "../api/api";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Vue3BasicAutocomplete from "vue3-basic-autocomplete";
import Swal from "sweetalert2";
export default {
  name: "TerrainsView",
  components: {
    Vue3BasicAutocomplete,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      isWishlist: this.$store.state.myListFavoris,
      allVilles: [],
      equipementChoosen: [],
      chooseType: "",
      isLoadingSearch: false,
      chooseMoyenSell: "",
      listEquipements: [],
      listCommunes: [],
      lienImage: lienImage,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      perPage: 3,
      currentPage: 1,
      current: 1,
      rows: 5,
      nbrPiece: "",
      SuperficieMax: 5000,
      ProprieteSuperficie: "",
      ToogleSuperficie: false,
      SuperficieMin: 1000,
      totalPages: "",
      listBiensImmobilier: [],
      listBiensAll: [],
      maxVisibleButtons: 3,
      preloader: false,
      equipement: [],
      isWhished: [],
      multipleSilders: [10, 100],
      ChooseVille: "",
      ChooseVilleId: "",
      prixMaximun: "",
      prixMinimun: "",
      listSearchBien: [],
      isLoading: false,
      chooseCommuneLabel: "",
      chooseCommune: {},
      chooseVilleName: "",
      chooseVille: {},
      tableauLike: [],
      listCommuneFilter:[],
    };
  },
  computed: {
    paginatedTerrainMaison() {
      const startIndex = (this.currentPage - 1) * this.rows;
      //console.log("startIndex", startIndex);
      const endIndex = startIndex + this.rows;
      //console.log("endIndex", endIndex);
      return this.listBiensImmobilier.slice(startIndex, endIndex);
    },
  },
  methods: {
    chooseMyCommune(e) {
      console.log("commune", e.value);
      this.chooseCommuneLabel = this.chooseCommune.name;
      //console.log("this.chooseCommuneLabel", this.chooseCommuneLabel);
    },
    rechercheByTypeWithMoyenAndCommune(
      type,
      moyen,
      commune,
      piece,
      prixMin,
      prixMax,
      area
    ) {
      let filterTypeOrMyen = [];
      filterTypeOrMyen = this.listBiensAll.filter((item) => {
        return (
          item.category.label.includes(type) &&
          item.types.label.includes(moyen) &&
          item.municipality.name.includes(commune) &&
          item.room.includes(piece) &&
          Number(item.cost) >= Number(prixMin) &&
          Number(prixMax) >= Number(item.cost) &&
          item.area.includes(area)
        );
      });
      return filterTypeOrMyen;
    },
    rechercheByTypeWithMoyen(type, moyen) {
      let filterTypeOrMyen = [];
      filterTypeOrMyen = this.listBiensAll.filter((item) => {
        return (
          item.category.label.includes(type) && item.types.label.includes(moyen)
        );
      });
      return filterTypeOrMyen;
    },
    rechercheByCommuneWithType(type, commune) {
      let filterCommune = [];
      filterCommune = this.listBiensAll.filter((item) => {
        return (
          item.category.label.includes(type) &&
          item.municipality.name.includes(commune)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneAndPiece(commune, piece) {
      let filterCommune = [];
      filterCommune = this.listBiensAll.filter((item) => {
        return (
          item.municipality.name.includes(commune) && item.room.includes(piece)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneAndPrixMin(commune, prixMin) {
      let filterCommune = [];
      filterCommune = this.listBiensAll.filter((item) => {
        return (
          item.municipality.name.includes(commune) &&
          Number(item.cost) >= Number(prixMin)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneAndPrixMax(commune, prixMax) {
      let filterCommune = [];
      filterCommune = this.listBiensAll.filter((item) => {
        return (
          item.municipality.name.includes(commune) &&
          Number(prixMax) >= Number(item.cost)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneWithCommodite(commune, equipements) {
      let filterCommune = [];
      if (commune && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listBiensAll.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              item.municipality.name.includes(commune)
            ) {
              fitreCommodite.push(item);
              filterCommune = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterCommune);
            }
          });
        });
      }
      return filterCommune;
    },
    rechercheByNombrePiece(piece) {
      let filterCommune = [];
      filterCommune = this.listBiensAll.filter((item) => {
        return item.room.includes(piece);
      });
      return filterCommune;
    },
    rechercheByNbrePieceAndPrixMin(piece, prixMin) {
      let filterPiece = [];
      filterPiece = this.listBiensAll.filter((item) => {
        return (
          item.room.includes(piece) && Number(item.cost) >= Number(prixMin)
        );
      });
      return filterPiece;
    },
    rechercheByNbrePieceAndPrixMax(piece, prixMax) {
      let filterPiece = [];
      filterPiece = this.listBiensAll.filter((item) => {
        return (
          item.room.includes(piece) && Number(prixMax) >= Number(item.cost)
        );
      });
      return filterPiece;
    },
    rechercheByPieceWithCommodite(piece, equipements) {
      let filterPiece = [];
      if (piece && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listBiensAll.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              item.room.includes(piece)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByPrixMin(prixMin) {
      let filterPrixMin = [];
      filterPrixMin = this.listBiensAll.filter((item) => {
        return Number(item.cost) >= Number(prixMin);
      });
      return filterPrixMin;
    },
    rechercheByPrixMax(prixMax) {
      let filterPrixMin = [];
      filterPrixMin = this.listBiensAll.filter((item) => {
        return Number(prixMax) >= Number(item.cost);
      });
      return filterPrixMin;
    },
    rechercheByPrixMinAndPrixMax(prixMin, prixMax) {
      let filterPrixMin = [];
      filterPrixMin = this.listBiensAll.filter((item) => {
        return (
          Number(item.cost) >= Number(prixMin) &&
          Number(prixMax) >= Number(item.cost)
        );
      });
      return filterPrixMin;
    },
    rechercheByPrixMinWithCommodite(prixMin, equipements) {
      let filterPiece = [];
      if (prixMin && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listBiensAll.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              Number(item.cost) >= Number(prixMin)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByPrixMaxWithCommodite(prixMax, equipements) {
      let filterPiece = [];
      if (prixMax && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listBiensAll.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              Number(prixMax) >= Number(item.cost)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByPrixMaxAndPrixMinWithCommodite(prixMin, prixMax, equipements) {
      let filterPiece = [];
      if (prixMax && equipements && prixMin) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listBiensAll.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              Number(prixMax) >= Number(item.cost) &&
              Number(item.cost) >= Number(prixMin)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByCommodite(equipements) {
      let filterPiece = [];
      if (equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listBiensAll.filter((item) => {
            if (item.comodites.some((como) => como.label === el)) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByType(type) {
      let filterType = [];
      filterType = this.listBiensAll.filter((item) =>
        item.category.label.includes(type)
      );
      return filterType;
    },
    rechercheByVille(ville) {
      let filterVille = [];
      filterVille = this.listBiensAll.filter((item) => {
        return item.municipality.city.name.includes(ville);
      });
      return filterVille;
    },
    rechercheByVilleTypeMoyenSell(ville, type, moyen) {
      let filterAll = [];
      filterAll = this.listBiensAll.filter(
        (item) =>
          item.municipality.city.name.includes(ville) &&
          item.category.label.includes(type) &&
          item.types.label.includes(moyen)
      );
      return filterAll;
    },
    chooseMyVille() {
      this.chooseVilleName = this.chooseVille.name;
      //console.log("this.chooseVille",this.chooseVille)
      //console.log("this.listCommunesNotFilter",this.listCommunes)
     this.listCommuneFilter = this.listCommunes.filter(item=>item.city.name === this.chooseVille.name)
      //console.log("this.listCommuneFilter",this.listCommuneFilter)
      //console.log("this.chooseVilleName", this.chooseVilleName);
    },

    ifTypeWithMoyenSeel() {
      let resultFilter = [];
      console.log(this.chooseType, this.chooseMoyenSell);
      resultFilter = this.rechercheByTypeWithMoyen(
        this.chooseType,
        this.chooseMoyenSell
      );
      this.listBiensImmobilier = resultFilter;
      console.log(this.listBiensImmobilier);
    },
    ifTypeMoyenCommune() {
      let resultFilter = [];
      resultFilter = this.rechercheByTypeWithMoyenAndCommune(
        this.chooseType,
        this.chooseMoyenSell,
        this.chooseCommuneLabel,
        this.nbrPiece,
        this.prixMinimun,
        this.prixMaximun,
        this.ProprieteSuperficie
      );
      this.listBiensImmobilier = resultFilter;
    },
    rechercheByVilleTypeMoyenCommune(ville, type, moyen, commune) {
      let filterAll = [];
      filterAll = this.listBiensAll.filter(
        (item) =>
          item.municipality.city.name.includes(ville) &&
          item.category.label.includes(type) &&
          item.types.label.includes(moyen) &&
          item.municipality.name.includes(commune)
      );
      return filterAll;
    },
    rechercheByCommune(commune) {
      let filterAll = [];
      filterAll = this.listBiensAll.filter((item) =>
        item.municipality.name.includes(commune)
      );
      return filterAll;
    },
    rechercheByArea(area) {
      let filterAll = [];
      filterAll = this.listBiensAll.filter(
        (item) => Number(item.area) >= Number(area)
      );
      return filterAll;
    },

    launchSearch() {
      let resultFilter = [];
      if (this.chooseType) {
        console.log(1);
        resultFilter = this.rechercheByType(this.chooseType);
        this.listBiensImmobilier = resultFilter;
      }
      if (this.chooseCommuneLabel && this.chooseType) {
        console.log(`${this.chooseCommuneLabel},${this.chooseType}`);
        resultFilter = this.rechercheByCommuneWithType(
          this.chooseType,
          this.chooseCommuneLabel
        );
        this.listBiensImmobilier = resultFilter;
      }
      if (this.chooseType && this.chooseMoyenSell && this.chooseCommuneLabel) {
        this.ifTypeMoyenCommune();
        console.log(2);
      }
      if (this.chooseType && this.chooseMoyenSell) {
        this.ifTypeWithMoyenSeel();
        console.log(3);
      }
      if (this.nbrPiece && this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommuneAndPiece(
          this.chooseCommuneLabel,
          this.nbrPiece
        );
        this.listBiensImmobilier = resultFilter;
        console.log(4);
      }
      if (
        this.chooseVilleName &&
        this.chooseType &&
        this.chooseMoyenSell &&
        this.chooseCommuneLabel
      ) {
        resultFilter = this.rechercheByVilleTypeMoyenCommune(
          this.chooseVilleName,
          this.chooseType,
          this.chooseMoyenSell,
          this.chooseCommuneLabel
        );
        this.listBiensImmobilier = resultFilter;
        console.log(895);
      }
      if (this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommune(this.chooseCommuneLabel);
        this.listBiensImmobilier = resultFilter;
        console.log(89585);
      }

      if (this.prixMinimun && this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommuneAndPrixMin(
          this.chooseCommuneLabel,
          this.prixMinimun
        );
        this.listBiensImmobilier = resultFilter;
        console.log(5);
      }

      if (this.ProprieteSuperficie) {
        resultFilter = this.rechercheByArea(this.ProprieteSuperficie);
        this.listBiensImmobilier = resultFilter;
        console.log(685474854);
      }
      if (this.chooseCommuneLabel && this.equipementChoosen.length) {
        resultFilter = this.rechercheByCommuneWithCommodite(
          this.chooseCommuneLabel,
          this.equipementChoosen
        );
        this.listBiensImmobilier = resultFilter;
        console.log(7);
      }
      if (this.nbrPiece) {
        resultFilter = this.rechercheByNombrePiece(this.nbrPiece);
        this.listBiensImmobilier = resultFilter;
        console.log(8);
      }
      if (this.nbrPiece && this.prixMinimun) {
        resultFilter = this.rechercheByNbrePieceAndPrixMin(
          this.nbrPiece,
          this.prixMinimun
        );
        this.listBiensImmobilier = resultFilter;
        console.log(9);
      }
      if (this.nbrPiece && this.prixMaximun) {
        resultFilter = this.rechercheByNbrePieceAndPrixMax(
          this.nbrPiece,
          this.prixMaximun
        );
        this.listBiensImmobilier = resultFilter;
        console.log(10);
      }
      if (this.nbrPiece && this.equipementChoosen.length) {
        resultFilter = this.rechercheByPieceWithCommodite(
          this.nbrPiece,
          this.equipementChoosen
        );
        this.listBiensImmobilier = resultFilter;
        console.log(10);
      }
      if (this.prixMinimun) {
        resultFilter = this.rechercheByPrixMin(this.prixMinimun);
        this.listBiensImmobilier = resultFilter;
        console.log(11);
      }
      if (this.prixMaximun) {
        resultFilter = this.rechercheByPrixMax(this.prixMaximun);
        this.listBiensImmobilier = resultFilter;
        console.log(7895);
      }
      if (this.prixMinimun && this.prixMaximun) {
        resultFilter = this.rechercheByPrixMinAndPrixMax(
          this.prixMinimun,
          this.prixMaximun
        );
        this.listBiensImmobilier = resultFilter;
        console.log(12);
      }
      if (this.chooseVilleName) {
        resultFilter = this.rechercheByVille(this.chooseVilleName);
        this.listBiensImmobilier = resultFilter;
        console.log(13);
      }
      if (this.chooseVilleName && this.chooseType && this.chooseMoyenSell) {
        resultFilter = this.rechercheByVilleTypeMoyenSell(
          this.chooseVilleName,
          this.chooseType,
          this.chooseMoyenSell
        );
        this.listBiensImmobilier = resultFilter;
        console.log(18);
      }

      if (this.prixMinimun && this.equipementChoosen.length) {
        resultFilter = this.rechercheByPrixMinWithCommodite(
          this.prixMinimun,
          this.equipementChoosen
        );
        this.listBiensImmobilier = resultFilter;
        console.log(14);
      }
      if (this.prixMaximun && this.equipementChoosen.length) {
        resultFilter = this.rechercheByPrixMaxWithCommodite(
          this.prixMaximun,
          this.equipementChoosen
        );
        this.listBiensImmobilier = resultFilter;
        console.log(15);
      }
      if (
        this.prixMinimun &&
        this.prixMinimun &&
        this.equipementChoosen.length
      ) {
        resultFilter = this.rechercheByPrixMaxAndPrixMinWithCommodite(
          this.prixMinimun,
          this.prixMaximun,
          this.equipementChoosen
        );
        this.listBiensImmobilier = resultFilter;
        console.log(16);
      }
      if (this.equipementChoosen.length) {
        resultFilter = this.rechercheByCommodite(this.equipementChoosen);
        this.listBiensImmobilier = resultFilter;
        console.log(17);
      }

      // this.listBiensImmobilier=[]
    },
    resetField(){
      this.chooseType = "";
      this.chooseMoyenSell="";
      this.chooseVille = "";
      this.listBiensImmobilier = this.listBiensAll;
      this.prixMinimun = "";
      this.prixMaximun = "";
      this.equipementChoosen = [];
      this.nbrPiece = "";
      this.chooseCommuneLabel = "";
      this.chooseCommune = {};
      this.ProprieteSuperficie="";
   },
    filtrerBien() {
      //console.log("lancer");
      // this.isLoadingSearch = true;
      if(this.prixMaximun && this.prixMinimun>this.prixMaximun){
        Swal.fire({
          icon: "error",
          title: "Le prix maximal doit être supérieur au prix minimal",
          showConfirmButton: true,
        });
      }else{
        this.launchSearch();
      }
      // setTimeout(()=>{
      //   this.isLoadingSearch = false;
      // },1500)
   
      // try {
      //   // let data = {
      //   //   type: this.typeFilter,
      //   //   document: this.docFilter,
      //   //   room: this.nbrPiece,
      //   //   area: this.superficieFilter,
      //   //   municipality_id: this.chooseCommuneLabel,
      //   //   category_id: this.categoryFilter,
      //   //   user_id: this.$store.state.user.id,
      //   //   person_maxi: this.personneFilter,
      //   //   minPrix: this.prixMinimun,
      //   //   maxPrix: this.prixMaximun,
      //   // };

      //   this.isLoadingSearch = false;
      //   // const response = await instance.post("search", data);
      //   // if (response.data.status) {

      //   // }
      // } catch (error) {
      //   console.log(error);
      //   this.isLoadingSearch = false;
      // }
    },
    resetAllResidence() {
      this.listBiensImmobilier = this.listBiensAll;
      this.prixMinimun = "";
      this.prixMaximun = "";
      this.equipementChoosen = [];
      this.nbrPiece = "";
      this.chooseCommuneLabel = "";
      this.chooseCommune = {};
    },
    onPageChange(event) {
      //console.log("EVENT", event);
      this.currentPage = event.page + 1;
    },
    async getListTerrainAndMaison() {
      this.isLoading = true;
      try {
        const responseMaisons = await instance.get("getUnpayMaisons");
        const responseTerrain = await instance.get("getUnpayTerrains");

        const response = [];
        response.push(responseMaisons.data.data, responseTerrain.data.data);
        this.listBiensImmobilier = response.flat(Infinity);
        this.listBiensAll = response.flat(Infinity);

        //console.log("this.listBiensImmobilier", this.listBiensImmobilier);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    listWhilist() {
      this.$store.dispatch("getListFavoris");
      console.log(
        "this.$store.state.myListFavoris",
        this.$store.state.myListFavoris
      );
    },
    async dislikePropriete(element) {
      try {
        const responseLike = await instance.put("favorites/" + element.id);
        //console.log("responseLike", responseLike);
        if (responseLike.data.status === true) {
          let index = this.$store.state.myListFavoris.findIndex(
            (item) => item.property_id === element.id || item.id === element.id
          );
          this.$store.state.myListFavoris.splice(index, 1);
          this.$store.commit("LIST_FAVORIS", this.$store.state.myListFavoris);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async likePropriete(idDislike) {
      const { id } = idDislike;
      this.tableauLike.push(id);
      let data = {
        properties: this.tableauLike,
      };
      try {
        const responseLike = await instance.post("favorites", data);
        //console.log("responseLike", responseLike);
        if (responseLike.data.status === true) {
          this.$store.state.myListFavoris.push(idDislike);
          this.$store.commit("LIST_FAVORIS", this.$store.state.myListFavoris);
        }
      } catch (error) {
        console.log(error);
      }
    },
    renderHeartRed(Propriete) {
      //console.log("Propriete", Propriete);
      if (!this.$store.state.token) {
        Swal.fire({
          icon: "error",
          title: "Veuillez-vous connecter",
          showConfirmButton: true,
        });
        this.$router.push({
          path: "/connexion",
          query: { redirect: this.$route.path },
        });
      } else {
        console.log(this.$store.state.myListFavoris);
        if (
          this.$store.state.myListFavoris.some(
            (item) =>
              item.property_id === Propriete.id || item.id === Propriete.id
          )
        ) {
          this.dislikePropriete(Propriete);
        } else {
          this.likePropriete(Propriete);
        }
      }
    },
    async getEquipement() {
      try {
        const responseEquipement = await instance.get("comodites");
        const responseCommune = await instance.get("municipalite");
        const responseVille = await instance.get("cities");
        //console.log("responseEquipement", responseEquipement.data.data);
        //console.log("responseCommune", responseCommune.data.data);
        this.listEquipements = responseEquipement.data.data;
        this.listCommunes = responseCommune.data.data;
        this.allVilles = responseVille.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getListTerrainAndMaison();
    this.getEquipement();
    this.listWhilist();
  },
};
</script>
<template>
  <div>
    <div class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h2 class="ipt-title">Terrains et maisons</h2>
          </div>
        </div>
      </div>
    </div>
    <section class="gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="filter_search_opt" onclick="openFilterSearch()">
              <a href="javascript:void(0);"
                >Recherche<i class="ml-2 ti-menu"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div
              class="simple-sidebar sm-sidebar"
              id="filter_search"
              style="left: 0"
            >
              <div class="search-sidebar_header" onclick="closeFilterSearch()">
                <h4 class="ssh_heading">Fermer le filtre</h4>
                <button class="w3-bar-item w3-button w3-large">
                  <i class="ti-close"></i>
                </button>
              </div>

              <div class="sidebar-widgets">
                <label class="d-block text-start">Type de propriéte</label>
                <select v-model="chooseType" class="form-control">
                  <option value="" disabled>
                    Sélectionne le type de propriéte
                  </option>
                  <option value="Maison">Maison</option>
                  <option value="Terrain">Terrain</option>
                </select>
                <label class="d-block text-start">Type de transaction</label>
                <select v-model="chooseMoyenSell" class="form-control">
                  <option value="" disabled>
                    Sélectionne le type de transaction
                  </option>
                  <option value="Location">Location</option>
                  <option value="Vente">Vente</option>
                </select>
                <label class="d-block text-start">Ville</label>
                <div class="form-group simple">
                  <vue3-basic-autocomplete
                    v-model="chooseVille"
                    :options="allVilles"
                    trackby="name"
                    none-find=" désole , Pas de resultat"
                    placeholder="Recherche par ville"
                    input-class="form-control"
                    @change.prevent="chooseMyVille"
                  />
                </div>
                <label class="d-block text-start">Commune</label>
                <div class="form-group simple">
                  <vue3-basic-autocomplete
                    v-model="chooseCommune"
                    :options=" this.listCommuneFilter.length ? this.listCommuneFilter:this.listCommunes"
                    trackby="name"
                    none-find=" désole , Pas de resultat"
                    placeholder="Recherche par commune"
                    input-class="form-control"
                    @change="chooseMyCommune"
                  />
                </div>

                <div class="sidebar-widgets">
                  <div class="form-group simple" v-if="chooseType === 'Maison'">
                    <div class="input-with-icon">
                      <label class="d-block text-start">Nombre de pièces</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Nombre de pièces"
                        v-model="nbrPiece"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="simple-input">
                          <label class="d-block text-start">Prix Min</label>
                          <VueNumberFormat
                            class="form-control"
                            v-model:value="prixMinimun"
                            :options="{
                              precision: 0,
                              nullValue: false,
                              prefix: '',
                              suffix: ' XOF',
                              decimal: ',',
                              thousand: '.',
                              acceptNegative: false,
                              isInteger: false,
                            }"
                          ></VueNumberFormat>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="simple-input">
                          <label class="d-block text-start">Prix Maxi</label>
                          <VueNumberFormat
                            class="form-control"
                            v-model:value="prixMaximun"
                            :options="{
                              precision: 0,
                              nullValue: false,
                              prefix: '',
                              suffix: ' XOF',
                              decimal: ',',
                              thousand: '.',
                              acceptNegative: false,
                              isInteger: false,
                            }"
                          ></VueNumberFormat>
                        </div>
                      </div>
                    </div>
                    <p class="text-danger" v-if="prixMaximun && prixMinimun>prixMaximun">
                      Le prix maximal doit être supérieur au prix minimal</p>
                    <div
                      class="col-lg-12 col-md-12 col-sm-12 position-relative my-2"
                    >
                      <label class="d-block text-start"
                        >Superficie (m<sup>2</sup>)
                      </label>
                      <div class="text-center my-3">
                        <input
                          type="number"
                          class="form-control"
                          v-model="ProprieteSuperficie"
                        />
                      </div>
                      <!-- <a-slider
                        :tooltip-visible="false"
                        v-model:value="ProprieteSuperficie"
                        :max="1000"
                      /> -->
                    </div>
                  </div>

                  <div class="ameneties-features">
                    <div v-if="chooseType === 'Maison'">
                      <div class="form-group" id="module">
                        <a
                          role="button"
                          class=""
                          data-bs-toggle="collapse"
                          href="#advance-search"
                          aria-expanded="true"
                          aria-controls="advance-search"
                        ></a>
                      </div>
                      <div
                        class="collapse"
                        id="advance-search"
                        aria-expanded="false"
                        role="banner"
                      >
                        <ul class="no-ul-list">
                          <li
                            v-for="(item, index) in this.listEquipements"
                            :key="index"
                          >
                            <input
                              :id="item.label"
                              class="checkbox-custom"
                              name="a-1"
                              type="checkbox"
                              :value="item.label"
                              @change="chooseEquipement"
                              v-model="equipement"
                            />
                            <label
                              :for="item.label"
                              class="checkbox-custom-label"
                              >{{ item.label }}</label
                            >
                          </li>
                        </ul>
                      </div>
                    </div>

                    <button
                      class="btn btn btn-theme-light-2 rounded full-width 
                      filter-desktop"
                      @click.prevent="filtrerBien"
                    >
                      Filtre
                    </button>
                    <button 
                    @click.prevent="resetField"
                    class="btn btn 
                    my-3
                    fw-bold
                    bg-danger rounded full-width 
                    ">Rédemarrer</button>
                    <button
                     onclick="closeFilterSearch()"
                      class="btn btn btn-theme-light-2 rounded full-width 
                      filter-mobile"
                      @click.prevent="filtrerBien"
                    >
                      Filtre
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-12 list-layout position-relative">
            <div id="preloader position-absolute" v-show="preloader">
              <div class="preloader"><span></span><span></span></div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12">
                <div class="item-shorting-box">
                  <div class="item-shorting clearfix">
                    <div class="preloader" v-if="isLoadingSearch">
                      Chargement...
                      <ProgressSpinner
                        style="width: 25px; height: 25px"
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                        aria-label="Custom ProgressSpinner"
                      />
                    </div>
                    <div
                      class="left-column pull-left text-start d-flex justify-content-between align-items-center"
                    >
                      <h4 class="m-0">
                        {{ this.listBiensImmobilier.length }} résultats
                      </h4>
                      <button
                        class="bg-dark text-light btn rounded"
                        @click.prevent="resetAllResidence"
                      >
                        Tous
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row text-start" v-if="listBiensImmobilier.length">
              <div
                class="col-lg-12 col-md-12"
                v-for="(bien, index) in paginatedTerrainMaison"
                :key="index"
              >
                <div class="property-listing property-1 position-relative">
                  <div class="listing-img-wrapper">
                    <vueper-slides
                      :bullets="false"
                      fixed-height="200px"
                      v-if="bien.photos.length"
                    >
                      <vueper-slide
                        v-for="(slide, i) in bien.photos"
                        :key="i"
                        :image="lienImage + slide.image"
                      >
                      </vueper-slide>
                    </vueper-slides>
                    <div v-else>
                      <img
                        class="w-100 img-fluid"
                        src="../assets/house.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="listing-content">
                    <span
                      @click.prevent="renderHeartRed(bien)"
                      class="position-absolute"
                      style="
                        background: white;
                        padding: 0 0.5em;
                        top: 0.5em;
                        right: 0.5em;
                        border-radius: 5px;
                        box-shadow: 1px 1px 1px 1px black;
                        z-index: 50;
                        cursor: pointer;
                      "
                    >
                      <i
                        class="bi bi-heart-fill"
                        style="font-size: 1.5em"
                        :class="
                          this.$store.state.myListFavoris.some((el) =>el.id === bien.id || el.property_id === bien.id)
                            ? 'color-heart'
                            : null
                        "
                      ></i>
                    </span>
                    <div class="listing-detail-wrapper-box">
                      <div class="listing-detail-wrapper">
                        <div class="listing-short-detail">
                          <h4 class="listing-name">
                            <h5 href="single-property-2.html">
                              {{ bien.name }}
                            </h5>
                          </h4>
                          <div class="fr-can-rating">
                            <RatingStar
                              v-if="bien.moyenne"
                              :modelValue="bien.moyenne"
                              :readonly="true"
                              :stars="5"
                              :cancel="false"
                            />
                            <RatingStar
                              v-else
                              :readonly="true"
                              :stars="5"
                              :cancel="false"
                            />
                          </div>
                          <span class="prt-types rent"
                            >{{ bien.category.label }} /
                            {{ bien.types.label }}</span
                          >
                          <span
                            class="badge bg-danger w-25 my-2 superficie d-block"
                            v-if="bien.room"
                            >{{ bien.room }} pièces</span
                          >
                          <span v-if="bien.type" class="fw-bold"
                            >{{ bien.type }}
                          </span>
                        </div>
                        <div class="list-price">
                          <h6 class="listing-card-info-price text-dark">
                            {{ moneyFormat.format(bien.cost) }} XOF
                            <span v-if="bien.periode != null"
                              >/ {{ bien.periode }}</span
                            >
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="listing-footer-wrapper">
                      <div class="listing-locate">
                        <span class="listing-location"
                          ><i class="ti-location-pin"></i>
                          {{ bien.municipality.city.name }},
                          {{ bien.municipality.name }}
                        </span>
                      </div>
                      <div class="listing-detail-btn">
                        <router-link
                          :to="{ name: 'doVisite', params: { id: bien.id } }"
                          class="more-btn"
                          >Voir plus</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="listBiensImmobilier.length">
                <PaginatorVue
                  v-model="currentPage"
                  :rows="rows"
                  :totalRecords="listBiensImmobilier.length"
                  @page="onPageChange"
                ></PaginatorVue>
              </div>
            </div>

            <div v-else-if="!isLoading && !listBiensImmobilier.length">
              <h1>Pas de propriéte.</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>

.superficie {
  background-color: rgba(220, 20, 60, 0.357) !important;
}
.color-heart {
  color: crimson !important;
}
.checkbox {
  right: 1em;
  top: 0.4em;
  cursor: pointer;
}
.btn-theme-light-2,
.btn-theme-light-2:hover,
.more-btn {
  background-color: black !important;
  color: white !important;
}
.bg-danger {
  background-color: orange !important;
}
.pi-heart-fill {
  position: absolute;
  top: 0.5em;
  left: 1em;
  z-index: 99;
  cursor: pointer;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 10px;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.31);
}
.page {
  width: 30px;
  height: 30px;
}
.prev {
  background: crimson;
  color: white !important;
}
.pagination_btn {
  border: 1px solid white;
}
.color {
  background: crimson;
  color: white !important;
  font-weight: bold !important;
}
.next-prev {
  border: none;
  color: crimson;
  font-weight: bold !important;
}
</style>
