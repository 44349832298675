<script>
export default {
  name: "AsideDashComponent",
  setup() {},
};
</script>
<template>
  <div class="col-lg-2 col-md-12">
    <div class="simple-sidebar sm-sidebar" id="filter_search">
      <div class="search-sidebar_header"
      onclick="closeFilterSearch()"
      >
        <h4 class="ssh_heading">Fermer</h4>
        <button
          
          class="w3-bar-item w3-button w3-large"
        >
          <em class="ti-close"></em>
        </button>
      </div>

      <div class="sidebar-widgets">
        <div class="dashboard-navbar">
          <!-- <div class="d-user-avater">
                       
                        <i class="bi bi-person-fill img-fluid avater h1"></i>
                        <h4 v-if="this.$store.state.user">{{this.$store.state.user.nom}}  {{this.$store.state.user.prenom}}</h4>
                        <span>Propriétaire</span>
                    </div> -->

          <div class="d-navigation text-start">
            <ul>
              <li>
                <router-link :to="{ name: 'Bienvenue' }"
                  >Tableau de bord</router-link
                >
              </li>
              <li
              
              >
                <router-link :to="{ name: 'Profil' }">Mon profil</router-link>
              </li>
              <li
              
              >
                <router-link :to="{ name: 'notifications' }">
                  Mes notifications
                
                </router-link>
                <!-- <span class="badge bg-danger position-absolute ">1</span> -->
              </li>
              <li v-if="this.$store.state.user && 
              this.$store.state.user.roles.some(
                (item) => item.label == 'Propriétaire'
              )
            ">
                <router-link
                  :to="{ name: 'ListesResidences' }"
                  
                  >Location</router-link
                >
              </li>
              <li
                v-if="this.$store.state.user &&
                  this.$store.state.user.roles.some(
                    (item) => item.label == 'Propriétaire'
                  )
                "
              >
                <router-link :to="{ name: 'ListesProprieteVente' }"
                  >Vente</router-link
                >
              </li>
              <li
                v-if=" this.$store.state.user &&
                  this.$store.state.user.roles.some(
                    (item) => item.label == 'Propriétaire'
                  )
                "
              >
                <router-link :to="{ name: 'CreateResidence' }"
                  >Enregistrer ma propriété</router-link
                >
              </li>
              <li v-if=" this.$store.state.user && 
              this.$store.state.user.roles.some(
                (item) => item.label == 'Propriétaire'
              )
            ">
                <router-link
                  :to="{ name: 'revenus' }"
                  
                  >Mes revenus</router-link
                >
              </li>
              <li
                v-if="
                  this.$store.state.user.roles.some(
                    (item) => item.label == 'Client'
                  )
                "
              >
                <router-link :to="{ name: 'ListesResidencesReserveesValide' }"
                  >Mes réservations</router-link
                >
              </li>
              <li
              v-if="
                this.$store.state.user.roles.some(
                  (item) => item.label == 'Client'
                )
              "
            >
              <router-link :to="{ name: 'visites' }"
                >Mes visites</router-link
              >
            </li>
              <!-- <li
                v-if="
                  this.$store.state.user.roles.some(
                    (item) => item.label == 'Client'
                  )
                "
              >
                <router-link :to="{ name: 'HistoriqueVersement' }"
                  >Mes transactions</router-link
                >
              </li> -->

              <!-- <li><router-link :to="{name:'BienImmobilierVente'}">Terrain Et Maison En Attente</router-link></li>
                            <li><router-link :to="{name:'BienImmobilierAccept'}">Biens immobiliers Achetés ou loués</router-link></li>
                            <li><router-link :to="{name:'HistoriqueReservation'}">Historique de réservation et 
                                vente</router-link></li> -->
              <!-- <li><router-link :to="{name:'HistoriqueVersement'}">Historique des versements</router-link></li> -->

              <!-- <li><strong class="deconnexion">Se déconnecter</strong></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
ul li{
  position:relative;
  height:20px;
}
a {
  font-size: 1.1em !important;
  position:absolute;
  top:0;
  bottom:0;
  width:100%;
  height:100%;
  left:0;
  right:0;
}
a.router-link-exact-active {
  color: #03151b;
}
.deconnexion {
  cursor: pointer;
}
</style>
