<script>
import axios from "axios";
const baseUrl = "http://192.168.1.3:8080/api/";
import Swal from "sweetalert2";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

export default {
  data() {
    return {
      user: this.$store.state.user,
      preloaderVente: false,
      checkboxReservation: false,
      IdResidenceForCancel: "",
      preloaderHistorique: false,
      historiqueDetailsPaymentMoney: [],
      listReservationClient: [],
      spinner: false,
      numeroOfPayment:"",
      Today: 7,
      montant: "",
      preloader: false,
      coupon: "",
      historiqueRows: [
        {
          libelle: "Super Villa",
          reservation: "lundi 03 Mars 2022 au Mercredi 05 mars 2022",
          city: "Abidjan",
          tarif: "15.000 XOF /jour",
          montant: "45.000 XOF",
          logement: "Résidence",
          TypePropriete: "villa basse",
          Statut: "En attente d'approbation",
        },
      ],
      HistoriquePaiement: [],
      tab: "compte",
      showConfirmationForModify: false,
      ToggleShowRebootInformationPersonnelle: false,
      showDetailsResidence: false,
      listTerrainOrMaisonClient: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      seeDetailReservation: {},
      seeDetailreservationImmobilier: {},
      gallery: [],
      galleryBien: [],
      showDetailsBien: false,
      screenForPaymentResidence: false,
      seeDetailPayment: {},
      payment: "",
      otherPayment: "",
      IdReservationPayment: "",
      oldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
      seeDetailreservationBien: {},
      seeDetail: {},
      annuler: false,
      detailsResidencePayment: {},
      detailsResidencePaymentImmobilier: {},
    };
  },
  methods: {
    changePassword() {
      if (this.ConfirmPassword != this.NewPassword) {
        Swal.fire({
          icon: "error",
          title: "Confirmation de mot de passe incorrect.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let data = {
          oldPassword: this.oldPassword,
          password: this.NewPassword,
        };
        axios
          .post(baseUrl + "modifyPassword", data, {
            headers: { Authorization: "Bearer " + this.$store.state.token },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === true) {
              Swal.fire({
                icon: "success",
                title: "Mot de passe changé avec succès.",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status === false) {
              Swal.fire({
                icon: "error",
                title: "Mot de passe incorrect.",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    closeDetailResidence() {
      this.showDetailsResidence = !this.showDetailsResidence;
      this.gallery = [];
    },
    seeDetailResidence(id) {
      this.listReservationClient.forEach((reservation) => {
        if (reservation.id === id) {
          this.seeDetailReservation = reservation;
          this.seeDetailreservationImmobilier = reservation.immobilier;
          let address = "http://192.168.1.3:8080/public/image/";
          this.seeDetailreservationImmobilier.photos.forEach((item) => {
            if (item) {
              this.gallery.push(address + item.path);
              console.log(this.gallery);
            }
          });
          console.log(this.seeDetailReservation);
          console.log(this.seeDetailreservationImmobilier);
        }
      });
      this.showDetailsResidence = !this.showDetailsResidence;
    },
    paymentRealEstate(id) {
      this.IdReservationPayment = id;
      this.listReservationClient.forEach((item) => {
        if (item.id == id) {
          this.detailsResidencePayment = item;
          this.detailsResidencePaymentImmobilier = item.immobilier;
          //console.log("DETAILSPayment", this.detailsResidencePayment);
        }
      });
      //console.log("IDPAYEMENT", this.IdReservationPayment);
      this.screenForPaymentResidence = !this.screenForPaymentResidence;
    },
    closeScreenPayment() {
      this.screenForPaymentResidence = !this.screenForPaymentResidence;
      this.montant = "";
      this.coupon = "";
      this.payment = "";
    },
    closeDetailBien() {
      this.showDetailsBien = !this.showDetailsBien;
      this.galleryBien = [];
      console.log(this.galleryBien);
    },
    seeDetailBien(id) {
      this.listTerrainOrMaisonClient.forEach((reservation) => {
        if (reservation.id === id) {
          console.log(id);
          this.seeDetail = reservation;
          this.seeDetailreservationBien = reservation.immobilier;
          console.log(this.seeDetail);
          console.log(this.seeDetailreservationBien);
          let address = "http://192.168.1.3:8080/public/image/";
          reservation.immobilier.photos.forEach((item) => {
            this.galleryBien.push(address + item.path);
            //console.log("GALERY", this.gallery);
          });
          console.log(this.seeDetailReservation);
          console.log(this.seeDetailreservationBien);
        }
      });
      this.showDetailsBien = !this.showDetailsBien;
    },
    ShowListReservationClient() {
      this.preloader = true;
      axios
        .get(baseUrl + "histoclientR", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST BIEN EN ATTENTE", response.data);
          this.listReservationClient = response.data.data;
          this.preloader = false;
          //console.log("LIST BIEN EN ATTENTE", this.listReservationClient);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ShowListTerrainOrMaisonClient() {
      this.preloaderVente = true;
      axios
        .get(baseUrl + "histoclientTM", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST  TERRAIN MAISON", response.data);
          this.listTerrainOrMaisonClient = response.data.data;
          this.preloaderVente = false;
          //console.log("LIST  TERRAIN MAISON", this.listTerrainOrMaisonClient);
          setTimeout(function () {
            $("#MyTable").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    HistoriquePayment() {
      axios
        .get(baseUrl + "histoclientTM", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST  TERRAIN MAISON", response.data);
          this.HistoriquePaiement = response.data.data;
          //console.log("LIST  TERRAIN MAISON", this.HistoriquePaiement);
          setTimeout(function () {
            $("#MyTableHisto").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    modifyUserInfoPersonnel() {
      let data = {
        nom: this.user.nom,
        prenom: this.user.prenom,
        phone: this.user.phone,
        speudo: this.user.speudo,
      };
      this.$store.dispatch("ModifyUser", data);
    },
    voulezVousAnnulerReservation(id) {
      this.IdResidenceForCancel = id;
      console.log(this.IdResidenceForCancel);
      this.annuler = !this.annuler;
    },
    annulerCancelReservation() {
      this.IdResidenceForCancel = "";
      this.checkboxReservation = false;
      this.annuler = !this.annuler;
    },
    CancelReservation() {
      let data = {
        validation: 3,
      };
      
      console.log(this.IdResidenceForCancel);
      axios
        .post(baseUrl + "updatvald/" + this.IdResidenceForCancel, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            this.listReservationClient.forEach((item) => {
          if (item.id == this.IdResidenceForCancel) {
          item.validation = 3;
        }
      });
            this.annuler = !this.annuler;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
                icon: "error",
                title: "Problème avec la base de donnée",
                showConfirmButton: false,
                timer: 1500,
              });
        });
    },
    paymentReservationRealEstate() {
      this.spinner = true;
      let data = {
        montant: this.montant,
        type: this.payment,
        phone:this.numeroOfPayment,
        reservation_id: this.IdReservationPayment,
        validation: 0,
        code: this.coupon,
      };
      axios
        .post(baseUrl + "paiements", data, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "true") {
            Swal.fire({
              icon: "success",
              title: "Paiement effectué,Merci et à bientot.",
              showConfirmButton: false,
              timer: 1500,
            })
            setTimeout(()=>{
             this.$router.push('/espace_client')   
            },1500)
            this.tab = "listeReservation"
            this.spinner = false;
            
          }
          if (res.data.status == "false") {
            Swal.fire({
              icon: "info",
              title: res.data.data,
              showConfirmButton: false,
              timer: 3000,
            });
            this.spinner = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.spinner = false;
        });
    },
    HistoriquePaymentClientglobal() {
      this.preloaderHistorique = true;
      axios
        .get(baseUrl + "Dpaiementclient", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("HISTORIQUEPAYMENT", response);
          this.historiqueDetailsPaymentMoney = response.data.data;
          this.preloaderHistorique = false;
          //console.log("HISTORIQUEPAYMENT", this.historiqueDetailsPaymentMoney);
          setTimeout(function () {
            $("#MyTableDetailsPaiement").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.ShowListReservationClient();
    this.ShowListTerrainOrMaisonClient();
    this.HistoriquePayment();
    this.HistoriquePaymentClientglobal();
  },
};
</script>
<template>
  <div class="col-lg-12 col-md-12 pb-5">
    <div class="confirmation_modifie annuler_reservation" v-if="annuler">
      <h6>Voulez-vous annuler la réservation ?</h6>
      <button
        class="btn bg-danger mx-2 text-white"
        @click.prevent="annulerCancelReservation"
      >
        Retour
      </button>
      <button
        class="btn bg-dark mx-2 text-white"
        @click.prevent="CancelReservation"
      >
        Annuler
      </button>
    </div>

    <div class="conteneur_modify" v-show="showDetailsResidence">
      <div class="content position-relative">
        <div
          class="ecriteau text-start d-flex justify-content-between align-items-center"
        >
          <h4>Détails Résidence</h4>
          <em
            class="bi bi-x-circle h1"
            @click.prevent="closeDetailResidence"
          ></em>
        </div>
        <div class="submit-pages text-start">
          <div class="boxTotal pb-5">
            <h5 class="badge bg-danger">Détails résidence</h5>

            <h5>
              <strong>Date</strong> :
              {{
                new Date(seeDetailReservation.created_at).toLocaleDateString(
                  "fr"
                )
              }}
            </h5>
            <h5>
              <strong>Propriété</strong> :
              {{ seeDetailreservationImmobilier.libelle }}
            </h5>

            <h5 v-if="seeDetailreservationImmobilier.type">
              <strong>Type de Propriété</strong> :
              {{ seeDetailreservationImmobilier.type.libelle }}
            </h5>
            <h5 v-if="seeDetailreservationImmobilier.nbre_pieces">
              <strong>Nombre de Pièces</strong> :
              {{ seeDetailreservationImmobilier.nbre_pieces }}
            </h5>

            <h5>
              <strong>Tarif</strong> :
              {{ moneyFormat.format(seeDetailreservationImmobilier.tarif) }}
              <span v-if="seeDetailreservationImmobilier.periode"
                >par {{ seeDetailreservationImmobilier.periode }}</span
              >
            </h5>
            <!-- <span>{{Number(seeDetailreservationImmobilier.tarif) * (((new Date(seeDetailReservation.date_fin).getTime() -new Date(seeDetailReservation.date_debut).getTime())/(1000 * 3600 * 24))+1)}}</span> -->
            <h5 v-if=" JSON.stringify(new Date(seeDetailReservation.date_fin)) != JSON.stringify(new Date(seeDetailReservation.date_debut)) ">
              <strong>Montant Total</strong> :
              {{
                moneyFormat.format(Number(seeDetailreservationImmobilier.tarif) * (((new Date(seeDetailReservation.date_fin).getTime() -new Date(seeDetailReservation.date_debut).getTime())/(1000 * 3600 * 24))+1))
              }}
              XOF 
            </h5>
            <h5 v-else-if="JSON.stringify(new Date(seeDetailReservation.date_fin)) === JSON.stringify(new Date(seeDetailReservation.date_debut)) ">
              <strong>Montant Total</strong> :
              {{ moneyFormat.format(seeDetailreservationImmobilier.tarif) }}
              XOF
            </h5>
            <h5 v-else>
              <strong>Montant Total</strong> :
              {{ moneyFormat.format(seeDetailreservationImmobilier.tarif) }}
              XOF
            </h5>
            <h5
              v-if="
                seeDetailReservation.date_debut || seeDetailReservation.date_fin
              "
            >
              <strong>Date de reservation</strong> :
              {{
                new Date(seeDetailReservation.date_debut).toLocaleDateString(
                  "fr",
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )
              }}
              <span v-if="seeDetailReservation.date_fin">
                <strong>au</strong>
                {{
                  new Date(seeDetailReservation.date_fin).toLocaleDateString(
                    "fr",
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )
                }}</span
              >
            </h5>
            <h5 v-if="seeDetailreservationImmobilier.commentaire != null">
              <strong>Raison concernant l annulation</strong> :{{seeDetailreservationImmobilier.commentaire}}
            </h5>
            <h5 class="badge bg-danger">Lieu</h5>
            <h5 v-if="seeDetailreservationImmobilier.ville">
              <strong>Ville</strong> :
              {{ seeDetailreservationImmobilier.ville.libelle }}
            </h5>
            <h5>
              <strong>Quartier</strong> :
              {{ seeDetailreservationImmobilier.quartier }}
            </h5>
            <h5 v-if="seeDetailreservationImmobilier.precission">
              <strong>Autre précision</strong> :
              {{ seeDetailreservationImmobilier.precission }}
            </h5>
            <h5 v-if="seeDetailreservationImmobilier.description">
              <strong class="d-block">Description </strong>
              {{ seeDetailreservationImmobilier.description }}
            </h5>
            <h5 class="badge bg-danger">Galerie</h5>
            <MazGallery v-if="gallery" :images="gallery" :height="400" />
          </div>
        </div>
      </div>
    </div>

    <div class="conteneur_modify" v-show="showDetailsBien">
      <div class="content position-relative">
        <div
          class="ecriteau text-start d-flex justify-content-between align-items-center"
        >
          <h4>Détails Bien</h4>
          <em class="bi bi-x-circle h1" @click.prevent="closeDetailBien"></em>
        </div>
        <div class="submit-pages text-start">
          <div class="boxTotal pb-5">
            <h5 class="badge bg-primary">Détails Bien</h5>
            <h5>
              <strong>Date</strong> :
              {{ new Date(seeDetail.created_at).toLocaleDateString("fr") }}
            </h5>
            <h5>
              <strong>Propriété</strong> :
              {{ seeDetailreservationBien.libelle }}
            </h5>

            <h5 v-if="seeDetailreservationBien.type">
              <strong>Type de Propriété</strong> :
              {{ seeDetailreservationBien.type.libelle }}
            </h5>
            <h5 v-if="seeDetailreservationBien.nbre_pieces">
              <strong>Nombre de Pièces</strong> :
              {{ seeDetailreservationBien.nbre_pieces }}
            </h5>

            <h5>
              <strong>Tarif</strong> :
              {{ moneyFormat.format(seeDetailreservationBien.tarif) }}
              XOF
              <span v-if="seeDetailreservationBien.periode"
                >par {{ seeDetailreservationBien.periode }}</span
              >
            </h5>
            <h5 v-if="new Date(seeDetail.date_fin).getTime() != new Date(seeDetail.date_debut).getTime()">
              <strong>Montant Total</strong> :
              {{
                moneyFormat.format(
                  seeDetailreservationBien.tarif *
                    ((new Date(seeDetail.date_fin).getTime() -
                      new Date(seeDetail.date_debut).getTime()) /
                      (1000 * 3600 * 24))
                )
              }}
              XOF
            </h5>
            <h5
                v-else-if="
                  new Date(seeDetail.date_fin).getTime() ==
                  new Date(seeDetail.date_debut).getTime()
                "
              >
              {{ moneyFormat.format(seeDetailreservationBien.tarif) }}
              XOF
              </h5>
            <h5 v-else>
              <strong>Montant Total</strong> :
              {{ moneyFormat.format(seeDetailreservationBien.tarif) }}
              XOF
            </h5>
            <h5 v-if="seeDetail.date_debut || seeDetail.date_fin">
              <strong>Date de reservation</strong> :
              {{ new Date(seeDetail.date_debut).toLocaleDateString("fr") }}
              <span v-if="seeDetail.date_fin">
                au
                {{
                  new Date(seeDetail.date_fin).toLocaleDateString("fr")
                }}</span
              >
            </h5>
            <h5 class="badge bg-secondary">Lieu</h5>
            <h5 v-if="seeDetailreservationBien.ville">
              <strong>Ville</strong> :
              {{ seeDetailreservationBien.ville.libelle }}
            </h5>
            <h5>
              <strong>Quartier</strong> :
              {{ seeDetailreservationBien.quartier }}
            </h5>
            <h5 v-if="seeDetailreservationBien.precission">
              <strong>Autre précision</strong> :
              {{ seeDetailreservationBien.precission }}
            </h5>
            <h5 v-if="seeDetailreservationBien.description">
              <strong class="d-block">Description :</strong>
              {{ seeDetailreservationBien.description }}
            </h5>
            <h5 class="badge bg-danger">Galerie</h5>
            <MazGallery
              v-if="galleryBien"
              :images="galleryBien"
              :height="400"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="conteneur_modify" v-show="screenForPaymentResidence">
      <div class="content position-relative">
        <div class="skeleton" v-show="spinner">
          <div class="spinner-border chargement" role="status"></div>
        </div>
        <div
          class="ecriteau text-start d-flex justify-content-between align-items-center"
        >
          <h4>Effectué un Paiement</h4>

          <em
            class="bi bi-x-circle h1"
            @click.prevent="closeScreenPayment"
          ></em>
        </div>
        <div class="submit-pages text-start">
          <div class="boxTotal pb-5">
            <!-- <h5 class="text-center">
              Montant à versé :
              <span
                v-if="
                
                JSON.stringify(new Date(detailsResidencePayment.date_fin)) !=
                JSON.stringify(new Date(detailsResidencePayment.date_debut))
                "
              >
              {{
                moneyFormat.format(Number(seeDetailreservationImmobilier.tarif) * (((new Date(detailsResidencePayment.date_fin).getTime() -new Date(detailsResidencePayment.date_debut).getTime())/(1000 * 3600 * 24))+1))
              }}
                XOF
              </span>
              <span
                v-else-if="
                JSON.stringify(new Date(detailsResidencePayment.date_fin)) ==
                JSON.stringify(new Date(detailsResidencePayment.date_debut))
                "
              >
                {{
                  moneyFormat.format(detailsResidencePaymentImmobilier.tarif)
                }}
                XOF
              </span>
              <span v-else>
                {{
                  moneyFormat.format(detailsResidencePaymentImmobilier.tarif)
                }}
                XOF
              </span>
            </h5> -->
            <form>
              <div class="row container">
                <div>
                  <label class="text-start">Numéro de télephone</label>
                  <input type="text" class="form-control" v-model="numeroOfPayment">
                  <label class="text-start">Montant</label>
                  <VueNumberFormat
                    class="form-control"
                    v-model:value="montant"
                    :options="{
                      precision: 0,
                      nullValue: false,
                      prefix: '',
                      suffix: ' XOF',
                      decimal: ',',
                      thousand: '.',
                      acceptNegative: false,
                      isInteger: false,
                    }"
                  ></VueNumberFormat>
                </div>
                <div v-if="otherPayment == false">
                  <label class="text-start">Type d'opération</label>
                  <select name="" id="" v-model="payment" class="form-control">
                    <option value="" disabled>
                      Veuillez sélectionner un type d'opération
                    </option>
                    <option value="moov">Moov</option>
                    <option value="orange">Orange</option>
                    <option value="mtn">Mtn</option>
                    <option value="wave">Wave</option>
                  </select>
                </div>
                <div
                  class="d-flex justify-content-start align-items-center my-3"
                >
                  <input type="checkbox" id="other" v-model="otherPayment" />
                  <label for="other">Autre</label>
                </div>
                <div v-if="otherPayment == true">
                  <label class="text-start">Autre type d'opération</label>
                  <input type="text" class="form-control" v-model="payment" />
                </div>
                <div class="my-3">
                  <label class="text-start d-block">Coupon</label>
                  <input
                    type="text"
                    class="form-control"
                    id="other"
                    placeholder="Entrer un code"
                    v-model="coupon"
                  />
                </div>
              </div>
              <div class="my-3">
                <button
                  class="btn bg-primary w-100"
                  @click.prevent="paymentReservationRealEstate"
                >
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="conteneur_modify"
      id="modalModify"
      v-show="ToggleShowRebootInformationPersonnelle"
    >
      <div class="content position-relative">
        <div
          class="ecriteau text-start d-flex justify-content-between align-items-center"
        >
          <h4>Modifier mes informations personnelles</h4>
          <em
            class="bi bi-x-circle h1"
            @click.prevent="
              ToggleShowRebootInformationPersonnelle =
                !ToggleShowRebootInformationPersonnelle
            "
          ></em>
        </div>
        <form class="p-5" @submit.prevent="modifyUserInfoPersonnel">
          <div class="submit-section">
            <div class="row text-start">
              <div class="form-group col-md-6">
                <label>Nom</label>
                <input type="text" class="form-control" v-model="user.nom" />
              </div>
              <div class="form-group col-md-6">
                <label>Prénoms</label>
                <input type="text" class="form-control" v-model="user.prenom" />
              </div>

              <div class="form-group col-md-6">
                <label>Téléphone</label>
                <input type="text" class="form-control" v-model="user.phone" />
              </div>
              <div class="form-group col-md-6">
                <label>Pseudo</label>
                <input type="text" class="form-control" v-model="user.speudo" />
              </div>
            </div>
          </div>
          <div class="text-start">
            <button type="submit" class="btn bg-primary text-white">
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
    <ul
      class="d-flex justify-content-start align-items-center px-5 pt-5 listes"
    >
      <li class="mx-5">
        <a
          href="#"
          :class="{ color: tab == 'compte' }"
          class="lien"
          @click.prevent="tab = 'compte'"
          >Compte</a
        >
      </li>
      <li class="mx-3">
        <a
          href="#"
          :class="{ colorNew: tab == 'listeReservation' }"
          class="lien"
          @click.prevent="tab = 'listeReservation'"
          >Liste de réservation</a
        >
      </li>
      <li class="mx-3">
        <a
          href="#"
          :class="{ colorNew: tab == 'ListeAchatTerrainAndMaison' }"
          class="lien"
          @click.prevent="tab = 'ListeAchatTerrainAndMaison'"
          >Liste des Propriétés (Terrain & Maison)
        </a>
      </li>
      <li class="mx-3">
        <a
          href="#"
          :class="{ colorNew: tab == 'HistoriquePaiement' }"
          class="lien"
          @click.prevent="tab = 'HistoriquePaiement'"
          >Historique de paiement
        </a>
      </li>
    </ul>
    <section v-show="tab === 'compte'">
      <form>
        <div class="d-flex justify-content-start align-items-center">
          <h4 class="text-start fw-bold">Informations personnelles</h4>
          <em
            class="bi bi-pencil mx-4"
            @click.prevent="
              ToggleShowRebootInformationPersonnelle =
                !ToggleShowRebootInformationPersonnelle
            "
          ></em>
        </div>
        <div class="submit-section">
          <div class="row text-start">
            <div class="form-group col-md-6">
              <label>Nom</label>
              <input
                type="text"
                class="form-control"
                v-model="user.nom"
                disabled
              />
            </div>
            <div class="form-group col-md-6">
              <label>Prénoms</label>
              <input
                type="text"
                class="form-control"
                v-model="user.prenom"
                disabled
              />
            </div>

            <div class="form-group col-md-6">
              <label>Téléphone</label>
              <input
                type="text"
                class="form-control"
                v-model="user.phone"
                disabled
              />
            </div>
            <div class="form-group col-md-6" v-if="user.speudo">
              <label>Pseudo</label>
              <input
                type="text"
                class="form-control"
                v-model="user.speudo"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="form-submit text-start">
          <h4 class="fw-bold">Changer mon mot de passe</h4>
          <div class="submit-section">
            <form @submit.prevent="changePassword">
              <div class="row">
                <div class="form-group col-lg-12 col-md-6">
                  <label>Ancien mot de passe</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="oldPassword"
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Nouveau mot de passe</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="NewPassword"
                    required
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Confirmer mot de passe</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="ConfirmPassword"
                    required
                  />
                </div>

                <div class="form-group col-lg-12 col-md-12">
                  <button class="btn btn-theme-light-2 rounded" type="submit">
                    Modifier
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>
    </section>
    <section v-show="tab === 'listeReservation'">
      <div class="form" :class="preloader ? 'pre' : 'null'">
        <div id="preloader position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h4 class="text-start fw-bold">Liste des réservations</h4>

        <div class="col-sm-12" v-if="preloader == false">
          <table id="MyTableData" class="table text-center">
            <thead>
              <tr class="text-center">
                <th class="bg-light">Propriété</th>
                <th class="bg-light">Image</th>
                <th class="bg-light">Ville</th>
                <th class="bg-light">Tarif</th>
                <th class="bg-light">Type de logement</th>
                <th class="bg-light">Type de propriété</th>
                <th class="bg-light">Date de réservation</th>
                <th class="bg-light">Statut</th>
                <th class="bg-light">Annuler</th>
                <th class="bg-light">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listReservationClient" :key="index">
                <td v-if="item.immobilier">{{ item.immobilier.libelle }}</td>
                <td v-if="item.immobilier">
                  <n-image
                    width="50"
                    v-if="
                      item.immobilier.photos.length || item.immobilier.photos[0]
                    "
                    :src="
                      'http://192.168.1.3:8080/public/image/' +
                      item.immobilier.photos[0].path
                    "
                    alt="photo"
                  />

                  <span v-else>Pas d'image</span>
                </td>
                <td v-if="item.immobilier">
                  {{ item.immobilier.ville.libelle }}
                </td>
                <td v-if="item.immobilier">
                  {{ moneyFormat.format(item.immobilier.tarif) }} XOF
                  <span v-if="item.immobilier.periode">
                    par {{ item.immobilier.periode }}</span
                  >
                </td>

                <td v-if="item.immobilier">
                  {{ item.immobilier.type_logement }}
                </td>
                <td v-if="item.immobilier">
                  {{ item.immobilier.type.libelle }}
                </td>
                <td>
                  {{
                    new Date(item.date_debut).toLocaleDateString("fr", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  }}
                  <span v-if="item.date_fin">
                    au
                    {{
                      new Date(item.date_fin).toLocaleDateString("fr", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}</span
                  >
                </td>
                <td>
                  <span v-if="item.validation === 1" class="badge bg-success"
                    >Approuveé</span
                  >
                  <span
                    v-if="item.validation === 2 || item.validation === 3"
                    class="badge bg-danger"
                    >Annulée</span
                  >
                  <span
                    v-if="item.validation === 0 || item.validation === null"
                    class="badge bg-dark"
                  >
                    En attente</span
                  >
                </td>
                <td>
                  <input
                    v-if="
                      Math.ceil(
                        (new Date(item.date_debut).getTime() -
                          new Date().getTime()) /
                          (1000 * 3600 * 24)
                      ) > 7 && item.validation == 1
                    "
                    type="checkbox"
                    :value="item.id"
                    :id="item.id"
                    v-model="checkboxReservation"
                    @input.prevent="voulezVousAnnulerReservation(item.id)"
                  />
                  <span
                    v-if="
                      (Math.ceil(
                        new Date(item.date_debut).getTime() -
                          new Date().getTime()) /
                          (1000 * 3600 * 24)
                      ) < 7 && item.validation == 1
                    "
                    >
                    <span v-if="item.validation == 3"><em class="bi bi-dash-circle text-danger"></em
                      ></span>
                    <span v-else>l'annulation peut se faire une semaine avant la date de
                      reservation.</span>
                    </span
                  >
                 
                  <span v-if="item.validation == 0"
                    >Vous pouvez annuler après acceptation</span
                  >
                  <span v-if="item.validation == 2 || item.validation == 3"
                    ><em class="bi bi-dash-circle text-danger"></em
                  ></span>
                </td>
                <td>
                  <span
                    class="d-flex justify-content-center align-items-center"
                  >
                    <em
                      class="bi bi-eye mx-3"
                      @click.prevent="seeDetailResidence(item.id)"
                    ></em>
                    <em
                      v-if="item.validation === 1"
                      class="bi bi-cash-stack mx-3"
                      @click.prevent="paymentRealEstate(item.id)"
                    ></em>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section v-show="tab === 'ListeAchatTerrainAndMaison'">
      <div class="form" :class="preloaderVente ? 'pre' : 'null'">
        <div id="preloader position-absolute" v-show="preloaderVente">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h4 class="text-start fw-bold">Liste de ventes et locations</h4>

        <div class="col-sm-12" v-if="preloaderVente == false">
          <table id="MyTable" class="table text-center">
            <thead>
              <tr class="text-center">
                <th class="bg-light">Propriété</th>
                <th class="bg-light">Image</th>
                <th class="bg-light">Ville</th>
                <th class="bg-light">Tarif</th>
                <th class="bg-light">Type de logement</th>
                <th class="bg-light">Statut</th>
                <th class="bg-light">Détail</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in listTerrainOrMaisonClient"
                :key="index"
              >
                <td>{{ item.immobilier.libelle }}</td>
                <td>
                  <n-image
                    width="50"
                    v-if="
                      item.immobilier.photos.length || item.immobilier.photos[0]
                    "
                    :src="
                      'http://192.168.1.3:8080/public/image/' +
                      item.immobilier.photos[0].path
                    "
                    alt="photo"
                  />

                  <span v-else>Pas d'image</span>
                </td>
                <td v-if="item.immobilier.ville">
                  {{ item.immobilier.ville.libelle }}
                </td>
                <td>
                  {{ moneyFormat.format(item.immobilier.tarif) }} XOF
                  <span v-if="item.immobilier.periode">
                    par {{ item.immobilier.periode }}</span
                  >
                </td>

                <td>
                  {{ item.immobilier.type_logement }}
                </td>

                <td>
                  <span v-if="item.validation === 1" class="badge bg-success"
                    >Approuveé</span
                  >
                  <span
                    v-if="item.validation === 0 || item.validation === null"
                    class="badge bg-danger"
                  >
                    En attente</span
                  >
                </td>
                <td>
                  <em
                    class="bi bi-eye"
                    @click.prevent="seeDetailBien(item.id)"
                  ></em>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section v-show="tab === 'HistoriquePaiement'">
      <div class="form" :class="preloaderHistorique ? 'pre' : 'null'">
        <div id="preloader position-absolute" v-show="preloaderHistorique">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h4 class="text-start fw-bold">Historique de paiement</h4>

        <div class="col-sm-12" v-if="preloaderHistorique == false">
          <table id="MyTableDetailsPaiement" class="table text-center">
            <thead>
              <tr class="text-center">
                <th class="bg-light">Date de paiement</th>
                <th class="bg-light">Propriété</th>
                <th class="bg-light">Date de réservation</th>
                <th class="bg-light">Tarif</th>
                <th class="bg-light">Montant versé</th>
                <th class="bg-light">Reste</th>
              </tr>
            </thead>
            <tbody v-if="historiqueDetailsPaymentMoney.length">
              <tr
                v-for="(item, index) in historiqueDetailsPaymentMoney"
                :key="index"
              >
                <td >
                  <span v-if="item.created_at">
                    {{
                      new Date(item.created_at).toLocaleDateString("fr", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}
                  </span>
                </td>
                <td >
                 <span v-if="item.reservations">
                  {{ item.reservations.immobilier.libelle }}
                 </span>
                </td>
                <td >
                  <span v-if="item.reservations">
                    {{
                      new Date(item.reservations.date_debut).toLocaleDateString(
                        "fr",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    }}

                    <span v-if="item.reservations.date_fin">
                      au
                      {{
                        new Date(item.reservations.date_fin).toLocaleDateString(
                          "fr",
                          {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      }}</span
                    >

                  </span>
               
                  
                </td>

                <td>
                  <span v-if="item.reservations">
                    {{
                      moneyFormat.format(item.reservations.immobilier.tarif)
                    }}
                    XOF
                    <span v-if="item.reservations.immobilier.periode">
                      par {{ item.reservations.immobilier.periode }}</span
                    >
                  </span>
                
                </td>

                <td >
                  <span v-if="item.montant">
                    {{ moneyFormat.format(item.montant) }} XOF
                  </span>
                  
                </td>
                <td>
                  <span v-if="item.reste">
                    {{ moneyFormat.format(item.reste) }} XOF</span
                  >
                  <!-- <span v-else>0 XOF</span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.skeleton {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: transparent;
  z-index: 999;
  display: flex;
  justify-content: center;
  place-items: flex-start;
}
.btn-theme-light-2 {
  background-color: black !important;
  color:white !important;
}
.btn-theme-light-2:hover {
  background-color: black !important;
  color:white !important;
}
.bi,
[type="checkbox"] {
  cursor: pointer;
}
.pre {
  margin: 0 0 15em 0;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.form,form{
  padding: 0 2em;
}
.listes {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.287);
}
.pb-5 {
  padding-bottom: 7em !important;
}
.lien {
  text-decoration: none;
  font-size: 1.3em;
  color: rgb(222, 222, 222);
}
.lien:hover {
  color: black;
}
.color,
.colorHistorique,
.colorNew {
  color: rgb(0, 0, 0);
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index: 999;
}
.annuler_reservation {
  left: 50%;
  transform: translateX(-50%);
  margin: 10em 0 0 0;
  z-index: 99 !important;
}
.confirmation_modifie {
  position: absolute;
  z-index: 9999;
  background-color: white;
  padding: 2em;
  border: 3px solid black;
  border-radius: 1em;
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: transparent;
  display: flex;
  justify-content: center;
  place-items: center;
}
.submit-pages {
  padding: 1em 3em;
}
.conteneur_modify .content {
  width: 60%;
  border-radius: 0.5em;

  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  box-shadow: 1px 0 1px 3px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
</style>
