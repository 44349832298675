<script>
import instance from "../../api/api";
export default {
  name: "listeResidenceReservationValidee",
  //   components: {
  //   KCheckbox,
  // },
  data() {
    return {
      listResidenceVisite: [],
      preloader: false,
      activeResidence: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      perPage: 3,
      currentPage: 1,
      current: 1,
      rows: 6,
    };
  },
  computed: {
    paginatedVisites() {
      const startIndex = (this.currentPage - 1) * this.rows;
      //console.log("startIndex", startIndex);
      const endIndex = startIndex + this.rows;
      //console.log("endIndex", endIndex);
      return this.listResidenceVisite.slice(startIndex, endIndex);
    },
  },
  methods: {
    onPageChange(event) {
      //console.log("EVENT", event);
      this.currentPage = event.page + 1;
    },
    ToogleResidence(e) {
      console.log(this.activeResidence);
      let data = {
        validation: 1,
      };
      let IdResidence = Number(e.target.value);
      this.listResidenceVisite.forEach((item) => {
        if (item.id === IdResidence) {
          item.validation = 1;
        }
      });
      instance
        .post("updatevalid/" + IdResidence, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ToggleResidenceNotActive(e) {
      let data = {
        validation: 0,
      };
      let IdResidence = Number(e.target.value);
      this.listResidenceVisite.forEach((item) => {
        if (item.id === IdResidence) {
          item.validation = 1;
        }
      });
      instance
        .post("updatevalid/" + IdResidence, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ShowListResidenceVisiteValidee() {
      this.preloader = true;
      instance
        .get("VisitClient")
        .then((response) => {
          //console.log("LIST VISITES", response);
          this.listResidenceVisite = response.data.data;
          this.preloader = false;
          //console.log("LIST VISITES", this.listResidenceVisite);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.ShowListResidenceVisiteValidee();
  },
};
</script>
<template>
  <div class="col-lg-10 col-md-12">
    <div class="dashboard-wraper">
      <div class="form-submit text-start" :class="preloader ? 'pre' : 'null'">
        <div id="preloader position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h4>Visites</h4>
        <span class="py-3"
          >{{ this.listResidenceVisite.length }} résultats</span
        >
      </div>
      <div class="row">
        <div
          v-for="item in paginatedVisites"
          :key="item.id"
          class="col-lg-4 col-md-6 col-sm-12 position-relative"
        >
          <div class="dashboard-stat widget-1 text-left">
            <div class="dashboard-stat-content">
              <h6>{{ item.propriety.name }}</h6>
              <span>Date de la visite:{{ item.date }}</span>
            </div>
            <span
              class="bg-info text-dark badge alert-success position-absolute"
            >
              {{ item.status }}</span
            >
            <span class="alert-date position-absolute">
              {{ new Date(item.created_at).toLocaleDateString("fr") }}</span
            >
            <!-- <button class="btn bg-dark btn-voir text-light position-absolute">
          <router-link
          :to="{
            name: 'detailResidence',
            params: { id: item.id },
          }"
          >Voir plus</router-link
        >
              
            </button> -->
          </div>
        </div>
        <div v-if="listResidenceVisite.length">
          <PaginatorVue
            v-model="currentPage"
            :rows="rows"
            :totalRecords="listResidenceVisite.length"
            @page="onPageChange"
          ></PaginatorVue>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>


.alert-success {
  left: 0 !important;
  top: 0.5em !important;
}
.alert-date {
  right: 0 !important;
  top: 0.5em !important;
}
.btn-voir {
  right: 0 !important;
  bottom: 0 !important;
}
</style>
