<script>
// import { VueperSlides, VueperSlide } from "vueperslides";
// import "vueperslides/dist/vueperslides.css";
import Swal from "sweetalert2";
import instance from "../api/api";

export default {
  name: "HomeView",
  components: {
    // Vue3BasicAutocomplete,
    // VueperSlides,
    // VueperSlide,
  },
  data() {
    return {
      tableauLike: [],
      valueRate: 1,
      SearchByVille: "",
      preloader: false,
      villes: "",
      prixMinimun: "",
      prixMaximun: "",
      chooseTypeLogement: "",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      value: "",
      ChooseVille: "",
      ChooseVilleId: "",
      listResidenceLove: [],
      listMaison: [],
      listTerrain: [],
      isWishlist: this.$store.state.myListFavoris,
      isWhished: [],
      ListBiensImmobilier: [],
      preloaderBien: false,
      responsiveOptions: [
        {
          breakpoint: "1400px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      listResidence: [],
      colorHeart: false,
      listDataFavoris: [],
    };
  },
  computed: {},
  methods: {
    getListDataFavoris(data) {
      let dataList = [];
      data.forEach((item) => {
        dataList.push(item.propriete);
      });
      return dataList;
    },
    listWhilist() {
      this.$store.dispatch("getListFavoris");
      //console.log("this.$store.state.myListFavoris",this.$store.state.myListFavoris)
      this.listDataFavoris = this.getListDataFavoris(
        this.$store.state.myListFavoris
      );
      //console.log("this.listDataFavoris",this.listDataFavoris)
    },
    async dislikePropriete(element) {
      //console.log("dislikeElement",element)
      try {
        const responseLike = await instance.put("favorites/" + element.id);
        //console.log("responseLike1",responseLike.data.status)
        if (responseLike.data.status === true) {
          let index = this.$store.state.myListFavoris.findIndex(
            (item) => item.property_id === element.id || item.id === element.id
          );
          this.$store.state.myListFavoris.splice(index, 1);
          this.$store.commit("LIST_FAVORIS", this.$store.state.myListFavoris);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async likePropriete(idDislike) {
      //console.log("idDislike",idDislike)
      //console.log("this.$store.state.myListFavoris",this.$store.state.myListFavoris)
      const { id } = idDislike;
      this.tableauLike.push(id);
      let data = {
        properties: this.tableauLike,
      };
      try {
        const responseLike = await instance.post("favorites", data);
        //console.log("responseLike2",responseLike)
        if (responseLike.data.status === true) {
          //console.log("idDislike",idDislike)
          this.$store.state.myListFavoris.push(idDislike);
          this.$store.commit("LIST_FAVORIS", this.$store.state.myListFavoris);
        }
      } catch (error) {
        console.log(error);
      }
    },
    renderHeartRed(Propriete) {
      //console.log("this.$store.state.myListFavoris",this.$store.state.myListFavoris)
      //console.log("Propriete",Propriete.id)
      if (!this.$store.state.token) {
        Swal.fire({
          icon: "error",
          title: "Veuillez-vous connecter",
          showConfirmButton: true,
        });
        this.$router.push({
          path: "/connexion",
          query: { redirect: this.$route.path },
        });
      } else {
        if (
          this.$store.state.myListFavoris.some(
            (item) =>
              Number(item.property_id) === Number(Propriete.id) ||
              item.id === Propriete.id
          )
        ) {
          this.dislikePropriete(Propriete);
        } else {
          this.likePropriete(Propriete);
        }
      }
    },

    async getlistResidence() {
      this.preloader = true;
      try {
        const responseResidence = await instance.get("getUnreserveResidences");
        const responseMaisons = await instance.get("getUnpayMaisons");
        const responseTerrain = await instance.get("getUnpayTerrains");
        this.listResidence = responseResidence.data.data.slice(0, 9);
        this.listMaison = responseMaisons.data.data.slice(0, 9);
        this.listTerrain = responseTerrain.data.data.slice(0, 9);
        //console.log("this.listResidence",this.listResidence)
        //console.log("this.listMaison",this.listMaison)
        //console.log(" this.listTerrain", this.listTerrain)
        this.preloader = false;
      } catch (error) {
        console.log(error);
        this.preloader = false;
      }
    },
  },
  created() {
    this.getlistResidence();
    this.listWhilist();
  },
};
</script>
<template>
  <main>
    <section>
      <div
        v-if="!preloader && listResidence.length"
        class="d-flex justify-content-between align-items-center container conteneur-bien"
      >
        <h3>Résidence meublées</h3>
        <button class="btn bg-voir-plus text-light">
          <router-link :to="{ name: 'locationResidence' }"
            >Voir plus</router-link
          >
        </button>
      </div>
      <div v-if="preloader">
        Chargement...
        <ProgressSpinner
          style="width: 25px; height: 25px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Custom ProgressSpinner"
        />
      </div>

      <div class="container" v-if="!preloader && listResidence.length">
        <carouselVue
          :value="listResidence"
          :numVisible="3"
          :numScroll="3"
          :responsiveOptions="responsiveOptions"
        >
          <template #item="slotProps">
            <div class="border-1 surface-border border-round m-1 p-1">
              <div class="mb-3">
                <div class="relative mx-auto">
                  <n-carousel show-arrow :show-dots="false">
                    <img
                      v-for="item in slotProps.data.photos"
                      :key="item.id"
                      class="carousel-img w-full border-round"
                      :src="
                        `https://backend.dexter-immo.com/public/public/image/` +
                        item.image
                      "
                      :alt="item.image"
                    />
                  </n-carousel>
                  <span
                    class="badge bg-success position-absolute"
                    style="top: 0.5em; left: 0.5em"
                  >
                    {{ slotProps.data.status }}
                  </span>
                  <span
                    @click.prevent="renderHeartRed(slotProps.data)"
                    class="position-absolute"
                    style="
                      background: white;
                      padding: 0 0.5em;
                      top: 0.5em;
                      right: 0.5em;
                      border-radius: 5px;
                      box-shadow: 1px 1px 1px 1px black;
                      cursor: pointer;
                    "
                  >
                    <i
                      class="bi bi-heart-fill"
                      style="font-size: 1.5em"
                      :class="
                        this.$store.state.myListFavoris.some(
                          (item) =>
                            item.property_id === slotProps.data.id ||
                            item.id === slotProps.data.id
                        )
                          ? 'color-heart'
                          : null
                      "
                    ></i>
                  </span>
                </div>
              </div>
              <div class="position-relative">
                <div class="mb-3 text-left fw-bold texte-coupe">
                  {{ slotProps.data.name }}
                </div>
                <div>
                  <span
                    class="position-relative top-0 right-0 d-flex align-items-center"
                    v-if="slotProps.data.moyenne > 0"
                  >
                    <RatingStar
                      :modelValue="slotProps.data.moyenne"
                      :cancel="false"
                      readonly
                      :stars="5"
                    />
                  </span>
                  <span
                    class="position-relative top-0 right-0 d-flex align-items-center"
                    v-else
                  >
                    <RatingStar :cancel="false" readonly :stars="5" />
                  </span>
                </div>
                <div class="text-left my-2 texte-coupe">
                  <i class="bi bi-geo-alt"></i
                  >{{ slotProps.data.municipality.city.name }},{{
                    slotProps.data.municipality.name
                  }}
                </div>

                <div class="flex justify-content-between align-items-center">
                  <h6 class="font-semibold">
                    {{ moneyFormat.format(slotProps.data.cost) }}XOF/jour
                  </h6>
                  <div>
                    <router-link
                      :to="{
                        name: 'details',
                        params: { id: slotProps.data.id },
                      }"
                      class="more-btn"
                      >Voir plus</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </carouselVue>
      </div>

      <div v-else-if="!preloader && !listMaison.length">
        <h2>Pas de résidences disponibles</h2>
      </div>
    </section>

    <section>
      <div
        v-if="!preloader"
        class="d-flex justify-content-between align-items-center container conteneur-bien"
      >
        <h3>Maisons</h3>

        <button v-if="listMaison.length" class="btn bg-voir-plus text-light">
          <router-link :to="{ name: 'locationResidence' }"
            >Voir plus</router-link
          >
        </button>
      </div>
      <div class="container" v-if="listMaison.length">
        <carouselVue
          :value="listMaison"
          :numVisible="3"
          :numScroll="3"
          :responsiveOptions="responsiveOptions"
        >
          <template #item="slotProps">
            <div class="border-1 surface-border border-round m-1 p-1">
              <div class="mb-3">
                <div class="relative mx-auto">
                  <n-carousel show-arrow :show-dots="false">
                    <img
                      v-for="item in slotProps.data.photos"
                      :key="item.id"
                      class="carousel-img w-full border-round"
                      :src="
                        `https://backend.dexter-immo.com/public/public/image/` +
                        item.image
                      "
                      :alt="item.image"
                    />
                  </n-carousel>
                  <span
                    class="badge bg-success position-absolute"
                    style="top: 0.5em; left: 0.5em"
                  >
                    {{ slotProps.data.status }}
                  </span>
                  <span
                    @click.prevent="renderHeartRed(slotProps.data)"
                    class="position-absolute"
                    style="
                      background: white;
                      padding: 0 0.5em;
                      top: 0.5em;
                      right: 0.5em;
                      border-radius: 5px;
                      box-shadow: 1px 1px 1px 1px black;
                      cursor: pointer;
                    "
                  >
                    <i
                      class="bi bi-heart-fill"
                      style="font-size: 1.5em"
                      :class="
                        this.$store.state.myListFavoris.some(
                          (item) =>
                            item.property_id === slotProps.data.id ||
                            item.id === slotProps.data.id
                        )
                          ? 'color-heart'
                          : null
                      "
                    ></i>
                  </span>
                </div>
              </div>
              <div class="position-relative">
                <div class="mb-3 text-left fw-bold texte-coupe">
                  {{ slotProps.data.name }}
                </div>
                <div>
                  <span
                    class="position-relative top-0 right-0 d-flex align-items-center"
                    v-if="slotProps.data.moyenne > 0"
                  >
                    <RatingStar
                      :modelValue="slotProps.data.moyenne"
                      :cancel="false"
                      readonly
                      :stars="5"
                    />
                  </span>
                  <span
                    class="position-relative top-0 right-0 d-flex align-items-center"
                    v-else
                  >
                    <RatingStar :cancel="false" readonly :stars="5" />
                  </span>
                </div>
                <div class="text-left">
                  <span class="prt-types rent">{{
                    slotProps.data.types.label
                  }}</span>
                </div>

                <div class="text-left my-2 texte-coupe">
                  <i class="bi bi-geo-alt"></i>
                  {{ slotProps.data.municipality.city.name }},{{
                    slotProps.data.municipality.name
                  }}
                </div>
                <!-- <span class="position-absolute top-0 right-0 d-flex align-items-center" 
                v-if="slotProps.data.moyenne > 0"
                >
                  <RatingStar 
                  :modelValue="slotProps.data.moyenne"
                  :cancel="false" 
                  readonly 
                  :stars="5"
                
                    /> <span class="mx-2">
                      ({{slotProps.data.notes.length}})</span>
                </span>
                <span class="position-absolute top-0 right-0 d-flex align-items-center" 
                v-else
                >
                  <RatingStar 
                  :cancel="false"
                   readonly 
                   :stars="5"
                    />
                </span> -->
                <div class="flex justify-content-between align-items-center">
                  <h6 class="font-semibold">
                    {{ moneyFormat.format(slotProps.data.cost) }} XOF
                    {{
                      slotProps.data.types.label === "Location" ? "/mois" : null
                    }}
                  </h6>
                  <div>
                    <router-link
                      :to="{
                        name: 'doVisite',
                        params: { id: slotProps.data.id },
                      }"
                      class="more-btn"
                      >Voir plus</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </carouselVue>
      </div>
      <div v-else-if="!preloader && !listMaison.length">
        <h2>Pas de maisons disponibles</h2>
      </div>
    </section>
    <section>
      <div
        v-if="!preloader"
        class="d-flex justify-content-between align-items-center container conteneur-bien"
      >
        <h3>Terrains</h3>

        <button v-if="listTerrain.length" class="btn bg-voir-plus text-light">
          <router-link :to="{ name: 'locationResidence' }"
            >Voir plus</router-link
          >
        </button>
      </div>
      <div class="container" v-if="listTerrain.length">
        <carouselVue
          :value="listTerrain"
          :numVisible="3"
          :numScroll="3"
          :responsiveOptions="responsiveOptions"
        >
          <template #item="slotProps">
            <div class="border-1 surface-border border-round m-1 p-1">
              <div class="mb-3">
                <div class="relative mx-auto">
                  <n-carousel show-arrow :show-dots="false">
                    <img
                      v-for="item in slotProps.data.photos"
                      :key="item.id"
                      class="carousel-img w-full border-round"
                      :src="
                        `https://backend.dexter-immo.com/public/public/image/` +
                        item.image
                      "
                      :alt="item.image"
                    />
                  </n-carousel>
                  <span
                    class="badge bg-success position-absolute"
                    style="top: 0.5em; left: 0.5em"
                  >
                    {{ slotProps.data.status }}
                  </span>
                  <span
                    @click.prevent="renderHeartRed(slotProps.data)"
                    class="position-absolute"
                    style="
                      background: white;
                      padding: 0 0.5em;
                      top: 0.5em;
                      right: 0.5em;
                      border-radius: 5px;
                      box-shadow: 1px 1px 1px 1px black;
                      cursor: pointer;
                    "
                  >
                    <i
                      class="bi bi-heart-fill"
                      style="font-size: 1.5em"
                      :class="
                        this.$store.state.myListFavoris.some(
                          (item) =>
                            item.property_id === slotProps.data.id ||
                            item.id === slotProps.data.id
                        )
                          ? 'color-heart'
                          : null
                      "
                    ></i>
                  </span>
                </div>
              </div>
              <div class="position-relative">
                <div class="mb-3 text-left fw-bold texte-coupe">
                  {{ slotProps.data.name }}
                </div>
                <div class="text-left">
                  <span class="prt-types rent">{{
                    slotProps.data.types.label
                  }}</span>
                </div>

                <div class="text-left my-2 texte-coupe">
                  <i class="bi bi-geo-alt"></i
                  >{{ slotProps.data.municipality.city.name }},{{
                    slotProps.data.municipality.name
                  }}
                </div>
                <!-- <span class="position-absolute top-0 right-0 d-flex align-items-center" 
                v-if="slotProps.data.moyenne > 0"
                >
                  <RatingStar 
                  :modelValue="slotProps.data.moyenne"
                  :cancel="false" 
                  readonly 
                  :stars="5"
                
                    /> <span class="mx-2">
                      ({{slotProps.data.notes.length}})</span>
                </span>
                <span class="position-absolute top-0 right-0 d-flex align-items-center" 
                v-else
                >
                  <RatingStar 
                  :cancel="false"
                   readonly 
                   :stars="5"
                    />
                </span> -->
                <div class="flex justify-content-between align-items-center">
                  <h6 class="font-semibold">
                    {{ moneyFormat.format(slotProps.data.cost) }} XOF
                  </h6>
                  <div>
                    <router-link
                      :to="{
                        name: 'doVisite',
                        params: { id: slotProps.data.id },
                      }"
                      class="more-btn"
                      >Voir plus</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </carouselVue>
      </div>
      <div v-else-if="!preloader && !listTerrain.length">
        <h2>Pas de terrains disponibles</h2>
      </div>
    </section>

    <section class="bg-light">
      <div class="container">
        <div class="row justify-content-center position-relative">
          <div class="col-lg-7 col-md-10 text-center">
            <div class="sec-heading center">
              <h1 class="fw-bold pt-5">Téléchargement</h1>
              <p>
                Pour Télécharger l'application mobile,scannez le Qr code
                <br />
                ou cliquer sur l'image Google Play.
              </p>
              <div class="mt-5">
                <div class="my-5">
                  <div>
                    <a
                      href="https://apps.apple.com/ci/app/dexter-immo/id6499447562?l=en-GB"
                    >
                      <img
                        src="../assets/apple.webp"
                        style="width: 150px; margin-left: 1em"
                        alt="lien_app_play_store"
                      />
                    </a>
                  </div>

                  <n-qr-code
                    :size="150"
                    id="qr-code"
                    value="https://apps.apple.com/ci/app/dexter-immo/id6499447562?l=en-GB"
                  />
                </div>
                <div class="my-5">
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.invest.dexter.bundleId"
                    >
                      <img
                        src="../assets/google.webp"
                        style="width: 150px; margin-left: 1em"
                        alt="lien_google_play_store"
                      />
                    </a>
                  </div>
                  <n-qr-code
                    :size="150"
                    id="qr-code-2"
                    value="https://play.google.com/store/apps/details?id=com.invest.dexter.bundleId"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <a id="back2Top" class="top-scroll" title="Back to top" href="#"
      ><em class="ti-arrow-up"></em
    ></a>
  </main>
</template>
<style scoped>
.conteneur-bien {
  padding: 0 2em !important;
}
.bg-voir-plus {
  background: transparent;
  padding: 1em;
  font-weight: bold;
}
.bg-voir-plus a {
  color: #febf02 !important;
}

.color-heart {
  color: crimson !important;
}
.carousel-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.prt-view {
  background-color: crimson !important;
  color: white !important;
}
.text-secondary {
  font-weight: bold !important;
}
.btn-theme-light-2 {
  background-color: black !important;
  color: white !important;
}
.btn-theme-light-2:hover {
  background-color: black !important;
  color: white !important;
}
.search-btn {
  background-color: black !important;
  color: white !important;
}
.fa-star {
  color: gray;
}
.pre {
  padding: 0 0 15em 0;
}
.bg-danger {
  background-color: crimson !important;
  color: white !important;
}

.pi-heart-fill {
  position: absolute;
  top: 0.5em;
  left: 1em;
  z-index: 99;
  cursor: pointer;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 10px;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.31);
}
.position_price_residence {
  position: absolute;
  right: 0;
}
.superficie {
  position: absolute;
  left: 0;
  width: auto !important;
  background-color: crimson !important;
  color: white !important;
}
.residence_lien {
  text-decoration: none !important;
}
.commodites {
  position: absolute;
  bottom: 5em;
}
.bi-arrow-right {
  font-size: 1.5em !important;
}
.listing-card-info-price {
  font-size: 1em;
}
.form-control {
  padding: 0 3em !important;
}
.more-btn {
  width: auto;
  height: auto;
  padding: 0.3em;
  margin: -1em 0;
  color: white !important;
  background: black;
  border-radius: 5px;
}
</style>
