<script>
// import Vue3BasicAutocomplete from 'vue3-basic-autocomplete'

import axios from "axios";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
const baseUrl = "http://192.168.1.3:8080/api/";
export default {
  name: "BienImmobilierVenteView",
  //   components: {
  //   Vue3BasicAutocomplete,
  // },
  data() {
    return {
      activeResidence: false,
      preloader:false,
      showDetailsBienImmobilier:false,
      columns: [
        {
          label: "Propriété",
          field: "libelle",
        },
        {
          label: "Image",
          field: "image",
          width: "100px",
        },
        {
          label: "Ville",
          field: "city",
          type: "number",
        },
        {
          label: "Tarif",
          field: "tarif",
        },
        {
          label: "Type de logement",
          field: "logement",
          type: "string",
        },
        {
          label: "Type de propriété",
          field: "TypePropriete",
          type: "string",
        },
        {
          label: "Mode d'acquisition",
          field: "acquisition",
          type: "string",
        },
        {
          label: "Approuver",
          field: "accepte",
          type: "string",
        },
        // {
        //   label: 'Details',
        //   field: 'details',
        // },
      ],
      rows: [
        {
          libelle: "Bel appartement",
          acquisition: "Vente",
          city: "Abidjan",
          tarif: "3.000.000 XOF",
          logement: "Maison",
          TypePropriete: "villa basse",
          statut: "En attente d'approbation",
        },
      ],
      ToggleShowRebootInformationResidence: false,
      showConfirmationForModify: false,
      listBienEnAttenteFournisseur: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      SeedetailsResidence:{},
      gallery:[],
    };
  },
  methods: {
    Toogle() {
      this.ToggleShowRebootInformationResidence =
        !this.ToggleShowRebootInformationResidence;
    },
    DeleteResidence() {
      this.showConfirmationForModify = !this.showConfirmationForModify;
    },
    ToogleResidence(e) {
      console.log(this.activeResidence);
      let data = {
        validation: 1,
      };
      let IdResidence = Number(e.target.value);
      this.listBienEnAttenteFournisseur.forEach((item) => {
        if (item.id === IdResidence) {
          item.validation = 1;
        }
      });
      axios
        .post(baseUrl + "updatevalid/" + IdResidence, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "true") {
            this.$router.push("/liste_bien_immobilier_en_vente");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // ToggleResidenceNotActive(e){
    //   let data = {
    //     validation:0
    //   }
    //   let IdResidence = Number(e.target.value)
    // this.listBienEnAttenteFournisseur.forEach(item=>{
    //   if(item.id === IdResidence){
    //     item.validation = 1
    //   }
    // })
    //  axios.post(baseUrl+"updatevalid/"+IdResidence,data,{
    //   headers: {
    //       Authorization: "Bearer " + this.$store.state.token,
    //     },
    //  })
    //  .then(response =>{
    //   console.log(response);

    //  })
    //  .catch(err=>{
    //   console.log(err);
    //  });
    // },
    ShowListReservationFournisseur() {
      this.preloader = true
      axios
        .get(baseUrl + "reservationFA", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST BIEN EN ATTENTE", response.data);
          this.listBienEnAttenteFournisseur = response.data.data;
          this.preloader = false
          console.log(
            "LIST BIEN EN ATTENTE",
            this.listBienEnAttenteFournisseur
          );
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    seeDetailBienImmobilier(id) {
      this.listBienEnAttenteFournisseur.forEach((item) => {
        if (item.id === id) {
          this.SeedetailsResidence = item.immobilier;
          console.log(this.SeedetailsResidence);
          // this.SeedetailsTypePropriete = item.type;
          // this.SeedetailsVille = item.ville;
          let address = "http://192.168.1.3:8080/public/image/";
          this.SeedetailsResidence.photos.forEach((item) => {
            if (item) {
              this.gallery.push(address + item.path);
              console.log(this.gallery);
            }
          });
          // console.log(this.SeedetailsProprietaire);
          // console.log(this.SeedetailsTypePropriete);
          // console.log(this.detailsVille);
        }
      });
      this.showDetailsBienImmobilier = !this.showDetailsBienImmobilier;
    },
    CloseDetailBienImmobilier() {
      this.showDetailsBienImmobilier = !this.showDetailsBienImmobilier;
      this.gallery = []
    },
  },
  created() {
    this.ShowListReservationFournisseur();
  },
};
</script>
<template>
  <div class="col-lg-10 col-md-12">
    <div class="dashboard-wraper">



      <div class="conteneur_modify" v-show="showDetailsBienImmobilier">
        <div class="content position-relative">
          <div
            class="ecriteau text-start d-flex justify-content-between align-items-center"
          >
            <h4>Détails Bien Immobilier</h4>
            <em
              class="bi bi-x-circle h1"
              @click.prevent="CloseDetailBienImmobilier"
            ></em>
          </div>
          <div class="submit-pages text-start">
            <div
              class="infoResidence d-flex justify-content-between align-items-center mb-5"
            >
              <div>
                <h3>{{ SeedetailsResidence.libelle }}</h3>
                <span
                  >Type logement : {{ SeedetailsResidence.type_logement }}</span
                >
              </div>
              <img class="w-25" :src="gallery[0]" alt="villa" />
            </div>
            <div class="boxTotal pb-5">
              <h5 class="badge bg-primary">Détails propriété</h5>
              <h5>
                <strong>Date</strong> :
                {{ new Date(SeedetailsResidence.created_at).toLocaleDateString('fr') }}
              </h5>
              <h5>
                <strong>Propriété</strong> : {{ SeedetailsResidence.libelle }}
              </h5>
              <h5 v-if="SeedetailsResidence.ville">
                <strong>Ville</strong> : {{ SeedetailsResidence.ville.libelle }}
              </h5>
              
              <h5>
                <strong>Tarif</strong> :
                {{ moneyFormat.format(SeedetailsResidence.tarif) }} XOF
              </h5>
              <h5>
                <strong>Superficie</strong> :
                {{ SeedetailsResidence.surperficie }} m<sup>2</sup>
              </h5>
              <h5>
                <strong>ilot</strong> : {{ SeedetailsResidence.ilot }} 
              </h5>
              <!-- <h5 v-if="SeedetailsResidence.reduction != null">
                <strong>Réduction (en %)</strong> :
                {{ SeedetailsResidence.reduction }}
              </h5>
              <h5 v-if="SeedetailsResidence.c_reduction != null">
                <strong>Condition de réduction</strong> :
                {{ SeedetailsResidence.c_reduction }}
              </h5>
              <h5>
                <strong>Nombre de résidents acceptés</strong> :
                {{ SeedetailsResidence.nbre_personne }} personnes
              </h5> -->
              <!-- <h5 class="badge bg-secondary">Lieu</h5>
              <h5><strong>Ville</strong> : {{ detailsVille.libelle }}</h5>
              <h5>
                <strong>Quartier</strong> : {{ SeedetailsResidence.quartier }}
              </h5>
              <h5>
                <strong>Autre précision</strong> :
                {{ SeedetailsResidence.precission }}
              </h5>
              <h5>
                <strong class="d-block">Description</strong>
                {{ SeedetailsResidence.description }}
              </h5>
              <h5 class="badge bg-danger">Equipements</h5>
              <div class="p-3">
                <h5
                  v-for="(item, index) in SeedetailsResidence.commodite"
                  :key="index"
                >
                  <strong>-{{ item.libelle }}</strong>
                </h5> 
              </div> -->

              <h5 class="badge bg-danger">Galerie</h5>
              <div>
                <MazGallery :images="gallery" :height="400" />
              </div>
            </div>
          </div>
        </div>
      </div>










      <!-- Bookmark Property -->
      <div class="form-submit text-start" :class="preloader ? 'pre':'null'">
        <div id="preloader position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h4>Terrain et Maison en attente</h4>
      </div>

      <div class="col-sm-12" v-if="preloader == false">
        <table id="MyTableData" class="table text-center">
          <thead>
            <tr class="text-center">
              <th class="bg-light">Propriété</th>
              <th class="bg-light">Image</th>
              <th class="bg-light">Ville</th>
              <th class="bg-light">Tarif</th>
              <th class="bg-light">Type de logement</th>
              <th class="bg-light">Mode d'acquisition</th>
              <th class="bg-light">Status</th>
              <th class="bg-light">Approbation</th>
              <th class="bg-light">Détail</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in listBienEnAttenteFournisseur"
              :key="index"
            >
              <td>{{ item.immobilier.libelle }}</td>
              <td>
                <n-image
                  width="50"
                  v-if="
                    item.immobilier.photos.length || item.immobilier.photos[0]
                  "
                  :src="
                    'http://192.168.1.3:8080/public/image/' +
                    item.immobilier.photos[0].path
                  "
                  alt="photo"
                />

                <span v-else>Pas d'image</span>
              </td>
              <td v-if="item.immobilier.ville">
                {{ item.immobilier.ville.libelle }}
              </td>
              <td>
                {{ moneyFormat.format(item.immobilier.tarif) }} XOF
                <span v-if="item.immobilier.periode">
                  / {{ item.immobilier.periode }}</span
                >
              </td>

              <td>
                {{ item.immobilier.type_logement }}
              </td>
              <td>
                {{ item.immobilier.sell }}
              </td>
              <td>
                <span v-if="item.validation === 1" class="badge bg-success"
                  >Approuveé</span
                >
                <span
                  v-if="item.validation === 0 || item.validation === null"
                  class="badge bg-danger"
                >
                  En attente</span
                >
              </td>
              <td>
                <input
                  type="checkbox"
                  :checked="item.validation === 0 ? false : 'null'"
                  :value="item.id"
                  v-if="item.validation === 0 || item.validation === null"
                  @input="ToogleResidence"
                />
              </td>
              <td>
                <em
               class="bi bi-eye"
               @click.prevent="seeDetailBienImmobilier(item.id)"
          ></em>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              placeholder: 'Recherche',
            }"
            :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: 10,
                dropdownAllowAll: false,
                rowsPerPageLabel: 'Rangée par page',
              }">
              <template #table-row="props">
                <span v-if="props.column.label =='Image'">
                   <img class="w-100" src="./assets/villa.jpg" alt="villa">
                </span>
            <div class="d-flex justify-content-between align-items-center" v-if="props.column.label =='Details'">
                <em class="bi bi-pencil" @click.prevent="Toogle"></em>
                <em class="bi bi-eye" @click.prevent="seeDetailTerrain"></em>
                <em class="bi bi-trash3" @click.prevent="DeleteResidence"></em>
            </div>
            <div class="d-flex justify-content-center align-items-center" v-if="props.column.label =='Approuver'">
                <KCheckbox v-model="activeResidence" :required="true" /> <span class="badge text-bg-primary" v-if="!activeResidence">En attente</span>
                <span class="badge text-bg-success" v-if="activeResidence">Accepter</span>
              </div>
             </template>
             
          </vue-good-table> -->
    </div>
  </div>
</template>
<style scoped>
@import url("@kong/kongponents/dist/style.css");
.table {
  border: 1px solid black;
}
.pre{
  padding:0 0 15em 0;
}
td {
  border-right: 2px solid black;
}
.container[data-v-51562f51] {
  width: 100% !important;
  padding: 0 !important;
}
.confirmation_modifie {
  position: absolute;
  z-index: 99;
  background-color: white;
  padding: 2em;
  border: 3px solid black;
  border-radius: 1em;
  margin-left: 50%;
  transform: translateX(-75%);
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 60%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index: 999;
}
.submit-pages {
  padding: 1em 3em;
}
</style>
