import axios from "axios";
export const lienImage = "https://backend.dexter-immo.com/public/public/image/"
const instance = axios.create({
  baseURL: 'https://backend.dexter-immo.com/api/',
});


instance.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default instance;
