<script>
// import axios from "axios";
import Swal from "sweetalert2";
import instance from '../api/api'
import UploadMedias from "vue-upload-drop-medias"
export default {
    name:"createResidenceView",
    components:{
    UploadMedias
  },
data() {
    return {
      localisation_gps:"",
      dataObject: "",
      responseCreateImage:"",
      libelleTypeOperation: "",
      label: "",
      convenience: [],
      listCommune: [],
      filterVilleForCommune:[],
      listVille: [],
      preloader: false,
      listCommodite: [],
      categorieChosen: "",
      ListProprietaire: [],
      appartenanceImmobiliere: "fournisseur",
      docuPropriete: "",
      personneMaxi: "",
      categorieProduct: "",
      descriptionPropriete: "",
      Listcategorie: [],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      imagePropriete: [],
      labelPropriete: "",
      typePropriete: "",
      costPropriete: "",
      documentPropriete: "",
      roomPropriete: "",
      cautionPropriete: "",
      lotPropriete: "",
      areaPropriete: "",
      ilotPropriete: "",
      addressPropriete: "",
      municipality_idPropriete: "",
      ville_idPropriete:"",
      category_idPropriete: "",
      proprietairePropriete: "",
      comoditesPropriete: "",
      TypeOperationImmobiliere: "",
      transactionImmobiliere: "",
      listTypeOperation: [],
      listCommoditeChoosen:[],
      tableauCommoditeChoosen:"",
      listCommoditeGetOut:[],
      fields: [
        {
          convenience_id: "",
          number: "",
        },
      ],
    };
  },
    computed:{
    },
    methods:{
      pushElementInTableau(tableau) {
      let tableauFieldsFilter = [];
      tableau.forEach((item) => {
        if (item.convenience_id.id && item.number) {
          tableauFieldsFilter.push({
            comodity_id: item.convenience_id.id,
            number: item.number,
          });
        }
      });
      return tableauFieldsFilter;
    },
    resetData() {
      this.libelleCommodite = "";
      this.labelPropriete = "";
      this.costPropriete = "";
      this.documentPropriete = "";
      this.roomPropriete = "";
      this.transactionImmobiliere = "";
      this.typePropriete = "";
      this.cautionPropriete = "";
      this.descriptionPropriete = "";
      this.lotPropriete = "";
      this.imagePropriete = [];
      this.areaPropriete = "";
      this.ilotPropriete = "";
      this.personneMaxi = "";
      this.addressPropriete = "";
      this.municipality_idPropriete = "";
      this.categorieProduct = "";
      this.TypeOperationImmobiliere = "";
      this.fields = [
        {
          convenience_id: "",
          number: "",
        },
      ];
    
    },
   
      async saveProduct() {
      this.preloader = true;
      let tableauCommodite = this.pushElementInTableau(this.fields);

      this.TypeOperationImmobiliere = this.categorieChosen == "Résidence" ? 3 : this.TypeOperationImmobiliere;

      let data = new FormData();
      tableauCommodite.forEach((commodite) => {
        data.append("comodites[]", JSON.stringify(commodite));
      });
      
      //console.log("this.appartenanceImmobiliere",this.appartenanceImmobiliere)

      data.append("type_id", this.TypeOperationImmobiliere);
      data.append("name", this.labelPropriete);
      data.append("type", this.typePropriete);
      data.append("cost", this.costPropriete);
      data.append("document", this.documentPropriete);
      data.append("room", this.roomPropriete);
      data.append("caution", this.cautionPropriete);
       data.append("localisation_gps",this.localisation_gps)
      data.append("description", this.descriptionPropriete);
      data.append("lot", this.lotPropriete);
      data.append("area", this.areaPropriete);
      data.append("ilot", this.ilotPropriete);
      data.append("adress", this.addressPropriete);
      data.append("person_maxi", this.personneMaxi);
      data.append("municipality_id", this.municipality_idPropriete.id);
      data.append("category_id", this.categorieProduct);
      data.append("user_id", this.$store.state.user.id);
      data.append("registered_by", this.appartenanceImmobiliere);
      //console.log("data",data)
      try {
        this.preloader = true;

        const responseCreateReal = await instance.post("proprietes",data);

          //console.log("responseCreateReal",responseCreateReal.data)
          
        if (responseCreateReal.data.status === true) {
          try {
            let propriety_id = responseCreateReal.data.data.id;
            let dataForCreateImage = new FormData();
            this.imagePropriete.forEach((item) => {
              dataForCreateImage.append("image[]", item);
            });
            dataForCreateImage.append("propriety_id", propriety_id);
            const responseCreateImage = await instance.post("photos",dataForCreateImage);
           

            if (responseCreateImage.data.status === true) {
              Swal.fire({
                icon: "success",
                title: "Enregistré avec succès.",
                showConfirmButton: false,
                timer: 1500,
              });
       
              this.resetData();
            }
          } catch (error) {
            this.preloader = false;
          }
          this.preloader = false;
        }
      } catch (error) {
        this.preloader = false;
      }
    },
        addField: function () {
      //console.log(this.listCommodite);
      this.fields.push({ convenience_id: "", number: "" });
      //console.log(this.fields);
      this.listCommoditeGetOut = this.listCommodite.filter(item=>item.label !== this.tableauCommoditeChoosen)
      if(this.listCommoditeGetOut.length){
        this.listCommodite = this.listCommoditeGetOut
        // //console.log("this.listCommodite",this.listCommodite)
      }
      // //console.log("listCommodite",this.listCommoditeGetOut)
    },
    deleteField: function (element) {
      // //console.log("element",element.convenience_id.label)
      const DeleteField = this.fields.findIndex((item) => item === element);
      this.fields.splice(DeleteField, 1);
      if(element.convenience_id.label){
        this.listCommodite.push(element.convenience_id.label)
      }else{
        return;
      }
      
    },
        seeCategorieChoosen(value) {
     
     this.Listcategorie.forEach((item) => {
       if (item.id == value.target.value) this.categorieChosen = item.label;
     });
     this.ilotPropriete = "";
     this.lotPropriete = "";
     this.typePropriete = "";
     this.roomPropriete = "";
     this.areaPropriete = "";
     this.municipality_idPropriete = "";
     this.personneMaxi = "";
   },
        async getList(){
            try{
              const responseCategorie = await instance.get('categories')
              const responseComodites = await instance.get('comodites')
              const responseMunicipalite = await instance.get('municipalite')
              const responseCity = await instance.get('cities')
              const responseTypeOperation = await instance.get('types')
               //console.log("responseCity",responseCity)
              this.listTypeOperation =responseTypeOperation.data.data 
              this.listCommodite = responseComodites.data.data
              this.listCommune =responseMunicipalite.data.data  
              this.listVille =responseCity.data.data 
              this.Listcategorie = responseCategorie.data.data
            }catch(error){
                console.log(error);
                        }
        },
        handleMedias(files) {
      this.imagePropriete = files;
    },
    getValue(e){

      this.tableauCommoditeChoosen = e.label
   
    },
    getVille(e){

      this.municipality_idPropriete = ""
      this.filterVilleForCommune = this.listCommune.filter(item=>item.city_id === Number(e.id))
    },
       
    },
    mounted(){
        this.getList();
    },
}
</script>
<template>
    <div class="col-lg-10 col-md-12">
							
        <div class="dashboard-wraper">
            
            <div class="row text-start">
                
               
                <div class="col-lg-12 col-md-12">
                    <form @submit.prevent="saveProduct">
                      <div class="submit-pages_view text-start">
                        <div class="form-submit">
                          <h2 class="text-dark">Enregistrer une propriété</h2>
                          <div class="submit-section">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label
                                  >Catégorie de la propriéte
                                  <span class="text-danger">*</span></label
                                >
          
                                <select
                                  v-model="categorieProduct"
                                  class="form-control"
                                  @change="seeCategorieChoosen"
                                >
                                  <option disabled value="">
                                    Sélectionne une catégorie
                                  </option>
                                  <option
                                    v-for="(categorie, index) in Listcategorie"
                                    :key="index"
                                    :value="categorie.id"
                                    :id="categorie.label"
                                  >
                                    {{ categorie.label }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group col-md-6" v-if="categorieChosen">
                                <label
                                  >Nom  {{ categorieChosen == 'Terrain' ? "du terrain": categorieChosen == 'Maison' ? "de la maison":"de la résidence"  }} <span class="text-danger">*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="labelPropriete"
                                  required
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'terrain' ||
                                  categorieChosen == 'Terrain'
                                "
                              >
                                <label>Ilot <span class="text-danger">*</span></label>
          
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ilotPropriete"
                                  required
                                />
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'terrain' ||
                                  categorieChosen == 'Terrain'
                                "
                              >
                                <label
                                  >Numéro de lot <span class="text-danger">*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="lotPropriete"
                                  required
                                />
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'Résidence' ||
                                  categorieChosen == 'Maison'
                                "
                              >
                                <label
                                  >Type de Propriéte
                                  <span class="text-danger">*</span></label
                                >
          
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="typePropriete"
                                  placeholder="villa,duplex etc..."
                                  required
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'Maison' ||
                                  categorieChosen == 'Terrain'
                                "
                              >
                                <label
                                  >Type de l'operation
                                  <span class="text-danger">*</span></label
                                >
                                <select
                                  v-model="TypeOperationImmobiliere"
                                  class="form-control"
                                  @change="chooseTypeOperation"
                                >
                                  <option disabled value="">Veuillez sélectionner</option>
                                  <option
                                    v-for="item in listTypeOperation"
                                    :key="item"
                                    :value="item.id"
                                  >
                                    {{ item.label }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group col-md-6" v-if="categorieChosen">
                                <label>Prix <span class="text-danger">*</span></label>
                                <VueNumberFormat class="form-control"
                                v-model:value="costPropriete"
                                :options="{
                                  precision: 0,
                                  nullValue: false,
                                  prefix: '',
                                  decimal: ',',
                                  thousand: '.',
                                  acceptNegative: false,
                                  isInteger: false,
                                }"
                               
                                ></VueNumberFormat>
                              
                                
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'Maison' &&
                                  libelleTypeOperation == 'Location'
                                "
                              >
                                <label>Caution (Nombre de mois)</label>
          
                                <input
                                  type="number"
                                  min="1"
                                  class="form-control"
                                  v-model="cautionPropriete"
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'Maison' ||
                                  categorieChosen == 'Résidence'
                                "
                              >
                                <label
                                  >Nombre de pièces
                                  <span class="text-danger">*</span></label
                                >
          
                                <input
                                  type="number"
                                  min="1"
                                  class="form-control"
                                  v-model="roomPropriete"
                                  required
                                />
                              </div>
          
                              <div
                                class="form-group col-md-6"
                                v-if="
                                  categorieChosen == 'Maison' ||
                                  categorieChosen == 'Terrain'
                                "
                              >
                                <label>Superficie</label>
          
                                <input
                                  type="number"
                                  min="1"
                                  class="form-control"
                                  v-model="areaPropriete"
                                />
                              </div>
                              <div class="form-group col-md-6" v-if="categorieChosen">
                                <label>Ville <span class="text-danger">*</span></label>
          
                                <v-select
                                  placeholder="Sélectionner une ville"
                                  v-model="ville_idPropriete"
                                  :searchable="true"
                                  :options="listVille"
                                  label="name"
                                  @option:selected="getVille" 
                                >
                                  <template>
                                    <p>{{ label }}</p>
                                  </template>
                                </v-select>
                              </div>
                              <div class="form-group col-md-6" v-if="categorieChosen">
                                <label>
                                  {{this.ville_idPropriete.name == 'Abidjan' ? 'Commune':'Quartier'}} 
                                  <span class="text-danger">*</span></label>
          
                                <v-select
                                  :placeholder="this.ville_idPropriete.name == 'Abidjan' ? 'Sélectionner une commune':'Sélectionner un quartier'"
                                  v-model="municipality_idPropriete"
                                  :searchable="true"
                                  :options="filterVilleForCommune"
                                  label="name"
                                >
                                  <template>
                                    <p>{{ label }}</p>
                                  </template>
                                </v-select>
                              </div>
                              <div class="form-group col-md-6" v-if="categorieChosen">
                                <label>Situé précisément</label>
          
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="addressPropriete"
                                 
                                />
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="categorieChosen == 'Résidence'"
                              >
                                <label
                                  >Nombre de personne autorisées
                                  <span class="text-danger">*</span></label
                                >
          
                                <input
                                  type="number"
                                  class="form-control"
                                  min="1"
                                  v-model="personneMaxi"
                                  required
                                />
                              </div>
                              <div class="form-group col-md-12" v-if="categorieChosen">
                                <label>Description</label>
                                <textarea
                                  class="form-control"
                                  required
                                  v-model="descriptionPropriete"
                                  name=""
                                  id=""
                                  cols="30"
                                  rows="10"
                                >
                                </textarea>
                              </div>
          
                              <div
                                class="form-group col-md-12"
                                v-if="
                                  categorieChosen == 'Résidence' ||
                                  categorieChosen == 'Maison'
                                "
                              >
                                <div
                                  v-for="field in fields"
                                  :key="field"
                                  class="position-relative row"
                                >
                                <div class="w-100"  style="text-align:right;">
                                    <span
                                    v-if="field"
                                    class="delete badge bg-danger fw-bold"
                                    @click="deleteField(field)"
                                    >x</span
                                  >
                                </div>
                                 
                                  <div class="col-lg-6">
                                    <label :for="field">Commodité</label>
                                    <v-select
                                      v-model="field.convenience_id"
                                      :searchable="true"
                                      placeholder="Sélectionner une commodité"
                                      :options="listCommodite"
                                      label="label"
                                      autocomplete="true"
                                     @option:selected="getValue"
                                    >
                                      <template>
                                        <p>{{ label }}</p>
                                      </template>
                                    </v-select>
                                  </div>
                                  <div class="col-lg-6">
                                    <label :for="field">Nombre de la commodité</label>
                                    <input
                                      :id="field"
                                      type="number"
                                      min="1"
                                      class="form-control"
                                      v-model="field.number"
                                    />
                                  </div>
                                </div>
                                <div class="text-end">
                                  <button
                                    @click.prevent="addField"
                                    class="btn bg-primary  my-3 addField bg-dark text-light"
                                  >
                                    Ajouter une commodité +
                                  </button>
                                </div>
                              </div>
                              <div
                                class="form-group col-md-6"
                                v-if="categorieChosen == 'Terrain'"
                              >
                                <label>Document <span class="text-danger">*</span></label>
                                <div class="row container">
                                  <section class="col-md-12 circuit">
                                    <div class="col-md-12 d-flex align-items-center my-2">
                                      <input
                                        type="radio"
                                        id="ACD"
                                        name="fav_language"
                                        value="ACD"
                                        required
                                        v-model="documentPropriete"
                                      />
                                      <label for="ACD">ACD</label>
                                    </div>
          
                                    <div class="col-md-12 d-flex align-items-center my-2">
                                      <input
                                        type="radio"
                                        id="Approbation Ministeriel"
                                        name="fav_language"
                                        value="ACD en cours"
                                        required
                                        v-model="documentPropriete"
                                      />
                                      <label for="Approbation Ministeriel"
                                        >ACD en cours</label
                                      >
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <!-- <div class="form-group col-md-6" v-if="categorieChosen">
                                <label
                                  >Appartenance <span class="text-danger">*</span></label
                                >
          
                                <select
                                  v-model="appartenanceImmobiliere"
                                  class="form-control"
                                >
                                  <option disabled value="">Veuillez sélectionner</option>
                                  <option value="administrateur">Dexter</option>
                                  <option value="fournisseur">Fournisseur</option>
                                </select>
                              </div> -->
          
                              <!-- <div
                                class="form-group col-md-6"
                                v-if="appartenanceImmobiliere == 'fournisseur'"
                              >
                                <label
                                  >Proprietaire <span class="text-danger">*</span></label
                                >
                                <v-select
                                  v-model="proprietairePropriete"
                                  :searchable="true"
                                  :options="ListProprietaire"
                                  label="firstname"
                                >
                                  <template #option="{ firstname, lastname }">
                                    <p>{{ lastname }} {{ firstname }}</p>
                                  </template>
                                </v-select>
                              </div> -->
                              <div
                                class="form-group col-md-12 text-start"
                                v-if="categorieChosen"
                              >
                                <label
                                  >Télécharger des images
                                  <span class="text-danger">*</span></label
                                >
          
                                <UploadMedias 
                                  uploadMsg="glisser et déposer vos images"
                                  @changed="handleMedias"
                                />
                              </div>
                              <div class="col-lg-6 mt-2" v-if="categorieChosen">
                                <label class="fw-bold">
                                  Lien de la localisation 
                                  
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="localisation_gps"
                                />
                              </div>
          
                              <div class="text-start mt-3">
                                <button
                                  v-if="categorieChosen"
                                  class="btn bg-dark text-light bg-dark rounded"
                               
                                >
                                  Enregistrer
                                  <span
                                    class="spinner-border spinner-border-sm ms-2"
                                    v-show="preloader"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                
            </div>
            
        </div>
    </div>
</template>
<style scoped>
[type="radio"]:checked,[type="radio"]:not(:checked){
    position:relative;
    left:0;
}
[type="radio"]:checked + label:after{
    opacity:0 !important;
}
.btn-theme-light-2{
    background-color: black !important;
    color:white !important;
}
.container[data-v-51562f51]{
    width:100% !important;
    padding:0 !important;
}
.addField{
    width:auto;
}
.delete{
    right:0;
    padding:1em;
    width:auto;
}

</style>
