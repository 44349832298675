<script>
import Swal from "sweetalert2";
import axios from "axios";
const baseUrl = "http://192.168.1.3:8080/api/";
export default {
   data(){
    return{
     activeAccount:false,
    }
   },
   methods: {
    getActivateAccount(){
        let data= {
            email : this.$route.params.email,
            remember_token : this.$route.params.token
        } 
         axios.post(baseUrl + 'activecompte',data)
              .then((resp) =>{
                console.log(resp)
                  if(resp.data.status === true){
                      Swal.fire({
                            position:'center',
                            icon: 'success',
                            title: 'Le compte à été activé',
                           showConfirmButton: false,
                           timer: 3000
                               })
                               this.activeAccount = true
                        setTimeout(() =>{
                             this.$router.push('/')
                        },3000)
                  }
                  if(resp.data.status === false){
                      Swal.fire({
                            position:'center',
                            icon: 'success',
                            title: resp.data.message,
                           showConfirmButton: false,
                           timer: 3000
                               })
                  }
              })
              .catch((err) =>{
                console.log(err)
              })
    },
   },
   created(){
    this.getActivateAccount()
   }
}
</script>
<template>
   <div class="py-5" v-if="activeAccount == true">
    <h5>Félicitation votre compte vient d'être activé.</h5>
   </div>
   <div class="py-5" v-else>
    <h5>Désole votre compte n'est pas encore activé.</h5>
   </div>
</template>
