<script>
import instance, { lienImage } from "../api/api";
import Swal from "sweetalert2";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Vue3BasicAutocomplete from "vue3-basic-autocomplete";

export default {
  name: "LocationResidence",
  components: {
    Vue3BasicAutocomplete,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      allVilles:[],
      chooseCommuneLabel: "",
      lienImage: lienImage,
      currentPage: 1,
      isLoadingSearch: false,
      rows: 5,
      valueSlider: [15000, 30000],
      SearchByVille: "",
      nbrPiece: "",
      prixMinimun: "",
      preloader: false,
      prixMaximun: "",
      result: "",
      listResidence: [],
      chooseTypeLogement: "",
      moneyFormat: new Intl.NumberFormat("de-DE"),
      communes: [],
      perPage: 3,
      totalPages: "",
      maxVisibleButtons: 3,
      chooseCommune: null,
      isWhished: [],
      tableauLike:[],
      listResidenceLove: [],
      listSearchResidence: [],
      isLoading: false,
      equipements: [],
      equipementChoosen: [],
      chooseVilleName:"",
      chooseVille:{},
      isWishlist:this.$store.state.myListFavoris,
      listCommuneFilter:[]
    };
  },
  methods: {
    onPageChange(event) {
      //console.log("EVENT", event);
      this.currentPage = event.page + 1;
    },
    async dislikePropriete(element){
      //console.log("dd")
      try{
       const responseLike = await instance.put("favorites/" + element.id);
       //console.log("responseLike",responseLike)
       if(responseLike.data.status === true){
         let index = this.$store.state.myListFavoris.findIndex(item=>item.property_id === element.id || item.id === element.id);
       this.$store.state.myListFavoris.splice(index,1);
       this.$store.commit("LIST_FAVORIS",this.$store.state.myListFavoris) 
       }
      }catch(error){
       console.log(error)
      }
     },
     async likePropriete(idDislike){
       const {id}=idDislike
       //console.log("idDislike",idDislike)
       this.tableauLike.push(id)
       let data = {
         properties:this.tableauLike
       }
       try{
       const responseLike = await instance.post("favorites",data);
       //console.log("responseLike",responseLike)
       if(responseLike.data.status === true){
         this.$store.state.myListFavoris.push(idDislike)
       this.$store.commit("LIST_FAVORIS",this.$store.state.myListFavoris)
       }
      }catch(error){
       console.log(error)
      }
       
     },
     renderHeartRed(Propriete) {
     
     if(!this.$store.state.token){
 Swal.fire({
           icon: "error",
           title: "Veuillez-vous connecter",
           showConfirmButton: true,
         });
         this.$router.push({
             path: "/connexion",
             query: { redirect: this.$route.path }
           })
     }else{
       if(this.$store.state.myListFavoris.some(item=>item.property_id === Propriete.id || item.id === Propriete.id)){
        this.dislikePropriete(Propriete)
     }else{
       
       this.likePropriete(Propriete)
     }
     }
     
   },
   resetField(){
    this.chooseVille = "";
    this.chooseVilleName = "";
    this.nbrPiece = "";
    this.equipementChoosen = [];
    this.listResidence = this.listSearchResidence;
      this.prixMinimun=""
      this.prixMaximun=""
      this.equipementChoosen=[]
      this.nbrPiece=""
      this.chooseCommuneLabel=""
      this.chooseCommune={}
   },
    rechercheByCommune(commune) {
      let filterCommune = [];
      filterCommune = this.listSearchResidence.filter((item) => {
        return item.municipality.name === commune;
      });
      return filterCommune;
    },
    rechercheByVilleAndCommune(ville,commune){
      let filterCommune = [];
      filterCommune = this.listSearchResidence.filter((item) => {
        return item.municipality.name === commune && item.municipality.city.name.includes(ville) ;
      });
      return filterCommune;
    },
    rechercheByCommuneAndPiece(commune, piece) {
      let filterCommune = [];
      filterCommune = this.listSearchResidence.filter((item) => {
        return (
          item.municipality.name.includes(commune) && item.room.includes(piece)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneAndPrixMin(commune, prixMin) {
      let filterCommune = [];
      filterCommune = this.listSearchResidence.filter((item) => {
        return (
          item.municipality.name.includes(commune) &&
          Number(item.cost) >= Number(prixMin)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneAndPrixMax(commune, prixMax) {
      let filterCommune = [];
      filterCommune = this.listSearchResidence.filter((item) => {
        return (
          item.municipality.name.includes(commune) &&
          Number(prixMax) >= Number(item.cost)
        );
      });
      return filterCommune;
    },
    rechercheByCommuneWithCommodite(commune, equipements) {
      let filterCommune = [];
      if (commune && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listSearchResidence.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              item.municipality.name.includes(commune)
            ) {
              fitreCommodite.push(item);
              filterCommune = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterCommune);
            }
          });
        });
      }
      return filterCommune;
    },
    rechercheByNombrePiece(piece) {
      let filterCommune = [];
      filterCommune = this.listSearchResidence.filter((item) => {
        return item.room.includes(piece);
      });
      return filterCommune;
    },
    rechercheByNbrePieceAndPrixMin(piece, prixMin) {
      let filterPiece = [];
      filterPiece = this.listSearchResidence.filter((item) => {
        return (
          item.room.includes(piece) && Number(item.cost) >= Number(prixMin)
        );
      });
      return filterPiece;
    },
    rechercheByNbrePieceAndPrixMax(piece, prixMax) {
      let filterPiece = [];
      filterPiece = this.listSearchResidence.filter((item) => {
        return (
          item.room.includes(piece) && Number(prixMax) >= Number(item.cost)
        );
      });
      return filterPiece;
    },
    rechercheByPieceWithCommodite(piece, equipements) {
      let filterPiece = [];
      if (piece && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listSearchResidence.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              item.room.includes(piece)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByPrixMin(prixMin) {
      let filterPrixMin = [];
      filterPrixMin = this.listSearchResidence.filter((item) => {
        return Number(item.cost) >= Number(prixMin);
      });
      return filterPrixMin;
    },
    rechercheByPrixMinAndPrixMax(prixMin, prixMax) {
      let filterPrixMin = [];
      filterPrixMin = this.listSearchResidence.filter((item) => {
        return (
          Number(item.cost) >= Number(prixMin) &&
          Number(prixMax) >= Number(item.cost)
        );
      });
      return filterPrixMin;
    },
    rechercheByPrixMinWithCommodite(prixMin, equipements) {
      let filterPiece = [];
      if (prixMin && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listSearchResidence.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              Number(item.cost) >= Number(prixMin)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByPrixMaxWithCommodite(prixMax, equipements) {
      let filterPiece = [];
      if (prixMax && equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listSearchResidence.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              Number(prixMax) >= Number(item.cost)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByPrixMaxAndPrixMinWithCommodite(prixMin, prixMax, equipements) {
      let filterPiece = [];
      if (prixMax && equipements && prixMin) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listSearchResidence.filter((item) => {
            if (
              item.comodites.some((como) => como.label === el) &&
              Number(prixMax) >= Number(item.cost) &&
              Number(item.cost) >= Number(prixMin)
            ) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByCommodite(equipements) {
      let filterPiece = [];
      if (equipements) {
        //console.log("COMMODITE", equipements);
        let fitreCommodite = [];
        equipements.forEach((el) => {
          this.listSearchResidence.filter((item) => {
            if (item.comodites.some((como) => como.label === el)) {
              fitreCommodite.push(item);
              filterPiece = [...new Set(fitreCommodite)];
              //console.log("filtreFind", filterPiece);
            }
          });
        });
      }
      return filterPiece;
    },
    rechercheByVille(ville){
      let filterVille = [];
      filterVille = this.listSearchResidence.filter((item) => {
        return item.municipality.city.name.includes(ville);
      });
      return filterVille;
    },
    launchSearch() {
      let resultFilter = [];
      this.isLoading = true;
      this.isLoadingSearch = true;
      if (this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommune(this.chooseCommuneLabel);
        this.listResidence = resultFilter;
        
      }
      if(this.chooseVilleName){
        resultFilter = this.rechercheByVille(this.chooseVilleName);
        this.listResidence = resultFilter;  
        
      }
      if(this.chooseVilleName && this.chooseCommuneLabel){
        resultFilter = this.rechercheByVilleAndCommune(this.chooseVilleName,this.chooseCommuneLabel);
        this.listResidence = resultFilter; 
         
      }
      if (this.nbrPiece && this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommuneAndPiece(
          this.chooseCommuneLabel,
          this.nbrPiece   
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }

      if (this.prixMinimun && this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommuneAndPrixMin(
          this.chooseCommuneLabel,
          this.prixMinimun 
          
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.prixMaximun && this.chooseCommuneLabel) {
        resultFilter = this.rechercheByCommuneAndPrixMax(
          this.chooseCommuneLabel,
          this.prixMaximun
          
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.chooseCommuneLabel && this.equipementChoosen.length) {
        resultFilter = this.rechercheByCommuneWithCommodite(
          this.chooseCommuneLabel,
          this.equipementChoosen 
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.nbrPiece) {
        resultFilter = this.rechercheByNombrePiece(this.nbrPiece);
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.nbrPiece && this.prixMinimun) {
        resultFilter = this.rechercheByNbrePieceAndPrixMin(
          this.nbrPiece,
          this.prixMinimun
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.nbrPiece && this.prixMaximun) {
        resultFilter = this.rechercheByNbrePieceAndPrixMax(
          this.nbrPiece,
          this.prixMaximun
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.nbrPiece && this.equipementChoosen.length) {
        resultFilter = this.rechercheByPieceWithCommodite(
          this.nbrPiece,
          this.equipementChoosen
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.prixMinimun) {
        resultFilter = this.rechercheByPrixMin(this.prixMinimun);
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.prixMinimun && this.prixMaximun) {
        resultFilter = this.rechercheByPrixMinAndPrixMax(
          this.prixMinimun,
          this.prixMaximun
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.prixMinimun && this.equipementChoosen.length) {
        resultFilter = this.rechercheByPrixMinWithCommodite(
          this.prixMinimun,
          this.equipementChoosen
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.prixMaximun && this.equipementChoosen.length) {
        resultFilter = this.rechercheByPrixMaxWithCommodite(
          this.prixMaximun,
          this.equipementChoosen
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (
        this.prixMinimun &&
        this.prixMinimun &&
        this.equipementChoosen.length
      ) {
        resultFilter = this.rechercheByPrixMaxAndPrixMinWithCommodite(
          this.prixMinimun,
          this.prixMaximun,
          this.equipementChoosen
        );
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      if (this.equipementChoosen.length) {
        resultFilter = this.rechercheByCommodite(this.equipementChoosen);
        this.listResidence = resultFilter;
        this.isLoading = false;
        
      }
      setTimeout(()=>{
        this.isLoadingSearch = false;
      },1500)
      // this.listResidence = [];
    },
    filtrerResidence() {
      this.isLoadingSearch = true;
      if(this.prixMaximun && this.prixMinimun>this.prixMaximun){
        Swal.fire({
          icon: "error",
          title: "Le prix maximal doit être supérieur au prix minimal",
          showConfirmButton: true,
        });
        this.isLoading = false;
      }else{
        this.launchSearch();
        this.isLoading = false;
      }
      setTimeout(()=>{
        this.isLoadingSearch = false;
      },1500)
     
      
      // try {
      //   // let data = {
      //   //   type: this.typeFilter,
      //   //   document: this.docFilter,
      //   //   room: this.nbrPiece,
      //   //   area: this.superficieFilter,
      //   //   municipality_id: this.chooseCommuneLabel,
      //   //   category_id: this.categoryFilter,
      //   //   user_id: this.$store.state.user.id,
      //   //   person_maxi: this.personneFilter,
      //   //   minPrix: this.prixMinimun,
      //   //   maxPrix: this.prixMaximun,
      //   // };
      //   this.launchSearch();
      //   this.isLoadingSearch = false;
      //   this.isLoading = false;
      //   // document.getElementById("filter_search").style.display = "none";
      // } catch (error) {
      //   console.log(error);
      //   this.isLoadingSearch = false;
      //   this.isLoading = false;
      // }
    },
    resetAllResidence() {
      this.listResidence = this.listSearchResidence;
      this.prixMinimun=""
      this.prixMaximun=""
      this.equipementChoosen=[]
      this.nbrPiece=""
      this.chooseCommuneLabel=""
      this.chooseCommune={}

    },
    //   RateResidence(id) {
    //     let data = {
    //       immobilier_id:id
    //     }

    //     if(this.isWhished.includes(id)){
    //       const DeleteIdLike = this.isWhished.findIndex(p=>p === id)
    //     this.isWhished.splice(DeleteIdLike,1)
    //     //console.log("DELETE",this.isWhished);
    //     }else{
    //       this.isWhished.push(id)
    //       //console.log("ADD",this.isWhished);
    //     }
    //     instance.post( "whilist",data,{
    //       headers: { Authorization: "Bearer " + this.$store.state.token },
    //     })
    //     .then(response=>{
    //       //console.log("heartResidence",response);
    //       if(response.data.status === 'false'){
    //         this.$router.push({
    //             path: "/connexion",
    //           })
    //       }
    //     })
    //     .catch(error=>{
    //       console.log(error);

    //     })
    //   },
    chooseEquipement(e) {
      console.log(e.target.value);
      //console.log("this.equipementChoosen", this.equipementChoosen);
    },
    chooseMyCommune(e) {
      console.log("commune", e.value);
      this.chooseCommuneLabel = this.chooseCommune.name;
      //console.log("this.chooseCommuneLabel", this.chooseCommuneLabel);
    },
    chooseMyVille(){
      this.chooseVilleName = this.chooseVille.name;
      //console.log("this.chooseVille",this.chooseVille)
      //console.log("this.listCommunesNotFilter",this.communes)
     this.listCommuneFilter = this.communes.filter(item=>item.city.name === this.chooseVille.name)
      //console.log("this.listCommuneFilter",this.listCommuneFilter)
      //console.log("this.chooseVilleName", this.chooseVilleName);
      //console.log("this.chooseCommuneLabel", this.chooseVilleName);
    },
    getlistResidence() {
      this.isLoading = true;
      instance
        .get("getUnreserveResidences")
        .then((response) => {
          //console.log("getUnreserveResidences", response.data.data);
          this.listResidence = response.data.data;
          this.listSearchResidence = response.data.data;

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    async getEquipement() {
      try {
        const responseEquipement = await instance.get("comodites");
        const responseCommune = await instance.get("municipalite");
        const responseVille = await instance.get("cities")
        //console.log("responseEquipement", responseEquipement.data.data);
        //console.log("responseCommune", responseCommune.data.data);
        this.equipements = responseEquipement.data.data;
        this.communes = responseCommune.data.data;
        this.allVilles = responseVille.data.data
      } catch (error) {
        console.log(error);
      }
    },
    listWhilist(){
      this.$store.dispatch('getListFavoris')
      //console.log("this.$store.state.myListFavoris",this.$store.state.myListFavoris)
    },
  },
  computed: {
    paginatedResidences() {
      const startIndex = (this.currentPage - 1) * this.rows;
      //console.log("startIndex", startIndex);
      const endIndex = startIndex + this.rows;
      //console.log("endIndex", endIndex);
      return this.listResidence.slice(startIndex, endIndex);
    },
  },
  created() {
    this.getlistResidence();
    this.getEquipement();
    this.listWhilist();
  },
};
</script>
<template>
  <div>
    <div class="page-title">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h2 class="ipt-title">Résidences meublées</h2>
          </div>
        </div>
      </div>
    </div>
    <section class="gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="filter_search_opt">
              <a href="javascript:void(0);" onclick="openFilterSearch()"
                >Recherche<i class="ml-2 ti-menu"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div
              class="simple-sidebar sm-sidebar"
              id="filter_search"
              style="left: 0"
            >
              <div class="search-sidebar_header"
              onclick="closeFilterSearch()"
              >
                <h4 class="ssh_heading">Fermer le filtre</h4>
                <button
                  
                  class="w3-bar-item w3-button w3-large"
                >
                  <i class="ti-close"></i>
                </button>
              </div>

              <div class="sidebar-widgets">

                <label class="d-block text-start">Ville</label>
                <div class="form-group simple">
                  <vue3-basic-autocomplete
                    v-model="chooseVille"
                    :options="allVilles"
                    trackby="name"
                    none-find=" désole , Pas de resultat"
                    placeholder="Recherche par ville"
                    input-class="form-control"
                    @change.prevent="chooseMyVille"
                  />
                </div>
                <label class="d-block text-start">Commune</label>
                <div class="form-group simple">
                  <vue3-basic-autocomplete
                    v-model="chooseCommune"
              
                    :options=" this.listCommuneFilter.length ? this.listCommuneFilter:this.communes"
                    trackby="name"
                    none-find=" désole , Pas de resultat"
                    placeholder="Recherche par commune"
                    input-class="form-control"
                    @change.prevent="chooseMyCommune"
                  />
                </div>

                <div class="sidebar-widgets">
                  <div class="form-group simple">
                    <div class="input-with-icon">
                      <label class="d-block text-start">Nombre de pièces</label>
                      <input
                        type="number"
                        class="form-control"
                        min="1"
                        placeholder="Nombre de pièces"
                        v-model="nbrPiece"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="simple-input">
                          <label class="d-block text-start">Prix Min</label>
                          <VueNumberFormat
                            class="form-control"
                            v-model:value="prixMinimun"
                            :options="{
                              precision: 0,
                              nullValue: false,
                              prefix: '',
                              suffix: ' XOF',
                              decimal: ',',
                              thousand: '.',
                              acceptNegative: false,
                              isInteger: false,
                            }"
                          ></VueNumberFormat>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="simple-input">
                          <label class="d-block text-start">Prix Maxi</label>
                          <VueNumberFormat
                            class="form-control"
                            v-model:value="prixMaximun"
                            :options="{
                              precision: 0,
                              nullValue: false,
                              prefix: '',
                              suffix: ' XOF',
                              decimal: ',',
                              thousand: '.',
                              acceptNegative: false,
                              isInteger: false,
                            }"
                          ></VueNumberFormat>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                  <p class="text-danger" v-if="prixMaximun && prixMinimun>prixMaximun">
                    Le prix maximal doit être supérieur au prix minimal</p>

                  <div class="ameneties-features">
                    <div class="form-group" id="module">
                      <a
                        role="button"
                        class=""
                        data-bs-toggle="collapse"
                        href="#advance-search"
                        aria-expanded="true"
                        aria-controls="advance-search"
                      ></a>
                    </div>
                    <div
                      class="collapse"
                      id="advance-search"
                      aria-expanded="false"
                      role="banner"
                    >
                      <ul class="no-ul-list">
                        <li
                          v-for="(item, index) in this.equipements"
                          :key="index"
                          class="my-4"
                        >
                          <input
                            :id="item.label"
                            class="checkbox-custom"
                            name="a-1"
                            type="checkbox"
                            :value="item.label"
                            @change="chooseEquipement"
                            v-model="equipementChoosen"
                          />
                          <label
                            :for="item.label"
                            class="checkbox-custom-label fw-bold"
                            >{{ item.label }}</label
                          >
                        </li>
                      </ul>
                    </div>

                    <button
                      class="btn btn btn-theme-light-2 rounded full-width filter-desktop"
                      @click="filtrerResidence"
                    >
                    <span v-if="isLoading">
                      <ProgressSpinner
                      style="width: 25px; height: 25px"
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                      aria-label="Custom ProgressSpinner"
                    />
                    </span>
                    <span v-else>Filtre</span>
                    
                    </button>
                    <button 
                    @click.prevent="resetField"
                    class="btn btn 
                    my-3
                    fw-bold
                    bg-danger rounded full-width 
                    ">Rédemarrer</button>
                    <button
                    onclick="closeFilterSearch()"
                     class="btn btn btn-theme-light-2 rounded full-width 
                     filter-mobile"
                     @click.prevent="filtrerResidence"
                   >
                     Filtre
                   </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-12 list-layout position-relative">
            <div class="row justify-content-center">
              <div class="col-lg-12 col-md-12">
                <div class="item-shorting-box">
                  <div class="item-shorting clearfix">
                    <div class="preloader" v-if="isLoadingSearch">
                      Chargement...
                      <ProgressSpinner
                        style="width: 25px; height: 25px"
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                        aria-label="Custom ProgressSpinner"
                      />
                    </div>
                    <div
                      class="left-column pull-left text-start d-flex justify-content-between align-items-center"
                    >
                      <h4 class="m-0">
                        {{ this.listResidence.length }} résultat{{this.listResidence.length > 1 ? '(s)':null}}
                      </h4>
                      <button
                        class="bg-dark text-light btn rounded"
                        @click.prevent="resetAllResidence"
                      >
                        Toutes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row text-start">
              <div
                class="col-lg-12 col-md-12"
                v-for="(residence, index) in paginatedResidences"
                :key="index"
              >
              
                <div class="property-listing property-1 position-relative">
                 
                  <div class="listing-img-wrapper">
                    <vueper-slides
                      :bullets="false"
                      fixed-height="200px"
                      v-if="residence.photos.length"
                    >
                      <vueper-slide
                        v-for="(slide, i) in residence.photos"
                        :key="i"
                        :image="lienImage + slide.image"
                      >
                      </vueper-slide>
                    </vueper-slides>
                    <div v-else>
                      <img
                        class="w-100 img-fluid"
                        src="../assets/house.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="listing-content">
                    <span
                    @click.prevent="renderHeartRed(residence)"
                    class="position-absolute"
                    style="
                      background: white;
                      padding: 0 0.5em;
                      top: 0.5em;
                      right: 0.5em;
                      border-radius: 5px;
                      box-shadow: 1px 1px 1px 1px black;
                      z-index:50;
                      cursor: pointer;"
                  >
                    <i
                      class="bi bi-heart-fill"
                      style="font-size: 1.5em"
                      :class="this.$store.state.myListFavoris.some(el=>el.property_id === residence.id || el.id === residence.id ) ? 'color-heart' : null"
                    ></i>
                  </span>
                    <div class="listing-detail-wrapper-box">
                      <div class="listing-detail-wrapper">
                        <div class="listing-short-detail">
                          <h4 class="listing-name">
                            <h5>{{ residence.name }}</h5>
                          </h4>
                          <div class="fr-can-rating d-flex align-items-center">
                                
                                
                            <RatingStar v-if="residence.moyenne"
                             :modelValue="residence.moyenne"  
                            :readonly="true" 
                            :stars="5" 
                            :cancel="false" />
                            <RatingStar v-else  
                            :readonly="true" 
                            :stars="5" 
                            :cancel="false" />
                            
                          </div>
                          <span class="prt-types rent">{{
                            residence.type
                          }}</span>
                          <span
                            class="d-block mt-3 badge bg-danger w-25 superficie"
                            >{{ residence.room }} pièce</span
                          >
                        </div>
                        <div class="list-price">
                          <h6 class="listing-card-info-price text-dark">
                            {{ moneyFormat.format(residence.cost) }} XOF
                           
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="listing-footer-wrapper">
                      <div class="listing-locate">
                        <span class="listing-location"
                          ><em class="ti-location-pin">
                            </em
                          >{{ residence.municipality.city
.name}},{{ residence.municipality.name}}
</span
                        >
                      </div>
                      <div class="listing-detail-btn">
                        <router-link
                          :to="{
                            name: 'details',
                            params: { id: residence.id },
                          }"
                          class="more-btn"
                          >Voir plus</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="listResidence.length">
                <PaginatorVue
                  v-model="currentPage"
                  :rows="rows"
                  :totalRecords="listResidence.length"
                  @page="onPageChange"
                ></PaginatorVue>
              </div>
            </div>
            <div v-if="isLoading">
              <h1>Chargement...</h1>
            </div>
            <div v-else-if="!isLoading && !listResidence.length && !isLoadingSearch">
              <h1>Pas de résidences.</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.color-heart {
  color: crimson !important;
}
.superficie {
  background-color: orange !important;
}
.btn-theme-light-2,
.btn-theme-light-2:hover {
  background-color: black !important;
    color:white !important;
}
.bg-danger {
  background-color: orange !important;
}
.pi-heart-fill {
  position: absolute;
  top: 0.5em;
  left: 1em;
  z-index: 99;
  cursor: pointer;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 10px;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.31);
}
.page {
  width: 30px;
  height: 30px;
}
.prev {
  background: crimson;
  color: white !important;
  font-weight: bold !important;
}
.pagination_btn {
  border: 1px solid white;
}
.color {
  background: crimson;
  color: white !important;
  font-weight: bold !important;
}
.next-prev {
  border: none;
  color: crimson;
  font-weight: bold !important;
}
.listing-locate {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#preloader {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(106, 106, 106, 0.205);
  z-index: 80;
}
</style>
