<script>
import Swal from "sweetalert2";
import instance,{lienImage} from "../api/api";

export default {
    name:'profilView',
    data() {
        return {
            showConfirmationForModify:false,
            ToggleShowRebootInformationPersonnelle:false,
            user:this.$store.state.user,
            oldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
      lienImage:lienImage,
      filesPhoto:[],
      toogleProfil:[],
      ProfilUser:[],
      isLoadingModify:false,
        }
    },
    methods: {
      ProfilName() {
      instance
        .get("roles")
        .then((response) => {
          //console.log("response",response)
          response.data.data.forEach(item=>{
        if(item.label === 'Client' || item.label === "Propriétaire"){
          this.ProfilUser.push(item)
          //console.log("PROFILE", this.ProfilUser)
        }
      })
      this.toogleProfil = this.user.roles
        })
        .catch((err) => {
          console.log(err);
        });
    },
      putValueInArray(tableau){
     const tableauBoucle = []
     tableau.forEach(item=>{
      tableauBoucle.push(item.id)
     })
     return tableauBoucle;
    },
        async modifyProfil() {
      if(this.ConfirmPassword != this.NewPassword){
        Swal.fire({
            icon: "error",
            title: "Confirmation de mot de passe incorrect.",
            showConfirmButton: true,
            
          });
      }else{ 
        if(!this.toogleProfil.length){
          Swal.fire({
            icon: "info",
            title: "Veuillez choisir au moins un profil.",
            showConfirmButton: true,
          });
        }else{
          this.isLoadingModify = true;
        const profils = this.putValueInArray(this.toogleProfil)
        let dataForm = new FormData();
        dataForm.append("email",this.user.email)
        dataForm.append("password",this.NewPassword)
        dataForm.append("phone",this.user.phone)
        this.filesPhoto.forEach(item=>{
          dataForm.append("cni_doc[]",item)
        })
        profils.forEach((role) => {
          dataForm.append("roles[]", role);
        });
        //console.log("this.user.email",this.user.email)
        let dataWithoutForm = {
  "email": this.user.email,
  "oldPassword": this.oldPassword,
  "password": this.NewPassword
}
     let data = this.NewPassword ? dataWithoutForm : dataForm;
        
        const isPasswordExsit = this.NewPassword ? "modifyPassword":`updateusers/+${this.$store.state.user.id}`; 
        //console.log("isPasswordExsit",isPasswordExsit)
      await instance.post(isPasswordExsit,data)
      .then(response=>{
        console.log(response);
        if(response.data.status === true){
          Swal.fire({
            icon: "success",
            title: "Profile modifié avec succès.",
            showConfirmButton: true,
          });
          this.$store.dispatch('ModifyUser',response.data.user)
          this.isLoadingModify = false;
        }
        if(response.data.status === false){
          Swal.fire({
            icon: "error",
            title: response.data.message,
            showConfirmButton: true,
            
          });
          this.isLoadingModify = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.isLoadingModify = false;
      })
      }
    }
    },
    updatePhoto(e){
     this.filesPhoto.push(e.target.files[0])
    }

        
    },
    created(){
this.ProfilName()
    }
}
</script>
<template>
   
    <div class="col-lg-10 col-md-12">
       
        <div class="dashboard-wraper">
            <div class="confirmation_modifie" v-show="showConfirmationForModify">
                <h6>Voulez-vous changer les informations ?</h6>
                <button class="btn bg-danger mx-2 text-white">Retour</button>
                <button class="btn bg-primary mx-2 text-white">Modifier</button>
               </div>
           
        
            
            <div class="form-submit">	
                <div class="d-flex justify-content-start align-items-center">
                    <h4 class="text-start">Informations personnelles</h4>
                <i class="bi bi-pencil mx-4" @click.prevent="ToggleShowRebootInformationPersonnelle = !ToggleShowRebootInformationPersonnelle"></i>
                </div>
                <div class="submit-section">
                    <div class="row text-start">
                    
                        <div class="form-group col-md-6">
                            <label>Nom</label>
                            <input type="text" class="form-control" 
                            v-model="user.lastname" disabled>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Prénoms</label>
                            <input type="text" class="form-control"
                             v-model="user.firstname" disabled>
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label>Email</label>
                            <input type="email" 
                            class="form-control" 
                            v-model="user.email">
                        </div>
                        
                      
                        
                        <div class="form-group col-md-6">
                            <label>Téléphone</label>
                            <input type="text" 
                            class="form-control"
                             v-model="user.phone">
                        </div>
                        <div class="form-group col-md-6">
                          <label>Profil</label>
                          <v-select
                          v-model="toogleProfil"
                          :searchable="true"
                          :options="ProfilUser"
                          multiple
                          label="label"
                        >
                          <template>
                            <p>{{ label }}</p>
                          </template>
                        </v-select>
                          <!-- <MultiSelect v-model="toogleProfil" 
                      :options="ProfilUser" 
                      optionLabel="label" 
                      placeholder="Choisir un profil"
                      class="w-full p-2 text-left"
                      /> -->
                      </div>
                        

                        <div class="form-group col-md-6">
                          <label class="d-block">Pièce d'identité</label>
                          <n-image
                          width="100"
                          :src="lienImage+JSON.parse(user.cni_doc)[0]"
                        />
                        <div class="mt-3">

                          <input type="file" @change="updatePhoto">
                        </div>
                      </div>
                      <div class="form-group col-lg-12 col-md-12">
                        <button
                          class="btn btn-theme-light-2 rounded"
                          @click.prevent="modifyProfil"
                        >
                        <span v-if="!isLoadingModify">
                          Modifier 
                        </span>
                        <span v-else>
                          <ProgressSpinner  
                          style="width: 25px; height: 25px" 
                         strokeWidth="8" 
                         fill="var(--surface-ground)"
                         animationDuration=".5s" 
                         aria-label="Custom ProgressSpinner" />
                        </span>
                        </button>
                      </div>
        
                    </div>
                </div>
            </div>
            <div class="form-submit text-start">
                <h4 class="fw-bold">Changer mon mot de passe</h4>
                <div class="submit-section">
                  <div class="row">
                    <div class="form-group col-lg-12 col-md-6">
                      <label>Ancien mot de passe</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="oldPassword"
                      />
                    </div>
      
                    <div class="form-group col-md-6">
                      <label>Nouveau mot de passe</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="NewPassword"
                      />
                    </div>
      
                    <div class="form-group col-md-6">
                      <label>Confirmer mot de passe</label>
                      <input
                        type="password"
                        class="form-control"
                        v-model="ConfirmPassword"
                      />
                    </div>
      
                    <div class="form-group col-lg-12 col-md-12">
                      <button
                        class="btn btn-theme-light-2 rounded"
                        @click.prevent="modifyProfil"
                      >
                       Modifier
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            
        </div>
    </div>
</template>
<style scoped>
.btn-theme-light-2{
  background-color: black !important;
  color:white !important;
}
.ecriteau{
    position:sticky;
    top:0;
    left:0;
    width:100%;
    padding:1em;
    background-color:white;
    box-shadow:1px 1px 1px 2px rgba(0, 0, 0, 0.129);
    z-index:999;
    border-radius: 1em;
  }
.confirmation_modifie{
    position: absolute;
    z-index: 9999;
    background-color:white;
    padding:2em;
    border:3px solid black;
    border-radius: 1em;
}
.conteneur_modify{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:999;
    background-color:transparent;
    display:flex;
    justify-content: center;
    place-items: center;
    
}
.conteneur_modify .content {
    width:60%;
    height:auto;
    background: white;
    border-radius: 1em;
    box-shadow: 1px 0 1px 3px rgba(0, 0, 0, 0.128);
}
.close_modal{
    position:absolute;
    top:1em;
    right:1em;
}
</style>