<script>
import instance,{lienImage} from "../../api/api";
export default {
  name: "detailReservation",

  data() {
    return {
      isSpinner:false,
      listResidenceReservee: [],
      detailReservation:null,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      lienImage:lienImage
    };
  },
  computed:{

  },
  methods: {
    listDetailsPropriete() {
      this.isSpinner = true;
      instance
        .get("reservations/" + this.$route.params.id)
        .then((res) => {
          this.detailReservation = res.data.data;
          //console.log("this.detailReservation", this.detailReservation);
          this.isSpinner = false;
        })
        .catch((err) => {
          console.log(err);
          this.isSpinner = false;
        });
    },
  },
  created() {
    this.listDetailsPropriete()
  },
};
</script>
<template>
  <div class="col-lg-10 col-md-12">
    <div v-if="isSpinner" class="text-center">
      Chargement... <ProgressSpinner  
      style="width: 25px; height: 25px" 
      strokeWidth="8" 
      fill="var(--surface-ground)"
      animationDuration=".5s" 
      aria-label="Custom ProgressSpinner" />
    </div>
    <div class="dashboard-wraper" v-else>
      <div class="form-submit text-start">
        
        <h4>Détail De La réservation</h4>
      </div>
      <div v-if="detailReservation" class="text-left">
      <span class="bagde p-1 text-light fw-bold" 
      :class="detailReservation.paid !== 'Impayer'?'bg-success':'bg-danger'"
      >{{detailReservation.paid}}</span>
      <h5>Réserver le:{{new Date(detailReservation.created_at).toLocaleDateString('fr')}}</h5>
        <h5>Bien:{{detailReservation.propriety.name}}</h5>
        <h5>Lieu:{{detailReservation.propriety.adress}}</h5>
        <h5>Cout:{{moneyFormat.format(detailReservation.cost)}} XOF</h5>
        <h5>Date d'arrivée:{{new Date(detailReservation.date_start).toLocaleDateString('fr')}}</h5>
        <h5>Date de fin:{{new Date(detailReservation.date_end).toLocaleDateString('fr')}}</h5>
        <h5>Code:{{ detailReservation.propriety.code }}</h5>
        <div class="w-50 h-100">
          <n-carousel show-arrow>
            <img
            v-for="item in detailReservation.propriety.photos"
              :key="item.id"
              class="carousel-img"
              :src="lienImage+item.image"
            >
           
          </n-carousel>

        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.carousel-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.dashboard-stat.widget-1 {
  background-color: white;
  box-shadow: 1px 1px 1.5px 1.5px black;
  color: black !important;
}
.dashboard-stat-content h4 {
  color: black !important;
}
.dashboard-stat-content {
  left: 0 !important;
  padding: 1em;
}

.alert-success {
  left: 0 !important;
  top: 0.5em !important;
}
.alert-date {
  right: 0 !important;
  top: 0.5em !important;
}
.btn-voir {
  right: 0 !important;
  bottom: 0 !important;
}
</style>
