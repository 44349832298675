<script>
export default {
  name: "HeaderCompoment",
  data() {
    return {
        user:this.$store.state.user,
        toggleDashboard:false,
        arrayProfil:[],
        showMenuMobile:false,
    };
  },
  methods: {
    Deconnexion(){
        this.$store.dispatch('DeconnecterUser')
        this.$store.state.user = "";
        this.toggleDashboard = false;
        
    },
    toogleDahboard(){
      this.$router.push('/tableau_de_bord')
      this.toggleDashboard = !this.toggleDashboard;
    },
    showListFavoris(){
      this.$router.push('/favoris')
      this.toggleDashboard = !this.toggleDashboard;
    },
    showNotification(){
      this.$router.push('/notifications')
      this.toggleDashboard = !this.toggleDashboard;
    },
    toogleFavoris(){
      this.$router.push('/favoris')
      this.toggleDashboard = !this.toggleDashboard;
    },
    toogleCompte(){
      this.$router.push('/espace_client')
      this.toggleDashboard = !this.toggleDashboard;
    },
    toggleMenuMobile(){
      this.showMenuMobile = !this.showMenuMobile
    }

  },
};
</script>
<template>
  <header>
    
    
    <section class="menu_mobile"  v-show="showMenuMobile">
      
        <div class="position-absolute fermer_menu_mobile" @click.prevent="toggleMenuMobile">

        </div>
     
        <div class="position-absolute menu">
          <ul class="nav-menu nav-menu-social align-to-right profile_mobile">
        
            <li class="add-listing" v-if="!this.$store.state.user">
              <router-link :to="{ name: 'login' }">
                Se connecter</router-link
              >
            </li>

            <li v-if="this.$store.state.user" class="position-relative">
              <!-- <i class="bi bi-bell-fill"></i> -->
              <div class="btn-group account-drop" @click.prevent="toggleDashboard = !toggleDashboard">
                <button
                  type="button"
                  class="btn btn-order-by-filt dropdown-toggle"
                  id="showbutton"
                  
                >
                
                  <img
                    src="/assets/img/user-5.jpg"
                    class="avater-img"
                    alt="avatar"
                  />{{this.$store.state.user.lastname}}
                </button>
              </div>
              <div class="see-dashboard text-start" v-show="toggleDashboard">
                <h6 @click="toogleDahboard">
                 <span>Tableau de bord</span> </h6>
                    <h6 
                  @click="showNotification"
                  >
                    <span
                    >Notifications </span>
                     <span class="badge bg-danger"
                     v-if="this.$store.state.listNotification.length > 0"
                     >{{this.$store.state.listNotification.length}}</span>
                   </h6>
                  <h6 
                  v-if="this.$store.state.user ||
                  (this.$store.state.user && this.$store.state.user.roles.some(
                    (item) => item.label != 'Propriétaire'
                  ))
                  "
                  @click="showListFavoris">
                    <span>Liste des favoris</span> </h6>
    
                <h6 @click.prevent="Deconnexion">se déconnecter</h6>
            </div>
            </li>
          </ul>


          <ul class="nav-menu list_menu_mobile">
            <li>
              <router-link :to="{ name: 'home' }"
                >Accueil<span class="submenu-indicator"></span
              ></router-link>
            </li>

            <li>
              <router-link to="/terrains_a_vendre"
                >Terrain et Maison<span class="submenu-indicator"></span
              ></router-link>
            </li>
            <li>
              <router-link to="/Residence_en_location"
                >Résidences<span class="submenu-indicator"> </span>
              </router-link>
             
            </li>
            <li>
              <router-link to="/about"
                >A propos de nous<span class="submenu-indicator"></span>
              </router-link>
            </li>
          </ul>
          
        

        </div>
    
    </section>

    <div class="header header-light head-shadow">
      <div class="conteneur">
        <nav id="navigation" class="navigation navigation-landscape">
          <section class="bar-menu navigation navigation-landscape">
            <div class="d-flex justify-content-between align-items-center">
              <div class="nav-header">
                <router-link exact-active-class="no-active" class="nav-brand" to="/">
                  <img src="/dexter_logo1.jpg" class="logo img_logo" alt="lce" />
                </router-link>
                <div class="nav-toggle"></div>
              </div>
              <div class="menu">
                <em class="bi bi-list" @click.prevent="toggleMenuMobile"></em>
              </div>
            </div>
          </section>
          <div class="nav-header sreen">
            <router-link exact-active-class="no-active" class="nav-brand" to="/">
              <img src="/dexter_logo1.jpg" class="logo img_logo_web" alt="lce" />
            </router-link>
            <div class="nav-toggle"></div>
          </div>
         
          <div class="nav-menus-wrapper sreen">
            <ul class="nav-menu">
              <li>
                <router-link :to="{ name: 'home' }"
                  >Accueil<span class="submenu-indicator"></span
                ></router-link>
              </li>

              <li>
                <router-link to="/terrains_a_vendre"
                  >Terrain et Maison<span class="submenu-indicator"></span
                ></router-link>
              </li>
              <li>
                <router-link to="/Residence_en_location"
                  >Résidences<span class="submenu-indicator"> </span>
                </router-link>
               
              </li>
              <li>
                <router-link to="/about"
                  >A propos de nous<span class="submenu-indicator"></span>
                </router-link>
              </li>
            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">
        
              <li class="add-listing" v-if="!this.$store.state.user" >
                <router-link :to="{ name: 'login' }" class="text-dark">
                  <img
                    src="/assets/img/user-light.svg"
                    width="12"
                    alt=""
                    class="mr-2"
                  />Se connecter</router-link
                >
              </li>
              <li 
            @click="this.$router.push('/notifications')" 
            >
                <div class="d-flex align-items-center">
                  <i class="bi bi-bell-fill" v-if="this.$store.state.user"></i>
                  <span class="badge bg-danger"
                  v-if="this.$store.state.listNotification.length > 0"
                  >{{this.$store.state.listNotification.length}}</span>
                </div>
              </li>

              <li v-if="this.$store.state.user" class="position-relative">
                
                <div class="btn-group account-drop" 
                @click.prevent="toggleDashboard = !toggleDashboard">
                  <button
                    type="button"
                    class="btn btn-order-by-filt dropdown-toggle"
                    id="showbutton"
                    
                  >
                
                    <img
                      src="/assets/img/user-5.jpg"
                      class="avater-img"
                      alt="avatar"
                    />
                    {{this.$store.state.user.lastname}}
                  </button>
                </div>
                <div class="see-dashboard text-start" v-show="toggleDashboard">

                  <h6 @click.prevent="toogleDahboard">
                   <span>Tableau de bord</span> 
                  </h6>
                   
                    <h6
                    v-if="this.$store.state.user ||
                    (this.$store.state.user && this.$store.state.user.roles.some(
                      (item) => item.label != 'Propriétaire'
                    ))
                  "
                    @click="showListFavoris">
                      <span>
                        Liste des favoris</span>
                    </h6>
      
                  <h6 @click.prevent="Deconnexion">se déconnecter</h6>
              </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>
<style scoped>
.bi-bell-fill{
  font-size:1.3em;
}
.img_logo{
  width:30px;
  height:80px;
  margin-left: 10px;
  border-radius:20px;
}
h6{
  padding:.5em;
}

.add-listing{
  background-color: black !important;
}

a.router-link-exact-active {
  color: rgb(254, 200, 22) !important;
}
.img_logo_web{
  border-radius:20px;
  width:50px;
  height:50px;
}
.see-dashboard{
    height:auto;
    width:200px;
    padding:1em;
    top:4em;
    left:-5em;
    background: white;
    position:absolute;
    z-index: 999;
    border-radius:5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.155);
}
.see-dashboard h6{
    cursor:pointer;
}
.bar-menu{
  display:none;
}
.menu_mobile{
  position:fixed;
  background-color:rgba(0, 0, 0, 0.526);
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:99999;
}
.menu{
  right:0;
  width:60%;
  height:100%;
  top:0;
  bottom:0;
  background-color:white;
  text-align:right;
  padding:0 2em;
  font-size: 1em;
  overflow:auto;
}
.fermer_menu_mobile{
  left:0;
  width:50%;
  height:100%;
  top:0;
  bottom:0;
  background-color:transparent;
}
.list_menu_mobile{
  display:flex !important;
  flex-direction:column;
  width:100%;
 
}
.list_menu_mobile li{
  text-align:center;
}
.profile_mobile{
  width:100%;
  margin:1em 0 0 0;
}
.menu_mobile{
  display:none
}
@media screen and (max-width:1200px) {
  .sreen{
    display:none;
  }
  .menu_mobile{
    display:block;
  }
  .header{
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.338);
    
  }
  .bi-list{
    font-size:2em !important;
    font-weight:900 !important;
    cursor: pointer;
  }
  .nav-brand .logo{
    width:100% !important;
  }
  .navigation{
    height:100px !important;
  }
  .nav-brand{
    padding:0;
  }
  nav,.conteneur{
    padding:0;
  }
  section{
  padding:0;
  }
  .bar-menu{
    display:block;
  }
}
</style>
