<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
const baseUrl = "http://192.168.1.3:8080/api/";
export default {
    name:'HistoriqueReservationView',
    data() {
        return {
            columns: [
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Propriété',
          field: 'libelle',
        },
        {
          label: 'Type propriéte',
          field: 'type',
        },
        {
          label: 'Mode d\'acquisition',
          field: 'acquisition',
        },
        // {
        //   label: 'Image',
        //   field: 'image',
        //   width: '100px',
          
        // },
        {
          label: 'Tarif',
          field: 'tarif',
          type: 'string'
        },
        // {
        //   label: 'Date de reservation',
        //   field: 'reservation',
        //   type: 'string',
        // },
        {
          label: 'Details',
          field: 'detail',
          tdClass: 'text-center',


        }
      ], rows: [{
        date:"15-02-2022",libelle:"Super Villa",type:"villa basse",acquisition:"location",tarif:"15000 XOF / jour",reservation:"25-02-2022 au 28-02-2022"
      },{
        date:"16-02-2022",libelle:"Belle vue",type:"villa basse",acquisition:"vente",tarif:"150.000.000 XOF",reservation:""
      }
        
      ],
      listHistoriqueFournisseur:[],
      preloader:false,
      ToggleShowRebootInformationResidence:false,
      showConfirmationForModify:false,
      ToggleShowDetailVersementResidence:false,
        }
    },
    methods: {
      Toogle(){
     this.ToggleShowDetailVersementResidence = !this.ToggleShowDetailVersementResidence
      },
      ShowListReservationFournisseur() {
        this.preloader = true
      axios
        .get(baseUrl + "histofournisseur", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST HISTORIQUE RESERVATION",response.data.data);
          this.listHistoriqueFournisseur = response.data.data;
          this.preloader =false
          //console.log("LIST RESERVATION",this.listHistoriqueFournisseur);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    },
    created(){
      this.ShowListReservationFournisseur()
    }
}
</script>
<template>
    <div class="col-lg-10 col-md-12">
        <div class="dashboard-wraper">
          <div class="conteneur_modify" id="modalModify" v-show="ToggleShowDetailVersementResidence">
            
            <div class="content position-relative" >
                
                <div class="ecriteau text-start d-flex justify-content-between align-items-center">
                    <h4>Détails Réservation</h4>
                    <em class="bi bi-x-circle h1" @click.prevent="Toogle"></em>
                </div>
                <div class="submit-pages text-start">
                  <div class="infoResidence d-flex justify-content-between align-items-center mb-5">
                  <div>
                    <h3>Super villa</h3>
                    <span>Type logement : Résidence</span>
                  </div>
                   <!-- <img class="w-25" src="./assets/villa.jpg" alt="villa"> -->
                  </div>
                <div class="boxTotal pb-5">
                  <h5 class="badge bg-danger">Détails résidence</h5>
                  <h5> <strong>Date & heure</strong> : 15-02-2022 , 08h12</h5>
                  <h5> <strong>Propriété</strong> : Super Villa</h5>
                  <h5> <strong>Type de Propriété</strong>  : Villa basse</h5>
                  <h5> <strong>Mode d'acquisition</strong>  : Location</h5>
                  <h5> <strong>Tarif</strong>  : 15000 XOF / Jour</h5>
                  <h5><strong>Date pour la reservation</strong>  : 25-02-2022 au 28-02-2022</h5>
                  <h5><strong>Raison </strong>  : Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur libero debitis iusto, illum rem mollitia accusamus consequuntur? Ipsum accusamus vitae incidunt. Et magnam ratione possimus amet totam repellat odit provident.
                  At, consequatur dolorum accusamus ipsa rerum possimus eveniet officiis numquam ratione laboriosam deleniti architecto amet quis deserunt! Earum fugiat facilis sed pariatur tempore beatae consequuntur provident quos. Praesentium, quasi esse?</h5>
                  <h5><strong>Nombre de résidents autorisés</strong>  : 10 personnes</h5>
                </div>         
              </div>

            </div>
            </div>
        
            <!-- Bookmark Property -->
            <div class="form-submit text-start" :class="preloader ? 'pre':'null'">	
              <div id="preloader position-absolute" v-show="preloader">
                <div class="preloader"><span></span><span></span></div>
              </div>	
                <h4>Historique de Réservation et vente</h4>
            </div>

            <div class="col-sm-12" v-if="preloader == false">
              <table id="MyTableData" class="table text-center">
                <thead>
                  <tr class="text-center">
                    <th class="bg-light">Date</th>
                    <th class="bg-light">Propriété</th>
                    <th class="bg-light">Type de propriété</th>
                    <th class="bg-light">Mode d'acquisition</th>
                    <th class="bg-light">Tarif</th>
                    <th class="bg-light">Détails</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listHistoriqueFournisseur" :key="index">
                    <td >
                      <span v-for="(date,index) in item.reservations" :key="index">
                        <span v-if="date.created_at != null">
                          {{ date.created_at }}
                        </span>
                        <span v-else>
                          pas de date
                        </span>
                      </span>
                      
                    </td>
                    <td v-if="item.libelle" >
                      {{ item.libelle }}
                    </td >
                    <td v-if="item.type">{{ item.type.libelle }}</td>

                    <td>
                   {{ item.sell }}
                    </td>
                    <td>
                   {{ item.tarif }}
                    </td>
                    <td>
                      <em class="bi bi-eye" @click.prevent="Toogle"></em>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
           <!-- <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              placeholder: 'Recherche',
            }"
            :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: 10,
                dropdownAllowAll: false,
                rowsPerPageLabel: 'Rangée par page',
              }">
              <template #table-row="props">
               <span v-if="props.column.label =='Image'">
                   <img class="w-100" src="./assets/villa.jpg" alt="villa">
                </span>
            <div  v-if="props.column.label =='Details'">
                <em class="bi bi-eye" @click.prevent="Toogle"></em>
          
            </div>
             </template>
             
          </vue-good-table>
             -->
            
        </div>
    </div>
</template>
<style scoped>
.pre{
  padding:0 0 15em 0;
}
.table{
  border:1px solid black;
}
td{
  border-right: 2px solid black;
}
.boxTotal h5{
  margin-bottom:1em;
}
.boxTotal h5 strong{
  color:rgb(93, 93, 93) !important;
}
.conteneur_modify{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:999;
  background-color:rgba(0, 0, 0, 0.255);
  display:flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width:60%;
  height:600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal{
  position:absolute;
  top:1em;
  right:1em;
}
.ecriteau{
  position:sticky;
  top:0;
  left:0;
  width:100%;
  padding:1em;
  background-color:white;
  box-shadow:1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index:999;
}
.submit-pages{
  padding:1em 3em;
}
</style>