<script>
export default {
    name:'headerDashComponent',
    setup() {
        
    },
}
</script>
<template>
    <div class="page-title">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    
                    <h2 class="ipt-title">Bienvenue!</h2>
                    
                </div>
            </div>
        </div>
    </div>
</template>