<script>
export default {
  name: "NotificationsVue",
  data() {
    return {
    };
  },
  methods: {
    showListNotification() {
     this.$store.dispatch("getListNotifications")
    },
  },
  created() {
    this.showListNotification();
  },
};
</script>
<template>
  <div class="col-lg-10 col-md-12">
    <div class="row">
      <h1 class="text-start">Mes notifications</h1>

      <div class="text-left" 
      v-for="item in this.$store.state.listNotification" :key="item.id"
      >
        <div class="d-flex align-items-center">
            <n-image
            width="25"
            src="../dexter_logo1.jpg"
          />
            <h5 class="mx-2">{{item.objet}}</h5>  
        </div>
        
        <p>
          {{item.texte}}
        </p>
      </div>
      <div v-if="!this.$store.state.listNotification.length" class="fw-bold">
       Pas de notifications
      </div>
    </div>
  </div>
</template>
