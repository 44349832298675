<script>
import {headerDash,AsideDash} from '@/components'

export default {
    name:'AccueilView',
    components:{
        headerDash,AsideDash
    },
    data(){
        return {

        }
    }
}
</script>
<template>
 
   <headerDash></headerDash>
   <section class="bg-light">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="filter_search_opt">
                    <a href="#" onclick="openFilterSearch()">
                        Dashboard Navigation<i class="ml-2 ti-menu"></i></a>
                </div>
            </div>
        </div>
        <div class="row">
            <AsideDash></AsideDash>
            <router-view/>
            </div> 
    </div>
   
   </section>
   
</template>