<script>
// import Vue3BasicAutocomplete from 'vue3-basic-autocomplete'

import axios from "axios";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
const baseUrl = "http://192.168.1.3:8080/api/";
export default {
    name:'listeMaisonTerrainValidee',
  //   components: {
  //   Vue3BasicAutocomplete,
  // },
    data() {
        return {
            activeResidence:false,
            columns: [
        {
          label: 'Propriété',
          field: 'libelle',
        },
        {
          label: 'Image',
          field: 'image',
          width: '100px',
          
        },
        {
          label: 'Ville',
          field: 'city',
          type: 'number',
        },
        {
          label: 'Tarif',
          field: 'tarif',
        },
        {
          label: 'Type de logement',
          field: 'logement',
          type: 'string',
        },
        {
          label: 'Type de propriété',
          field: 'TypePropriete',
          type: 'string',
        },
        {
          label: "Mode d'acquisition",
          field: 'acquisition',
          type: 'string',
        },
        {
          label: 'Approuver',
          field: 'accepte',
          type: 'string',
        },
        // {
        //   label: 'Details',
        //   field: 'details',
        // },
      ], rows: [{
        libelle:"Bel appartement",acquisition:"Vente",city:"Abidjan",tarif:"3.000.000 XOF",logement:"Maison",TypePropriete:"villa basse",statut:"En attente d'approbation",
      },
        
      ],
      ToggleShowRebootInformationResidence:false,
      showConfirmationForModify:false,
      listBienEnAttenteFournisseur:[],
      moneyFormat: new Intl.NumberFormat("de-DE"),
      preloader:false,
        }
    },
    methods: {
        Toogle(){
            this.ToggleShowRebootInformationResidence = !this.ToggleShowRebootInformationResidence
        },
        DeleteResidence(){
this.showConfirmationForModify = !this.showConfirmationForModify
        },
        ShowListReservationFournisseur() {
          this.preloader = true
      axios
        .get(baseUrl + "reservationFAV", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST BIEN EN ATTENTE",response.data);
          this.listBienEnAttenteFournisseur = response.data.data;
          this.preloader = false
          //console.log("LIST BIEN EN ATTENTE",this.listBienEnAttenteFournisseur);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    },
    created() {
      this.ShowListReservationFournisseur()
    }
}
</script>
<template>
    <div class="col-lg-10 col-md-12">
        <div class="dashboard-wraper">
        
        
            <!-- Bookmark Property -->
            <div class="form-submit text-start" :class="preloader ? 'pre':'null'">
              <div id="preloader position-absolute" v-show="preloader">
                <div class="preloader"><span></span><span></span></div>
              </div>	
                <h4>Liste des biens immobiliers loués ou achetés</h4>
            </div>

            <div class="col-sm-12" v-if="preloader == false">
                <table id="MyTableData" class="table text-center">
                  <thead>
                    <tr class="text-center">
                        <th class="bg-light">Date</th>
                      <th class="bg-light">Propriété</th>
                      <th class="bg-light">Image</th>
                      <th class="bg-light">Ville</th>
                      <th class="bg-light">Tarif</th>
                      <th class="bg-light">Type de logement</th>
                      <th class="bg-light">Mode d'acquisition</th>
                      <!-- <th class="bg-light">Status</th>
                      <th class="bg-light">Approbation</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in listBienEnAttenteFournisseur" :key="index">
                      <td>{{ new Date(item.updated_at).toLocaleDateString('fr')}}</td>
                      <td>{{ item.immobilier.libelle }}</td>
                      <td>
                        <n-image
                          width="50"
                          v-if="item.immobilier.photos.length || item.immobilier.photos[0] "
                          :src="
                            'http://192.168.1.3:8080/public/image/' +
                            item.immobilier.photos[0].path
                          "
                          alt="photo"
                        />
        
                        <span v-else>Pas d'image</span>
                      </td>
                      <td v-if="item.immobilier.ville">
                        {{ item.immobilier.ville.libelle }}
                         </td>
                      <td>{{ moneyFormat.format(item.immobilier.tarif) }} XOF  <span v-if="item.immobilier.periode"> / {{ item.immobilier.periode }}</span></td>
        
                      <td>
                     {{ item.immobilier.type_logement }}
                      </td>
                      <td>
                     {{ item.immobilier.sell }}
                      </td>
                      <!-- <td>
                        <span v-if="item.validation === 1" class="badge bg-success"
                          >Approuveé</span
                          >
                          <span
                            v-if="item.validation === 0 || item.validation === null"
                            class="badge bg-danger"
                          >
                            En attente</span
                          >
                      </td> -->
                      <!-- <td>
                        <input type="checkbox" :checked="item.validation === 0 ? false:'null'" :value="item.id"  v-if="item.validation === 0 || item.validation === null"  @input="ToogleResidence">
                     
                        <input type="checkbox" :checked="item.validation === 1 ? true:'null'" :value="item.id" v-if="item.validation === 1" @input="ToggleResidenceNotActive">
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            <!-- <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              placeholder: 'Recherche',
            }"
            :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: 10,
                dropdownAllowAll: false,
                rowsPerPageLabel: 'Rangée par page',
              }">
              <template #table-row="props">
                <span v-if="props.column.label =='Image'">
                   <img class="w-100" src="./assets/villa.jpg" alt="villa">
                </span>
            <div class="d-flex justify-content-between align-items-center" v-if="props.column.label =='Details'">
                <em class="bi bi-pencil" @click.prevent="Toogle"></em>
                <em class="bi bi-eye" @click.prevent="seeDetailTerrain"></em>
                <em class="bi bi-trash3" @click.prevent="DeleteResidence"></em>
            </div>
            <div class="d-flex justify-content-center align-items-center" v-if="props.column.label =='Approuver'">
                <KCheckbox v-model="activeResidence" :required="true" /> <span class="badge text-bg-primary" v-if="!activeResidence">En attente</span>
                <span class="badge text-bg-success" v-if="activeResidence">Accepter</span>
              </div>
             </template>
             
          </vue-good-table> -->
           
            
        </div>
    </div>
</template>
<style scoped>
@import url("@kong/kongponents/dist/style.css");
.table {
  border: 1px solid black;
}
.pre{
  padding:0 0 15em 0;
}
td {
  border-right: 2px solid black;
}
.container[data-v-51562f51]{
    width:100% !important;
    padding:0 !important;
}
.confirmation_modifie{
  position: absolute;
  z-index: 99;
  background-color:white;
  padding:2em;
  border:3px solid black;
  border-radius: 1em;
  margin-left:50%;
  transform:translateX(-75%)
}
.conteneur_modify{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:999;
  background-color:rgba(0, 0, 0, 0.255);
  display:flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width:60%;
  height:600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal{
  position:absolute;
  top:1em;
  right:1em;
}
.ecriteau{
  position:sticky;
  top:0;
  left:0;
  width:100%;
  padding:1em;
  background-color:white;
  box-shadow:1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index:999;
}
.submit-pages{
  padding:1em 3em;
}
</style>