import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import location from '../views/LocationResidence.vue'
import PageActiveAccount from '../views/PageActiveAccount.vue'
import sejour from '../views/SejourResidence.vue'
import DetailsResidence from '../views/DetailsResidence.vue'
import terrain from '../views/TerrainsView.vue'
import accueilDashboard from '../views/AccueilView.vue'
import Bienvenue from "../views/BienvenueView.vue";
import CreateResidence from "../views/CreateResidenceView.vue";
import HistoriqueVersement from "../views/HistoriqueVersementView.vue";
import HistoriqueReservation from "../views/HistoriqueReservationView.vue";
import ListesReservation from "../views/ListesReservationView.vue";
import ListesResidencesReservees from "../views/ListesResidencesReserveesView.vue";
import ListesResidences from "../views/ListesResidencesView.vue";
import Profil from "../views/ProfilView.vue";
import ReinitialisePasswordView from "../views/ReinitialisePasswordView.vue";
import ListesProprieteVenteView from "../views/ListesProprieteVenteView.vue"
import BienImmobilierVente from "../views/BienImmobilierVenteView.vue"
import EspaceClient from "../views/EspaceAdminClientView.vue"
import listeResidenceReserveeValide from "../views/listeResidenceReservationValidee.vue"
import listeMaisonTerrainValide from "../views/listeMaisonTerrainValidee.vue"

import detailReservation from "../views/dashboard/detailReservation.vue"
import ListVisite from "../views/dashboard/ListVisite.vue"
import DetailPropriete from "../views/detail/DetailPropriete.vue"
import  RevenuVue from "../views/revenu/RevenuVue.vue"
import NotificationsVue from "../views/notifications/NotificationsVue.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/activate-account/:email/:token',
    name: 'account',
    component: PageActiveAccount
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/connexion',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/inscription',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  {
    path: '/reinitialisation-mot-de-passe',
    name: 'rebootpassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgetPasswordView.vue')
  },
  {
    path: '/Residence_en_location',
    name: 'locationResidence',
    component: location
  },
  {
    path: '/Residence_pour_sejour',
    name: 'sejourResidence',
    component: sejour
  },
  {
    path: '/terrains_a_vendre',
    name: 'terrain',
    component: terrain
  },
  {
    path: '/details/:id',
    name: 'details',
    component: DetailsResidence
  },
  {
    path: '/:token/:transaction_id',
    name: 'searchByVille',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchByVilleView.vue')
  },
  {
    path: '/favoris',
    name: 'listeFavoris',
    meta:{
      requiresAuth:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/favoris/ListeFavoris.vue')
  },

  {
    path: '/conditions-generales-utilisation',
    name: 'conditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConditionGeneraleView.vue')
  },
  {
    path: "/:pathMatch(.*)",
    redirect: '/',
  },
  {
    path: '/change_password/:email/:token',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReinitialisePasswordView.vue')
  },
  {
    path: '/detail/propriete/:id',
    name: 'doVisite',
    component: () => import(/* webpackChunkName: "about" */ '../views/visite/VisiteVue.vue')
    
  },
  
  {
    path: '/espace_client',
    name: 'espaceClient',
    meta:{
      requiresAuth:true
    },
    component: EspaceClient
  },
  {
    path: '/tableau_de_bord',
    name: 'dashboard',
    meta:{
      requiresAuth:true
    },
    component: accueilDashboard,
    children: [
      {
        path: '',
        name: 'Bienvenue',
        // meta:{
        //   requiresAuth:true
        // },
        component: Bienvenue
      },
      {
        path: '/enregistrer_ma_residence',
        name: 'CreateResidence',
        meta:{
          requiresAuth:true
        },
        component: CreateResidence
      },
      {
        path: '/detail-propriete/:id',
        name: 'DetailPropriete',
        meta:{
          requiresAuth:true
        },
        component: DetailPropriete
      },
      {
        path: '/revenu',
        name: 'revenus',
        meta:{
          requiresAuth:true
        },
        component: RevenuVue
      },
      {
        path: '/notifications',
        name: 'notifications',
        meta:{
          requiresAuth:true
        },
        component: NotificationsVue
      },
     
      
      {
        path: '/historique_versements',
        name: 'HistoriqueVersement',
        meta:{
          requiresAuth:true
        },
        component: HistoriqueVersement
      },
      {
        path: '/historique_versement',
        name: 'HistoriqueReservation',
        meta:{
          requiresAuth:true
        },
        component: HistoriqueReservation
      },
      {
        path: '/liste_reservation',
        name: 'ListesReservation',
        meta:{
          requiresAuth:true
        },
        component: ListesReservation
      },
      {
        path: '/liste-residence-reservee',
        name: 'ListesResidencesReservees',
        meta:{
          requiresAuth:true
        },
        component: ListesResidencesReservees
      },
      {
        path: '/liste-residence',
        name: 'ListesResidences',
        meta:{
          requiresAuth:true
        },
        component: ListesResidences
      },
      {
        path: '/mon_profil',
        name: 'Profil',
        meta:{
          requiresAuth:true
        },
        component: Profil
      },
      {
        path: '/reinitialiser_mon_mot_de_passe',
        name: 'ReinitialisePassword',
        component: ReinitialisePasswordView
      }, 
      {
        path: '/liste_propriete_en_vente',
        name: 'ListesProprieteVente',
        meta:{
          requiresAuth:true
        },
        component: ListesProprieteVenteView
      },
      {
        path: '/liste_residence_reservation_validee',
        name: 'ListesResidencesReserveesValide',
        meta:{
          requiresAuth:true
        },
        component: listeResidenceReserveeValide
      },
      {
        path: '/liste_residence_reservation_validee/:id',
        name: 'detailResidence',
        meta:{
          requiresAuth:true
        },
        component: detailReservation
      },
      {
        path: '/liste_visites/',
        name: 'visites',
        meta:{
          requiresAuth:true
        },
        component: ListVisite
      },
      
      {
        path: '/liste_Maison_Terrain_validee',
        name: 'BienImmobilierAccept',
        meta:{
          requiresAuth:true
        },
        component: listeMaisonTerrainValide
      },
      {
        path: '/liste_bien_immobilier_en_vente',
        name: 'BienImmobilierVente',
        meta:{
          requiresAuth:true
        },
        component: BienImmobilierVente
      },
      
      
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if(savedPosition){
      return savedPosition
    }else{
      return { top: 0 }
    }
 
  },
})

router.beforeEach( (to, _from, next) => {
  if(to.meta.requiresAuth){
    const session = localStorage.getItem('token')
    if(session === null){
      next({
        path:'/connexion',
        query:{redirect:to.fullPath}
    }
      )
    }
   
    if(session !== null){
      next()
    }
  }else{
     next()
  }
})

export default router
