<script>
import instance from "../api/api";

import Swal from "sweetalert2";
export default {
   data(){
    return{
        // oldPassword:"",
        NewPassword:"",
        ConfirmPassword:""
    }
   },
   methods: {
    changePassword() {
      if(this.ConfirmPassword != this.NewPassword){
        Swal.fire({
            icon: "error",
            title: "Confirmation de mot de passe incorrect.",
            showConfirmButton: false,
            timer: 1500,
          });
      }else{ 
        let data = {
            email:this.$route.params.email,
            password:this.NewPassword,
            token:this.$route.params.token
      };
      instance.post("passreset",data)
      .then(response=>{
        console.log(response);
        if(response.data.status === true){
          Swal.fire({
            icon: "success",
            title: "Mot de passe changé avec succès.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if(response.data.status === false){
          Swal.fire({
            icon: "error",
            title: "Mot de passe incorrect.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch(error => {
        console.log(error);
      })
      }
     
    },
   }
}
</script>
<template>
    <div class="col-lg-12 col-md-12 col-sm-6">
        <div class="dashboard-wraper text-start py-5">
        
            
            <div class="form-submit">	
                <h4  class="modal-header-title">Changer mon mot de passe</h4>
                <div class="submit-section">
                  <form @submit.prevent="changePassword">
                    <div class="row px-5">
                    
                        <!-- <div class="form-group col-lg-12 col-md-6">
                            <label>Ancien mot de passe</label>
                            <input type="password" class="form-control" v-model="oldPassword">
                        </div> -->
                        
                        <div class="form-group col-md-6">
                            <label>Nouveau mot de passe</label>
                            <input type="password" class="form-control" v-model="NewPassword">
                        </div>
                        
                        <div class="form-group col-md-6">
                            <label>Confirmer le nouveau mot de passe</label>
                            <input type="password" class="form-control" v-model="ConfirmPassword">
                        </div>
                        
                        <div class="form-group col-lg-12 col-md-12 text-center">
                            <button class="btn btn-theme-light-2 rounded" type="submit">Envoyer</button>
                        </div>
                        
                    </div>
                  </form>
                </div>
            </div>
            
        </div>
    </div>
</template>
<style scoped>
.btn-theme-light-2,.btn-theme-light-2:hover{
  background-color: black !important;
  color:white !important;
} 
.dashboard-wraper{
 margin-left:-50%;
 transform:translateX(50%);

}
</style>