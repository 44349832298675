<script>
import instance,{lienImage} from "../api/api";
export default {
  name: "listeResidenceReservationValidee",
  //   components: {
  //   KCheckbox,
  // },
  data() {
    return {
      lienImage:lienImage,
      listResidenceReservee: [],
      preloader: false,
      activeResidence: false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      perPage:3,
      currentPage:1,
      current:1,
      rows: 6,
    };
  },
  computed:{
    paginatedReservation() {
      const startIndex = (this.currentPage - 1) * this.rows;
      //console.log("startIndex",startIndex)
      const endIndex = startIndex + this.rows;
      //console.log("endIndex",endIndex)
      return this.listResidenceReservee.slice(startIndex, endIndex);
    },
  },
  methods: {
    onPageChange(event) {
      //console.log("EVENT",event)
      this.currentPage = event.page + 1;
    },
    ToogleResidence(e) {
      console.log(this.activeResidence);
      let data = {
        validation: 1,
      };
      let IdResidence = Number(e.target.value);
      this.listResidenceReservee.forEach((item) => {
        if (item.id === IdResidence) {
          item.validation = 1;
        }
      });
      instance
        .post("updatevalid/" + IdResidence, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ToggleResidenceNotActive(e) {
      let data = {
        validation: 0,
      };
      let IdResidence = Number(e.target.value);
      this.listResidenceReservee.forEach((item) => {
        if (item.id === IdResidence) {
          item.validation = 1;
        }
      });
      instance
        .post("updatevalid/" + IdResidence, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ShowListResidenceReserveeValidee() {
      this.preloader = true;
      instance
        .get("ReservationClient")
        .then((response) => {
          //console.log("LIST RESIDENCE RESERVE ACCEPTÉES", response);
          this.listResidenceReservee = response.data.data;
          this.preloader = false;
          console.log(
            "LIST RESIDENCE RESERVE ACCEPTÉES",
            this.listResidenceReservee
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
   async getPayemts(){
      try{
        const responsePayment = await instance.get("paiements")
        console.log("responsePayment",responsePayment.data)
      }catch(error){
        console.log(error)
      }
    }
  },
  created() {
    this.ShowListResidenceReserveeValidee();
    this.getPayemts()
  },
};
</script>
<template>
  <div class="col-lg-10 col-md-12">
    <div class="dashboard-wraper">
      <div class="form-submit text-start" :class="preloader ? 'pre' : 'null'">
        <div id="preloader position-absolute" v-show="preloader">
          <div class="preloader"><span></span><span></span></div>
        </div>
        <h4>Mes réservations</h4>
        <span class="py-3">{{this.listResidenceReservee.length}} résultats</span>
      </div>
      <div class="row">
        <div
          v-for="item in paginatedReservation"
          :key="item.id"
          class="col-lg-4 col-md-6 col-sm-12 position-relative"
        >
        
          <div class="dashboard-stat widget-1 text-left">
            
            <div class="dashboard-stat-content d-flex justify-content-between">
              
           <div>
            <h6>{{ item.propriety.name }}</h6>
            <span>Date d'arrivée:{{ new Date(item.date_start).toLocaleDateString('fr') }}</span>
            <span>Date de sortie:{{ new Date(item.date_end).toLocaleDateString('fr') }}</span>
            <span>Prix:{{ moneyFormat.format(item.cost) }} XOF</span>
            <span class="d-block">Code:{{ item.propriety.code }}</span>
           </div>
              <div>
                <n-image
       width="80"
       :src="lienImage+item.propriety.photos[0].image"
       />
              </div>
            </div>
            
           
            <span
            v-if="item.payements[0]" 
            :class="item.payements[0].status === 'PENDING'?'bg-info':item.payements[0].status === 'FAILED'?'bg-danger':'bg-success'"
            class="badge alert-success position-absolute">
              {{ item.payements[0].status === 'PENDING'? 'en attente':item.payements[0].status === 'FAILED'?'echouée':'payée' }}</span
            >
            <span class="alert-date position-absolute">
              {{ new Date(item.created_at).toLocaleDateString("fr") }}</span
            >
            <button class="btn bg-dark btn-voir text-light position-absolute">
          <router-link
          :to="{
            name: 'detailResidence',
            params: { id: item.id },
          }"
          class="text-light fw-bold"
          >Voir plus</router-link
        >
              
            </button>
          </div>
        </div>
        <div v-if="listResidenceReservee.length">
          <PaginatorVue
          v-model="currentPage"
          :rows="rows"
          :totalRecords="listResidenceReservee.length"
          @page="onPageChange"
        ></PaginatorVue>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dashboard-stat{
  height:260px !important;
}

.dashboard-stat.widget-1 {
  background-color: white;
  box-shadow: 1px 1px 1.5px 1.5px black;
  color: black !important;
}
.dashboard-stat-content h4 {
  color: black !important;
}
.dashboard-stat-content {
  left: 0 !important;
  padding: 1em;
}

.alert-success {
  left: 0 !important;
  top: 0.5em !important;
}
.alert-date {
  right: 0 !important;
  top: 0.5em !important;
}
.btn-voir {
  right: 0 !important;
  bottom: 0 !important;
}
</style>
