import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import VueNumberFormat from 'vue-number-format'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazGallery from 'maz-ui/components/MazGallery'
import TabMenu from 'primevue/tabmenu';
import ImagePrime from 'primevue/image';
import MazCard from 'maz-ui/components/MazCard';
import MazInputNumber from 'maz-ui/components/MazInputNumber';
import MazSlider from 'maz-ui/components/MazSlider';
import PrimeVue from "primevue/config";
import SliderPrime from 'primevue/slider';
import PaginatorData from 'primevue/paginator';
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css'
import RatingStar from 'primevue/rating';
import VueSelect from 'vue-select'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Dialog from 'primevue/dialog';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import 'vuesax3/dist/vuesax.css'
import naive from 'naive-ui'
import Carousel from 'primevue/carousel';

import UploadMedias from "vue-upload-drop-medias"

import MultiSelect from 'primevue/multiselect';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import 'vue-select/dist/vue-select.css';
import Paginator from 'primevue/paginator';
import Galleria from 'primevue/galleria';
import ProgressSpinner from 'primevue/progressspinner';

const app = createApp(App);
app.use(router);
app.use(Antd)
app.use(naive)
app.use(ElementPlus)
app.component('VueGoodTable', VueGoodTable);
app.use(PrimeVue);
app.component('v-select', VueSelect);
app.component("carouselVue",Carousel);
app.component("TabView",TabView);
app.component("TabPanel",TabPanel);
app.component("RatingStar", RatingStar);
app.component("SliderPrime", SliderPrime);
app.component('MazSlider', MazSlider);
app.component('ProgressSpinner', ProgressSpinner);
app.component('DialogVue',Dialog)
app.component('GalleriaVue', Galleria);
app.component('PaginatorData', PaginatorData);
app.component('PaginatorVue', Paginator);
app.component('MazPhoneNumberInput', MazPhoneNumberInput);
app.component('MazInputNumber', MazInputNumber);
app.component('UploadMedias', UploadMedias);
app.component('MazGallery', MazGallery);
app.component('MazCard', MazCard);
app.component('TabMenu', TabMenu);
app.component('ImagePrime', ImagePrime);
app.component('MultiSelect', MultiSelect);
app.use(VueNumberFormat)
app.use(store);

app.mount('#app');
