import { createStore } from "vuex";
import Swal from "sweetalert2";
import router from "@/router";
import instance from "../api/api";

export default createStore({
  state: {
    roles: [],
    equipement:[],
    villes:[],
    nameVilleSearch:"",
    typePropriete:[],
    ListResidence:[],
    ListBiensImmobilier:[],
    allBienImmobilier:[],
    user: JSON.parse(localStorage.getItem("user")) || "",
    token: JSON.parse(localStorage.getItem("token")),
    spinner:false,
    isConnecte:false,
    myListFavoris:[],
    isLoading:false,
    isPayByCinetpay:JSON.parse(localStorage.getItem("code")) || null,
    tokenByCinetpay:JSON.parse(localStorage.getItem("tokenCinetpay")) || null,
    listNotification:[],
  },
  getters: {
    
  },
  mutations: {
    LIST_FAVORIS(state, payload) {
      state.myListFavoris = payload;
    },
    CONNECT_USER(state, infoUser) {
      state.user = infoUser;
    },
    CHANGE_ISCONNECTE(state,payload) {
      state.isConnecte = payload;
    },
    GET_VILLE(state,ville) {
      state.villes = ville;
    },
    GET_TYPE_PROPRIETE(state,type) {
      state.typePropriete = type;
    },
    GET_RESIDENCE(state,Residence) {
      state.ListResidence = Residence;
    },
    CHANGE_NOTIFICATION(state,payload) {
      state.listNotification = payload;
    },
    CHANGE_ISLOADING(state,payload){
      state.isLoading = payload
    },
  IS_PAY_CINETPAY(state,payload){
   state.isPayByCinetpay = payload
   localStorage.setItem("code", JSON.stringify(payload));
  },
  CODE_PAYEMENT(state,payload){
    state.isPayByCinetpay = payload
    localStorage.setItem("tokenCinetpay", JSON.stringify(payload));
   }
  },

  actions: {
   
    getListFavoris({commit,state}) {
      commit("CHANGE_ISLOADING", true);
      instance
        .get("favorites")
        .then((response) => {
          state.myListFavoris = response.data.data
          commit("LIST_FAVORIS", response.data.data);
          //console.log("LISTFAVORIS", response.data.data);
          commit("CHANGE_ISLOADING", false);
        })
        .catch((err) => {
          console.log(err);
          commit("CHANGE_ISLOADING", false);
        });
    },
    getListNotifications({commit,state}) {
      instance
        .get("messages")
        .then((response) => {
          state.myListFavoris = response.data.data
          //console.log("myListFavoris",response)
          commit("CHANGE_NOTIFICATION", response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async ConnectUser({commit,state}, dataForConnect) {
      state.spinner = true
      let dataUser = {
        email:dataForConnect.email,
        password:dataForConnect.password,
       
       }  
      await instance.post("login", dataUser)
      .then((response) => {
        //console.log("reponses",response);
        if (response.data.status === true) {
          state.isConnecte = true
          commit("CONNECT_USER", response.data.data.user);
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          localStorage.setItem("token", JSON.stringify(response.data.data.access_token));
          state.user = JSON.parse(localStorage.getItem("user"))
          state.token = JSON.parse(localStorage.getItem("token"))
         
          Swal.fire({
            icon: "success",
            title: "Connecté",
            showConfirmButton: false,
            timer: 1500,
          });
          state.spinner = false
          router.push(dataForConnect.redirectPath);
        }
        if (response.data.status === false) {
          Swal.fire({
            icon: "error",
            title: response.data.message,
            showConfirmButton: true,
          });
          state.spinner = false
          state.isConnecte = false
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: err.response.data.message,
          showConfirmButton: true,
        });
        state.spinner = false
        
      });
    },
    DeconnecterUser({state,commit}){
           localStorage.removeItem("token");
            localStorage.removeItem("user");
            state.user = ""
            state.token = ""
            Swal.fire({
              icon: "success",
              title: "Déconnexion",
              showConfirmButton: false,
              timer: 1500,
            });
            commit("LIST_FAVORIS", []);
            router.push('/')
            console.log(state.user);
            
    },
    ModifyUser({commit},dataForConnect) {
      commit("CONNECT_USER", dataForConnect);
          localStorage.setItem("user", JSON.stringify(dataForConnect));
    },
 
  },

  modules: {},
});
