<script>
export default {
    name:'HistoriqueVersementView',
    data() {
        return {
            columns: [
        {
          label: 'Propriété',
          field: 'propriete',
          type: 'string',
          tdClass: 'text-center',
        },
        {
          label: 'Type de logement',
          field: 'logement',
          type: 'string',
          tdClass: 'text-center',
        },
        // {
        //   label: 'Image',
        //   field: 'image',
        //   width: "150px",
        // },
        {
          label: 'Détail',
          field: 'detail',
          width: "100px",
          tdClass: 'text-center',
          
        },
      ], 
      rows: [{
        libelle:"Bouba",restant:"3.000XOF",versement:"15.000 XOF",vente:"25-02-2022",operation:"orange money",logement:"residence",propriete:"super villa"
      },{
        libelle:"Bouba",restant:"30.000XOF",versement:"1.000.000 XOF",vente:"21-02-2022",operation:"Cash",logement:"residence",propriete:"Bella vue"
      }
        
      ],
      ToggleShowDetailVersementResidence:false,
        }
    },
    methods: {
      Toogle(){
     this.ToggleShowDetailVersementResidence = !this.ToggleShowDetailVersementResidence
      }
    },
}
</script>
<template>
    <div class="col-lg-10 col-md-12">
        <div class="dashboard-wraper">
          <div class="conteneur_modify" id="modalModify" v-show="ToggleShowDetailVersementResidence">
            
            <div class="content position-relative" >
                
                <div class="ecriteau text-start d-flex justify-content-between align-items-center">
                    <h4>Détails versement</h4>
                    <i class="bi bi-x-circle h1" @click.prevent="Toogle"></i>
                </div>
                <div class="submit-pages text-start">
                  <div class="infoResidence d-flex justify-content-between align-items-center mb-5">
                  <div>
                    <h3>Super villa</h3>
                    <span>Type logement : Résidence</span>
                  </div>
                   <!-- <img class="w-25" src="./assets/villa.jpg" alt="villa"> -->
                  </div>
                <div class="d-flex justify-content-between align-items-center versement p-2">
                  <div>
                    <span>
                      Versement : <strong>15.000 XOF</strong>
                    </span>
                    <span class="d-block">
                      Opérateur : <strong>Bouba</strong>
                    </span>
                    <strong class="d-block">Date : 25-05-2022</strong>
                  </div>
                  <div>
                    <button class="btn bg-primary text-light">Accepter</button>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center versement p-2">
                  <div>
                    <span>
                      Versement : <strong>5.000 XOF</strong>
                    </span>
                    <span class="d-block">
                      Opérateur : <strong>Bouba</strong>
                    </span>
                    <strong class="d-block">Date : 23-05-2022</strong>
                  </div>
                  <div>
                   <span>Versement accepté</span>
                  </div>
                </div>
                <div class="boxTotal">
                  <h6 class="badge bg-danger">Resumé</h6>
                  <h6>Montant versé : 5.000 XOF</h6>
                  <h6>Montant restant : 35.000 XOF</h6>
                  <h6>Montant à remettre : 35.000 XOF</h6>
                </div>
                  
                
                              
              </div>

            </div>
            </div>
        
            <!-- Bookmark Property -->
            <div class="form-submit text-start">	
                <h4>Historique de versement</h4>
            </div>
        
            
        </div>
    </div>
</template>
<style scoped>
.versement{
  box-shadow:1px 1px 3px rgba(0, 0, 0, 0.133);
  border-left:3px solid blue;
  margin:.5em 0;
}
.boxTotal{
  box-shadow:1px 1px 3px rgba(0, 0, 0, 0.292);
  padding:.5em;
  border-radius:10px;
}
.confirmation_modifie{
  position: absolute;
  z-index: 99;
  background-color:white;
  padding:2em;
  border:3px solid black;
  border-radius: 1em;
  margin-left:50%;
  transform:translateX(-75%)
}
.conteneur_modify{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:999;
  background-color:rgba(0, 0, 0, 0.255);
  display:flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width:60%;
  height:600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal{
  position:absolute;
  top:1em;
  right:1em;
}
.ecriteau{
  position:sticky;
  top:0;
  left:0;
  width:100%;
  padding:1em;
  background-color:white;
  box-shadow:1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index:999;
}
.submit-pages{
  padding:1em 3em;
}
</style>