<script>
export default {
    name:'SejourResidence',
    setup() {
        
    },
}
</script>
<template>
    <div>
        <div class="page-title">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        
                        <h2 class="ipt-title">Property List</h2>
                        <span class="ipn-subtitle">Property List With Sidebar</span>
                        
                    </div>
                </div>
            </div>
        </div>
        <section class="gray">
            <div class="container">
                
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="filter_search_opt">
                            <a href="javascript:void(0);" 
                            onclick="openFilterSearch()">Recherche<i class="ml-2 ti-menu"></i></a>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                
                    <!-- property Sidebar -->
                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="simple-sidebar sm-sidebar" id="filter_search"  style="left:0;">							
                        
                            <div class="search-sidebar_header">
                                <h4 class="ssh_heading">Close Filter</h4>
                                <button onclick="closeFilterSearch()" class="w3-bar-item w3-button w3-large"><i class="ti-close"></i></button>
                            </div>
                            
                            <!-- Find New Property -->
                            <div class="sidebar-widgets">
                                
                                <!-- Find New Property -->
                                <div class="sidebar-widgets">
                                    
                                    <div class="form-group simple">
                                        <div class="input-with-icon">
                                            <input type="text" class="form-control" placeholder="Neighborhood">
                                            <em class="ti-search"></em>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="input-with-icon">
                                            <input type="text" class="form-control" placeholder="Location">
                                            <i class="ti-location-pin"></i>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="ptypes" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">Apartment</option>
                                                <option value="2">Condo</option>
                                                <option value="3">Family</option>
                                                <option value="4">Houses</option>
                                                <option value="5">Villa</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="status" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">For Rent</option>
                                                <option value="2">For Buy</option>
                                                <option value="3">For Sale</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="minprice" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">Less Then $1000</option>
                                                <option value="2">$1000 - $2000</option>
                                                <option value="3">$2000 - $3000</option>
                                                <option value="4">$3000 - $4000</option>
                                                <option value="5">Above $5000</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="bedrooms" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="bathrooms" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="garage" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">Any Type</option>
                                                <option value="2">Yes</option>
                                                <option value="3">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group simple">
                                        <div class="simple-input">
                                            <select id="built" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">2010</option>
                                                <option value="2">2011</option>
                                                <option value="3">2012</option>
                                                <option value="4">2013</option>
                                                <option value="5">2014</option>
                                                <option value="6">2015</option>
                                                <option value="7">2016</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <div class="simple-input">
                                                    <input type="text" class="form-control" placeholder="Min Area">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="form-group">
                                                <div class="simple-input">
                                                    <input type="text" class="form-control" placeholder="Max Area">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
                                            <h6>Choose Price</h6>
                                            <div class="rg-slider">
                                                 <input type="text" class="js-range-slider" name="my_range" value="" />
                                            </div>
                                        </div>
                                    </div>									

                                    <div class="ameneties-features">
                                        <div class="form-group" id="module">
                                            <a role="button" class="" data-bs-toggle="collapse" href="#advance-search" aria-expanded="true" aria-controls="advance-search"></a>
                                        </div>
                                        <div class="collapse" id="advance-search" aria-expanded="false" role="banner">
                                            <ul class="no-ul-list">
                                                <li>
                                                    <input id="a-1" class="checkbox-custom" name="a-1" type="checkbox">
                                                    <label for="a-1" class="checkbox-custom-label">Air Condition</label>
                                                </li>
                                                <li>
                                                    <input id="a-2" class="checkbox-custom" name="a-2" type="checkbox">
                                                    <label for="a-2" class="checkbox-custom-label">Bedding</label>
                                                </li>
                                                <li>
                                                    <input id="a-3" class="checkbox-custom" name="a-3" type="checkbox">
                                                    <label for="a-3" class="checkbox-custom-label">Heating</label>
                                                </li>
                                                <li>
                                                    <input id="a-4" class="checkbox-custom" name="a-4" type="checkbox">
                                                    <label for="a-4" class="checkbox-custom-label">Internet</label>
                                                </li>
                                                <li>
                                                    <input id="a-5" class="checkbox-custom" name="a-5" type="checkbox">
                                                    <label for="a-5" class="checkbox-custom-label">Microwave</label>
                                                </li>
                                                <li>
                                                    <input id="a-6" class="checkbox-custom" name="a-6" type="checkbox">
                                                    <label for="a-6" class="checkbox-custom-label">Smoking Allow</label>
                                                </li>
                                                <li>
                                                    <input id="a-7" class="checkbox-custom" name="a-7" type="checkbox">
                                                    <label for="a-7" class="checkbox-custom-label">Terrace</label>
                                                </li>
                                                <li>
                                                    <input id="a-8" class="checkbox-custom" name="a-8" type="checkbox">
                                                    <label for="a-8" class="checkbox-custom-label">Balcony</label>
                                                </li>
                                                <li>
                                                    <input id="a-9" class="checkbox-custom" name="a-9" type="checkbox">
                                                    <label for="a-9" class="checkbox-custom-label">Icon</label>
                                                </li>
                                            </ul>
                                        </div>
                                    
                                        <button class="btn btn btn-theme-light-2 rounded full-width">Find New Home</button>
                                    
                                    </div>
                                
                                </div>							
                            </div>
                        </div>
                        <!-- Sidebar End -->
                    
                    </div>

                    
                    <div class="col-lg-8 col-md-12 list-layout">
                    
                        <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="item-shorting-box">
                                    <div class="item-shorting clearfix">
                                        <div class="left-column pull-left"><h4 class="m-0">Found 1-10 of 142 Results</h4></div>
                                    </div>
                                    <div class="item-shorting-box-right">
                                        <div class="shorting-by">
                                            <select id="shorty" class="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value="1">Low Price</option>
                                                <option value="2">High Price</option>
                                                <option value="3">Most Popular</option>
                                            </select>
                                        </div>
                                        <ul class="shorting-list">
                                            <li><a href="grid-layout-with-sidebar.html"><i class="ti-layout-grid2"></i></a></li>
                                            <li><a href="list-layout-with-sidebar.html" class="active"><i class="ti-view-list"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row text-start">

                            <!-- Single Property Start -->
                            <div class="col-lg-12 col-md-12">
                                <div class="property-listing property-1">
                                        
                                    <div class="listing-img-wrapper">
                                        <a href="single-property-2.html">
                                            <img src="assets/img/p-1.jpg" class="img-fluid mx-auto" alt="" />
                                        </a>
                                    </div>
                                    
                                    <div class="listing-content">
                                    
                                        <div class="listing-detail-wrapper-box">
                                            <div class="listing-detail-wrapper">
                                                <div class="listing-short-detail">
                                                    <h4 class="listing-name"><a href="single-property-2.html">Resort Valley Ocs</a></h4>
                                                    <div class="fr-can-rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="reviews_text">(42 avis)</span>
                                                    </div>
                                                    <span class="prt-types sale">For Sale</span>
                                                </div>
                                                <div class="list-price">
                                                    <h6 class="listing-card-info-price">$7,000</h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bed.svg" width="13" alt="" /></div>3 Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bathtub.svg" width="13" alt="" /></div>1 Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/move.svg" width="13" alt="" /></div>800 sqft
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="listing-footer-wrapper">
                                            <div class="listing-locate">
                                                <span class="listing-location"><i class="ti-location-pin"></i>Quice Market, Canada</span>
                                            </div>
                                            <div class="listing-detail-btn">
                                                <a href="single-property-2.html" class="more-btn">View</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- Single Property End -->
                            
                            <!-- Single Property Start -->
                            <div class="col-lg-12 col-md-12">
                                <div class="property-listing property-1">
                                        
                                    <div class="listing-img-wrapper">
                                        <a href="single-property-2.html">
                                            <img src="assets/img/p-2.jpg" class="img-fluid mx-auto" alt="" />
                                        </a>
                                    </div>
                                    
                                    <div class="listing-content">
                                    
                                        <div class="listing-detail-wrapper-box">
                                            <div class="listing-detail-wrapper">
                                                <div class="listing-short-detail">
                                                    <h4 class="listing-name"><a href="single-property-2.html">Adobe Property Advisors</a></h4>
                                                    <div class="fr-can-rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="reviews_text">(42 avis)</span>
                                                    </div>
                                                    <span class="prt-types rent">For Rent</span>
                                                </div>
                                                <div class="list-price">
                                                    <h6 class="listing-card-info-price">$6,800</h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bed.svg" width="13" alt="" /></div>3 Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bathtub.svg" width="13" alt="" /></div>1 Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/move.svg" width="13" alt="" /></div>800 sqft
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="listing-footer-wrapper">
                                            <div class="listing-locate">
                                                <span class="listing-location"><i class="ti-location-pin"></i>Quice Market, Canada</span>
                                            </div>
                                            <div class="listing-detail-btn">
                                                <a href="single-property-2.html" class="more-btn">View</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- Single Property End -->

                            <!-- Single Property Start -->
                            <div class="col-lg-12 col-md-12">
                                <div class="property-listing property-1">
                                        
                                    <div class="listing-img-wrapper">
                                        <a href="single-property-2.html">
                                            <img src="assets/img/p-3.jpg" class="img-fluid mx-auto" alt="" />
                                        </a>
                                    </div>
                                    
                                    <div class="listing-content">
                                    
                                        <div class="listing-detail-wrapper-box">
                                            <div class="listing-detail-wrapper">
                                                <div class="listing-short-detail">
                                                    <h4 class="listing-name"><a href="single-property-2.html">Bluebell Real Estate</a></h4>
                                                    <div class="fr-can-rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="reviews_text">(42 avis)</span>
                                                    </div>
                                                    <span class="prt-types rent">For Rent</span>
                                                </div>
                                                <div class="list-price">
                                                    <h6 class="listing-card-info-price">$7,000</h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bed.svg" width="13" alt="" /></div>3 Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bathtub.svg" width="13" alt="" /></div>1 Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/move.svg" width="13" alt="" /></div>800 sqft
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="listing-footer-wrapper">
                                            <div class="listing-locate">
                                                <span class="listing-location"><i class="ti-location-pin"></i>Quice Market, Canada</span>
                                            </div>
                                            <div class="listing-detail-btn">
                                                <a href="single-property-2.html" class="more-btn">View</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- Single Property End -->

                            <!-- Single Property Start -->
                            <div class="col-lg-12 col-md-12">
                                <div class="property-listing property-1">
                                        
                                    <div class="listing-img-wrapper">
                                        <a href="single-property-2.html">
                                            <img src="assets/img/p-4.jpg" class="img-fluid mx-auto" alt="" />
                                        </a>
                                    </div>
                                    
                                    <div class="listing-content">
                                    
                                        <div class="listing-detail-wrapper-box">
                                            <div class="listing-detail-wrapper">
                                                <div class="listing-short-detail">
                                                    <h4 class="listing-name"><a href="single-property-2.html">Agile Real Estate Group</a></h4>
                                                    <div class="fr-can-rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="reviews_text">(42 avis)</span>
                                                    </div>
                                                    <span class="prt-types sale">For Sale</span>
                                                </div>
                                                <div class="list-price">
                                                    <h6 class="listing-card-info-price">$8,100</h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bed.svg" width="13" alt="" /></div>3 Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bathtub.svg" width="13" alt="" /></div>1 Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/move.svg" width="13" alt="" /></div>800 sqft
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="listing-footer-wrapper">
                                            <div class="listing-locate">
                                                <span class="listing-location"><i class="ti-location-pin"></i>Quice Market, Canada</span>
                                            </div>
                                            <div class="listing-detail-btn">
                                                <a href="single-property-2.html" class="more-btn">View</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- Single Property End -->

                            <!-- Single Property Start -->
                            <div class="col-lg-12 col-md-12">
                                <div class="property-listing property-1">
                                        
                                    <div class="listing-img-wrapper">
                                        <a href="single-property-2.html">
                                            <img src="assets/img/p-5.jpg" class="img-fluid mx-auto" alt="" />
                                        </a>
                                    </div>
                                    
                                    <div class="listing-content">
                                    
                                        <div class="listing-detail-wrapper-box">
                                            <div class="listing-detail-wrapper">
                                                <div class="listing-short-detail">
                                                    <h4 class="listing-name"><a href="single-property-2.html">Nestled Real Estate</a></h4>
                                                    <div class="fr-can-rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="reviews_text">(42 avis)</span>
                                                    </div>
                                                    <span class="prt-types sale">For Sale</span>
                                                </div>
                                                <div class="list-price">
                                                    <h6 class="listing-card-info-price">$5,700</h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bed.svg" width="13" alt="" /></div>3 Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bathtub.svg" width="13" alt="" /></div>1 Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/move.svg" width="13" alt="" /></div>800 sqft
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="listing-footer-wrapper">
                                            <div class="listing-locate">
                                                <span class="listing-location"><i class="ti-location-pin"></i>Quice Market, Canada</span>
                                            </div>
                                            <div class="listing-detail-btn">
                                                <a href="single-property-2.html" class="more-btn">View</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- Single Property End -->

                            <!-- Single Property Start -->
                            <div class="col-lg-12 col-md-12">
                                <div class="property-listing property-1">
                                        
                                    <div class="listing-img-wrapper">
                                        <a href="single-property-2.html">
                                            <img src="assets/img/p-6.jpg" class="img-fluid mx-auto" alt="" />
                                        </a>
                                    </div>
                                    
                                    <div class="listing-content">
                                    
                                        <div class="listing-detail-wrapper-box">
                                            <div class="listing-detail-wrapper">
                                                <div class="listing-short-detail">
                                                    <h4 class="listing-name"><a href="single-property-2.html">Flow Group Real Estate</a></h4>
                                                    <div class="fr-can-rating">
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star filled"></i>
                                                        <i class="fas fa-star"></i>
                                                        <span class="reviews_text">(42 avis)</span>
                                                    </div>
                                                    <span class="prt-types rent">For Rent</span>
                                                </div>
                                                <div class="list-price">
                                                    <h6 class="listing-card-info-price">$5,900</h6>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bed.svg" width="13" alt="" /></div>3 Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/bathtub.svg" width="13" alt="" /></div>1 Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img src="assets/img/move.svg" width="13" alt="" /></div>800 sqft
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="listing-footer-wrapper">
                                            <div class="listing-locate">
                                                <span class="listing-location"><i class="ti-location-pin"></i>Quice Market, Canada</span>
                                            </div>
                                            <div class="listing-detail-btn">
                                                <a href="single-property-2.html" class="more-btn">View</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- Single Property End -->					
                            
                        </div>
                        
                        <!-- Pagination -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <ul class="pagination p-center">
                                    <li class="page-item">
                                      <a class="page-link" href="#" aria-label="Previous">
                                        <span class="ti-arrow-left"></span>
                                        <span class="sr-only">Previous</span>
                                      </a>
                                    </li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item active"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">18</a></li>
                                    <li class="page-item">
                                      <a class="page-link" href="#" aria-label="Next">
                                        <span class="ti-arrow-right"></span>
                                        <span class="sr-only">Next</span>
                                      </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                
                    </div>
                    
                </div>
            </div>	
        </section>
    </div>
</template>