<script>
// import { KCheckbox } from "@kong/kongponents";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
const baseUrl = "http://192.168.1.3:8080/api/";
export default {
    name:'listesResidencesReserveesView',
  //   components: {
  //   KCheckbox,
  // },
    data() {
        return {
          preloader:false,
          columns: [
        {
          label: 'Propriété',
          field: 'libelle',
        },
        {
          label: 'Image',
          field: 'image',
          width: '100px',
          
        },
        {
          label: 'Tarif',
          field: 'tarif',
          type: 'string',
        },
        
        {
          label: 'Réservation',
          field: 'reservation',
          type: 'string',
        },
        {
          label: 'Prix total',
          field: 'total',
          type: 'string',
        },
        {
          label: 'Approbation',
          field: 'active',
        },
      ], 
      listResidenceReservee:[],
      SeedetailsResidence:{},
      activeResidence:false,
      moneyFormat: new Intl.NumberFormat("de-DE"),
      showDetailsBienImmobilier:false,
      gallery:[],
      listResidenceProprietaire:[],
      showDetailsResidence:false,
        }
    },
    methods: {
      ToogleResidence(e){
        console.log(this.activeResidence);
        let data = {
          validation:1
        }
        let IdResidence = Number(e.target.value)
        this.listResidenceReservee.forEach(item=>{
        if(item.id === IdResidence){
          item.validation = 1
        }
      })
       axios.post(baseUrl+"updatevalid/"+IdResidence,data,{
        headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
       })
       .then(response =>{
        console.log(response);
       })
       .catch(err=>{
        console.log(err);
       });
      },
      ToggleResidenceNotActive(e){
        let data = {
          validation:0
        }
        let IdResidence = Number(e.target.value)
      this.listResidenceReservee.forEach(item=>{
        if(item.id === IdResidence){
          item.validation = 0
        }
      })
       axios.post(baseUrl+"updatevalid/"+IdResidence,data,{
        headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
       })
       .then(response =>{
        console.log(response);
       })
       .catch(err=>{
        console.log(err);
       });
      },
      ShowListResidenceReservee() {
        this.preloader = true
      axios
        .get(baseUrl + "reservationFAR", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("LIST RESIDENCE EN ATTENTE",response);
          this.listResidenceReservee = response.data.data;
          this.preloader = false
          //console.log("LIST RESIDENCE EN ATTENTE",this.listResidenceReservee);
          setTimeout(function () {
            $("#MyTableData").DataTable({
              pagingType: "full_numbers",
              pageLength: 10,
              processing: true,
              order: [],
              language: {
                décimal: "",
                emptyTable: "Aucune donnée disponible dans le tableau",
                infoEmpty: "Showing 0 to 0 of 0 entries",
                info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                infoPostFix: "",
                thousands: ",",
                lengthMenu: "Afficher les entrées du _MENU_",
                loadingRecords: "Loading...",
                processing: "Processing...",
                search: "Chercher :",
                stateSave: true,
                zeroRecords: "Aucun enregistrement correspondant trouvé",
                paginate: {
                  first: "Premier",
                  last: "Dernier",
                  next: "Suivant",
                  previous: "Précédent",
                },
                aria: {
                  sortAscending: ": activate to sort column ascending",
                  sortDescending: ": activate to sort column descending",
                },
              },
            });
          }, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    seeDetailResidence(id) {
      this.listResidenceReservee.forEach((item) => {
        if (item.id === id) {
          this.SeedetailsResidence = item.immobilier;
          console.log(this.SeedetailsResidence);
          // this.SeedetailsTypePropriete = item.type;
          // this.SeedetailsVille = item.ville;
          let address = "http://192.168.1.3:8080/public/image/";
          this.SeedetailsResidence.photos.forEach((item) => {
              this.gallery.push(address + item.path);
              console.log(this.gallery);
          
          });
          // console.log(this.SeedetailsProprietaire);
          // console.log(this.SeedetailsTypePropriete);
          // console.log(this.detailsVille);
        }
      });
      this.showDetailsResidence = !this.showDetailsResidence;
    },
    CloseDetailBienImmobilier() {
      this.showDetailsResidence = !this.showDetailsResidence;
      this.gallery = []
    },
    },
    created() {
      this.ShowListResidenceReservee()
    }
}
</script>
<template>
    <div class="col-lg-10 col-md-12">
        <div class="dashboard-wraper">

          <div class="conteneur_modify" v-show="showDetailsResidence">
            <div class="content position-relative">
              <div
                class="ecriteau text-start d-flex justify-content-between align-items-center"
              >
                <h4>Détails Bien Immobilier</h4>
                <em
                  class="bi bi-x-circle h1"
                  @click.prevent="CloseDetailBienImmobilier"
                ></em>
              </div>
              <div class="submit-pages text-start">
                <div
                  class="infoResidence d-flex justify-content-between align-items-center mb-5"
                >
                  <div>
                    <h3>{{ SeedetailsResidence.libelle }}</h3>
                    <span
                      >Type logement : {{ SeedetailsResidence.type_logement }}</span
                    >
                  </div>
                  <img class="w-25" :src="gallery[0]" alt="villa" />
                </div>
                <div class="boxTotal pb-5">
                  <h5 class="badge bg-primary">Détails propriété</h5>
                  <h5>
                    <strong>Date</strong> :
                    {{ new Date(SeedetailsResidence.created_at).toLocaleDateString('fr') }}
                  </h5>
                  <h5>
                    <strong>Propriété</strong> : {{ SeedetailsResidence.libelle }}
                  </h5>
                  <h5 v-if="SeedetailsResidence.ville">
                    <strong>Ville</strong> : {{ SeedetailsResidence.ville.libelle }}
                  </h5>
                  
                  <h5>
                    <strong>Tarif</strong> :
                    {{ moneyFormat.format(SeedetailsResidence.tarif) }} XOF <span v-if="SeedetailsResidence.periode"> par {{SeedetailsResidence.periode}}</span>
                  </h5>
                  <h5 v-if="SeedetailsResidence.surperficie">
                    <strong>Superficie</strong> :
                    {{ SeedetailsResidence.surperficie }} m<sup>2</sup>
                  </h5>
                  <h5 v-if="SeedetailsResidence.ilot">
                    <strong>ilot</strong> : {{ SeedetailsResidence.ilot }} 
                  </h5>

                  <h5 class="badge bg-danger">Galerie</h5>
                  <div>
                    <MazGallery :images="gallery" :height="400" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
            <!-- Bookmark Property -->
            <div class="form-submit text-start" :class="preloader ? 'pre':'null'">	
              <div id="preloader position-absolute" v-show="preloader">
                <div class="preloader"><span></span><span></span></div>
              </div>
                <h4>Réservation en attente</h4>
            </div>
            <div class="col-sm-12" v-if="preloader == false">
              <table id="MyTableData" class="table text-center">
                <thead>
                  <tr class="text-center">
                    <th class="bg-light">Propriété</th>
                    <th class="bg-light">Image</th>
                    <th class="bg-light">Tarif</th>
                    <th class="bg-light">Date de Réservation</th>
                    <th class="bg-light">Prix total</th>
                    <th class="bg-light">Status</th>
                    <th class="bg-light">Approbation</th>
                    <th class="bg-light">Détail</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in listResidenceReservee" :key="index">
                    <td>{{ item.immobilier.libelle }}</td>
                    <td>
                      <n-image
                        width="50"
                        v-if="item.immobilier.photos.length || item.immobilier.photos[0]"
                        :src="
                          'http://192.168.1.3:8080/public/image/' +
                          item.immobilier.photos[0].path
                        "
                        alt="photo"
                      />
                      <span v-else>Pas d'image</span>
                    </td>
                    <td>{{ moneyFormat.format(item.immobilier.tarif) }} XOF par {{ item.immobilier.periode }}</td>
      
                    <td>
                   {{ new Date(item.date_debut).toLocaleDateString('fr',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}  
                   <span v-if="item.date_fin"> au {{ new Date(item.date_fin).toLocaleDateString('fr',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                    </td>
                    <td>
                      <span v-if="item.date_fin">
                        {{ moneyFormat.format(item.immobilier.tarif * ((new Date(item.date_fin).getTime() - new Date(item.date_debut).getTime()) / (1000 * 3600 * 24)))}} XOF
                      </span>
                      <span v-else>
                       {{moneyFormat.format(item.immobilier.tarif) }} XOF
                      </span>
                  
                    </td>
                    <td>
                      <span v-if="item.validation === 1" class="badge bg-success"
                      >Approuveé</span
                      >
                      <span
                        v-if="item.validation === 0 || item.validation === null"
                        class="badge bg-danger"
                      >
                        En attente</span
                      >
                    </td>
                    <td>
                      <input type="checkbox" :checked="item.validation === 0 ? false:'null'" :value="item.id"  v-if="item.validation === 0 || item.validation === null"  @input="ToogleResidence">
                   
                      <input type="checkbox" :checked="item.validation === 1 ? true:'null'" :value="item.id" v-if="item.validation === 1" @input="ToggleResidenceNotActive">
                    </td>
                    <td>
                      <em
                  class="bi bi-eye"
                  @click.prevent="seeDetailResidence(item.id)"
                ></em>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
           
        </div>
    </div>
</template>
<style scoped>
@import url("@kong/kongponents/dist/style.css");
.pre{
  padding:0 0 15em 0;
}

.detail{
  cursor:pointer;
}
.pre{
  padding:0 0 15em 0;
}
.images{
  border:3px solid black;
  border-radius:8px;
}
.position-delete_image{
  position: absolute;
  right:.3em;
  top:.3em;
  font-size: 1.3em;
  z-index:9;
  cursor:pointer;
  color:crimson;
  width:35px;
  height:35px;
  line-height: 35px;
  border-radius:50%;
  background-color:white;
}
.table {
  border: 1px solid black;
}
td {
  border-right: 2px solid black;
}
.container[data-v-51562f51] {
  width: 100% !important;
  padding: 0 !important;
}
.confirmation_modifie {
  position: absolute;
  z-index: 99;
  background-color: white;
  padding: 2em;
  border: 3px solid black;
  border-radius: 1em;
  margin-left: 50%;
  transform: translateX(-75%);
}
.conteneur_modify {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.255);
  display: flex;
  justify-content: center;
  place-items: center;
}
.conteneur_modify .content {
  width: 60%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 1em;
  box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
}
.close_modal {
  position: absolute;
  top: 1em;
  right: 1em;
}
.ecriteau {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  background-color: white;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  z-index: 999;
}
.submit-pages {
  padding: 1em 3em;
}
.table{
  border:1px solid black;
}
td{
  border-right: 2px solid black;
}
</style>