<script>
export default {
    name:'listesReservationView',
    setup() {
        
    },
}
</script>
<template>
    <div class="col-lg-9 col-md-12">
        <div class="dashboard-wraper">
        
            <!-- Bookmark Property -->
            <div class="form-submit">	
                <h4>My Property</h4>
            </div>
            
            <div class="row">
            
                <!-- Single Property -->
                <div class="col-md-12 col-sm-12 col-md-12">
                    <div class="singles-dashboard-list">
                        <div class="sd-list-left">
                            <img src="assets/img/p-3.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="sd-list-right">
                            <h4 class="listing_dashboard_title"><a href="#" class="theme-cl">My List property Name</a></h4>
                            <div class="user_dashboard_listed">
                                Price: from $ 154 month
                            </div>
                            <div class="user_dashboard_listed">
                                Listed in <a href="javascript:void(0);" class="theme-cl">Rentals</a> and <a href="javascript:void(0);" class="theme-cl">Apartments</a>
                            </div>
                            <div class="user_dashboard_listed">
                                City: <a href="javascript:void(0);" class="theme-cl">KASIA</a> , Area:540 sq ft
                            </div>
                            <div class="action">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><i class="ti-pencil"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="202 User View"><i class="ti-eye"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Property" class="delete"><i class="ti-close"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Featured" class="delete"><i class="ti-star"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Single Property -->
                <div class="col-md-12 col-sm-12 col-md-12">
                    <div class="singles-dashboard-list">
                        <div class="sd-list-left">
                            <img src="assets/img/p-4.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="sd-list-right">
                            <h4 class="listing_dashboard_title"><a href="#" class="theme-cl">My List property Name</a></h4>
                            <div class="user_dashboard_listed">
                                Price: from $ 154 month
                            </div>
                            <div class="user_dashboard_listed">
                                Listed in <a href="javascript:void(0);" class="theme-cl">Rentals</a> and <a href="javascript:void(0);" class="theme-cl">Apartments</a>
                            </div>
                            <div class="user_dashboard_listed">
                                City: <a href="javascript:void(0);" class="theme-cl">KASIA</a> , Area:540 sq ft
                            </div>
                            <div class="action">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><i class="ti-pencil"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="202 User View"><i class="ti-eye"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Property" class="delete"><i class="ti-close"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Featured" class="delete"><i class="ti-star"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Single Property -->
                <div class="col-md-12 col-sm-12 col-md-12">
                    <div class="singles-dashboard-list">
                        <div class="sd-list-left">
                            <img src="assets/img/p-5.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="sd-list-right">
                            <h4 class="listing_dashboard_title"><a href="#" class="theme-cl">My List property Name</a></h4>
                            <div class="user_dashboard_listed">
                                Price: from $ 154 month
                            </div>
                            <div class="user_dashboard_listed">
                                Listed in <a href="javascript:void(0);" class="theme-cl">Rentals</a> and <a href="javascript:void(0);" class="theme-cl">Apartments</a>
                            </div>
                            <div class="user_dashboard_listed">
                                City: <a href="javascript:void(0);" class="theme-cl">KASIA</a> , Area:540 sq ft
                            </div>
                            <div class="action">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><i class="ti-pencil"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="202 User View"><i class="ti-eye"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Property" class="delete"><i class="ti-close"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Featured" class="delete"><i class="ti-star"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Single Property -->
                <div class="col-md-12 col-sm-12 col-md-12">
                    <div class="singles-dashboard-list">
                        <div class="sd-list-left">
                            <img src="assets/img/p-6.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="sd-list-right">
                            <h4 class="listing_dashboard_title"><a href="#" class="theme-cl">My List property Name</a></h4>
                            <div class="user_dashboard_listed">
                                Price: from $ 154 month
                            </div>
                            <div class="user_dashboard_listed">
                                Listed in <a href="javascript:void(0);" class="theme-cl">Rentals</a> and <a href="javascript:void(0);" class="theme-cl">Apartments</a>
                            </div>
                            <div class="user_dashboard_listed">
                                City: <a href="javascript:void(0);" class="theme-cl">KASIA</a> , Area:540 sq ft
                            </div>
                            <div class="action">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><i class="ti-pencil"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="202 User View"><i class="ti-eye"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Property" class="delete"><i class="ti-close"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Featured" class="delete"><i class="ti-star"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Single Property -->
                <div class="col-md-12 col-sm-12 col-md-12">
                    <div class="singles-dashboard-list">
                        <div class="sd-list-left">
                            <img src="assets/img/p-7.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="sd-list-right">
                            <h4 class="listing_dashboard_title"><a href="#" class="theme-cl">My List property Name</a></h4>
                            <div class="user_dashboard_listed">
                                Price: from $ 154 month
                            </div>
                            <div class="user_dashboard_listed">
                                Listed in <a href="javascript:void(0);" class="theme-cl">Rentals</a> and <a href="javascript:void(0);" class="theme-cl">Apartments</a>
                            </div>
                            <div class="user_dashboard_listed">
                                City: <a href="javascript:void(0);" class="theme-cl">KASIA</a> , Area:540 sq ft
                            </div>
                            <div class="action">
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><i class="ti-pencil"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="202 User View"><i class="ti-eye"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Property" class="delete"><i class="ti-close"></i></a>
                                <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Make Featured" class="delete"><i class="ti-star"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </div>
</template>