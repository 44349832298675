<script>
import instance from "../api/api";

export default {
    name:'BienvenueView',
    data(){
     return{
        numberResidence :[],
        numberBienImmobilier:[],
        numberBienAcheteOrLouer:[],
        proprieteFournisseur:[],
        listReservation:[],
        listReservationConfirmer:[]
     }
    },
    methods:{
        ShowListReservationClient() {
      instance
        .get("ReservationClient")
        .then((response)=>{
            this.listReservation = response.data.data;
            //console.log("this.listReservation",this.listReservation)
            this.listReservationConfirmer = response.data.data.filter(item=>item.status === "Confirmée") 
        })
        .catch((error)=>{
            console.log(error);
        })
    },
    ShowListBienProprietaire() {
      instance
        .get("listnotepTMF")
        .then((response)=>{
       
            this.numberBienImmobilier = response.data.data;
            //console.log("this.numberBienImmobilier",this.numberBienImmobilier);
        })
        .catch((error)=>{
            console.log(error);
        });
    },
    ShowListReservationFournisseur() {
      instance
        .get("reservationFAV")
        .then((response)=>{
          //console.log("NOMBREBIENACHETER",this.numberBienAcheteOrLouer);
            this.numberBienAcheteOrLouer = response.data.data;
        })
        .catch((error)=>{
            console.log(error);
        });
    },
    ShowListResidenceReserveeValidee() {
      instance
        .get("reservationFARV")
        .then((response)=>{
        
            this.proprieteFournisseur = response.data.data;
            //console.log("proprieteFournisseur",this.proprieteFournisseur);
        })
        .catch((error)=>{
            console.log(error);
        });
    },
    getProprieteFournisseur(){
        instance
        .get("proprieteProprietaire")
        .then((response)=>{
        
            this.proprieteFournisseur = response.data.data;
            //console.log("proprieteFournisseur",this.proprieteFournisseur);
        })
        .catch((error)=>{
            console.log(error);
        });
    }
    },
    created() {
        this.ShowListReservationClient()
        this.getProprieteFournisseur()
    }
}
</script>
<template>
    <div class="col-lg-10 col-md-12">
        
        <div class="row">
            <h1 class="text-start">Tableau de bord</h1>

            <div class="col-lg-4 col-md-6 col-sm-12" 
            v-if="this.$store.state.user.roles.some(item=>item.label !== 'Client')">
                <div class="dashboard-stat widget-1 ">
                    <h3 class="text-dark text-left fw-bold">Propriétes</h3>
                    <div class="dashboard-stat-content d-flex justify-content-center">
                      <h4 class="text-center">{{proprieteFournisseur.length }}</h4>
                    </div>
                  
                </div>	
            </div>
            
            <!-- <div class="col-lg-4 col-md-6 col-sm-12" 
            v-if="this.$store.state.user.roles.some(item=>item.label !== 'Client')"
            >
                <div class="dashboard-stat widget-1">
                    <div class="dashboard-stat-content"><h4>{{numberBienAcheteOrLouer.length}}</h4> 
                      <span>Propriétés vendues</span></div>
                  
                </div>	
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12" 
            v-if="this.$store.state.user.roles.some(item=>item.label !== 'Client')"
            >
                <div class="dashboard-stat widget-1">
                    <div class="dashboard-stat-content"><h4>{{numberBienAcheteOrLouer.length}}</h4> 
                      <span>Propriétés louées</span></div>
                   
                </div>	
            </div> -->
            
            <div class="col-lg-4 col-md-6 col-sm-12"
            v-if="this.$store.state.user.roles.some(item=>item.label === 'Client')"
            >
                <div class="dashboard-stat widget-1">
                  <h3 class="text-dark text-left fw-bold">Réservations</h3>
                    <div class="dashboard-stat-content d-flex align-items-center justify-content-center w-100">
                     <!-- <div class="mx-2">
                      <h4>{{listReservation.length}}</h4>
                      <span>Total</span> 
                     </div> -->
                     <div class="mx-2">
                      <h4>{{listReservationConfirmer.length}}</h4>
                      <!-- <span>Confirmées</span>  -->
                     </div>
                      
                      </div>
                       
                    <!-- <div class="dashboard-stat-icon"><i class="ti-user"></i></div> -->
                </div>	
            </div>
            
           
            
            
            
          

        </div>

    </div>
</template>