import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export const Help = {
    is_number(value) {
      return /^\d{10}$/.test(value);
    },
    middleWareName(value){
        return /^[^\d\s]+$/.test(value);
    },
    showMsgErrorObjet(value) {
      let msgError = [];
      for (const property in value.data.message) {
        msgError = [...msgError, value.data.message[property]];
      }
      return msgError;
    },
    convertInMoney(value){
        return new Intl.NumberFormat('de-DE').format(value)
    },
    groupBy(value){
    const g = value.reduce((group,item)=>{
        if(group[item.name] == null){
            group[item.name]=[]
        }
        group[item.name].push(item)
        return group;
    },{})

    return g
},
calculeTotal(value) {
  let total = 0;
  value.forEach((element) => {
    total += element.pivot.nombre;
  });
  return new Intl.NumberFormat('de-DE').format(total);
},
calculeTotalPayment(value){
  let total = 0;
  //console.log("VALUE",value)
  value.forEach((element) => {
    total += Number(element);
  });
  return new Intl.NumberFormat('de-DE').format(total);
},
 getRoute(value){
  if(value === 'Superviseur'){
   return "/requete_traitee_agence";
  }else{
   return "/requete_traite_gestionnaire";
  }
 },
 diffForHumans(timestamp) {
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "dans %s",
      past: "%s",
      s: "quelques secondes",
      m: "1 minute",
      mm: "%d minutes",
      h: "1 heure",
      hh: "%d heures",
      d: "1 jour",
      dd: "%d jours",
      M: "1 mois",
      MM: "%d mois",
      y: "1 an",
      yy: "%d ans",
    },
  });
  return dayjs(timestamp).fromNow();
},
calculeDifferenceDate(dateStart,dateEnd){
  const date1 = dayjs(dateStart)
  const date2 = dayjs(dateEnd)
  const different = date1.diff(date2,'day')
  return different;
},
joursEntreDates(dateDebut, dateFin) {
  const jours = [];
  let currentDate = dayjs(dateDebut);
  while (currentDate.isSameOrBefore(dateFin)) {
      jours.push(currentDate.format('YYYY-MM-DD')); 
      currentDate = currentDate.add(1, 'day'); 
  }

  return jours;
}

  };