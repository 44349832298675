<template>
  <Header></Header>
  <div id="main-wrapper">
    <router-view/>
  </div>
  <Footer></Footer>
</template>
<script>
import {Header,Footer} from '@/components'
export default {
  components: {
   Header,Footer
  }
}
</script>

<style>
@media (max-width: 990px) {
	.filter-desktop{
    display: none !important;
  }
  
}

@media (min-width: 990px) {
	.filter-mobile {
		display: none !important;
	}
  
}
.conteneur-eye{
  z-index:30;
  top:25%;
  right:2em;
}
.vueperslides__arrow{
  background: rgba(0, 0, 0, 0.755) !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
background:#fabb04 !important;
border-color:#fabb04 !important;
}
.p-rating .p-rating-item .p-rating-icon{
  font-size:1em !important;
}
.p-rating .p-rating-item.p-rating-item-active .p-rating-icon{
  color:rgb(255, 221, 0)!important;
}
.btn-voir {
  right: 0 !important;
  bottom: 0 !important;
}
.dashboard-stat.widget-1 {
  background-color: white;
  box-shadow: 1px 1px 1.5px 1.5px black;
  color: black !important;
}
.dashboard-stat-content h4 {
  color: black !important;
}
.dashboard-stat-content {
  left: 0 !important;
  padding: .5em;
}
.texte-coupe {
  width: 100%; /* Définissez la largeur maximale du conteneur */
  white-space: nowrap; /* Empêche le texte de se retourner à la ligne */
  overflow: hidden; /* Masque le texte dépassant du conteneur */
  text-overflow: ellipsis; /* Affiche des points de suspension (...) si le texte est coupé */
}
.multiline {
  white-space: pre-line;
}
.texte-coupe-localisation{
  width: 100%; /* Définissez la largeur maximale du conteneur */
  white-space: nowrap; /* Empêche le texte de se retourner à la ligne */
  overflow: hidden; /* Masque le texte dépassant du conteneur */
  text-overflow: ellipsis; /* Affiche des points de suspension (...) si le texte est coupé */
}
.more-btn{
  width:auto;
  height:auto;
  padding:.3em;
  margin: -1em 0;
  color:white !important;
  border-radius:5px;
} 
.p-disabled{
  opacity:0 !important;
}
.n-carousel__arrow{
  width:45px !important;
  height:45px !important;
  background-color: rgba(255, 255, 255, 0.181) !important;
}
.show-photo {
  top: 35em;
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.316);
  right: 1em;
  z-index: 90;
}
.vs__search,
.vs__search:focus {
  padding: .8em 20px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.bg-warning{
  background-color:orange !important;
  color:black !important;
  border:none !important;
}

.nav-menu-social{
  text-align:center;
}


nav a {
  font-weight: bold;
  color: #2c3e50;
}
.router-link-exact-active{
  color:rgb(249, 197, 24);
}
.n-carousel.n-carousel--bottom .n-carousel__arrow-group > *:first-child,.n-carousel .n-carousel__arrow{
  background: white !important;
  color: black !important;;
}


</style>
